import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class NotifyService {
  async getAll() {
    return axiosInstance.get(apiRoutesEnum.notify);
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.notify}/${id}`);
  }

  async readNotify(id) {
    return axiosInstance.post(`${apiRoutesEnum.maskAsRead}/${id}`);
  }

  async readAllNotify() {
    return axiosInstance.post(apiRoutesEnum.maskAsRead);
  }

  async addRegistrationDevice(payload) {
    return axiosInstance.post(apiRoutesEnum.deviceGCM, payload);
  }

  async addRegistrationDeviceIos(payload) {
    return axiosInstance.post(apiRoutesEnum.deviceAPNS, payload);
  }
}

export default new NotifyService();
