import inventoryService from "../../services/inventory";

const state = {
  inventoryList: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getInventoryList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await inventoryService.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_INVENTORY", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },
};
const mutations = {
  SET_LIST_INVENTORY(state, payload) {
    state.inventoryList = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
