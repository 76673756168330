export const menu = [
  {
    key: "F1",
    title: "Tạo đơn hàng mới",
  },
  {
    key: "F2",
    title: "Đóng đơn hàng hiện tại",
  },
  {
    key: "F3",
    title: "Tìm kiếm sản phẩm",
  },
  {
    key: "F4",
    title: "Tìm kiếm khách hàng",
  },
  {
    key: "F8",
    title: "Nhập tiền khách đưa",
  },
  {
    key: "F9",
    title: "Thanh toán",
  },
  {
    key: "F11",
    title: "Phóng to toàn màn hình",
  },
  {
    key: "ESC",
    title: "Đóng hoặc mở menu",
  },
];
