export const StatusDefault = Object.freeze([
  {
    id: 0,
    name: "Mới tạo",
  },
  {
    id: 1,
    name: "Chờ duyệt",
  },
  {
    id: 2,
    name: "Đã duyệt",
  },
  {
    id: 3,
    name: "Huỷ bỏ",
  },
]);

export const StatusSupplier = Object.freeze([
  {
    id: "moi_tao",
    name: "Mới tạo",
  },
  {
    id: "hoat_dong",
    name: "Hoạt động",
  },
  {
    id: "xac_nhan",
    name: "Xác nhận",
  },
]);

export const statusCheckWarehouse = {
  CREATE: 0,
  PENDING: 1,
  APPROVED: 2,
  CANCEL: 3,
};

export const statusCheckWarehouseText = {
  0: "Mới tạo",
  1: "Chờ duyệt",
  2: "Đã duyệt",
  3: "Hủy bỏ",
};

export const statusFood = {
  0: "Mới tạo",
  1: "Đã duyệt",
  2: "Hủy bỏ",
};

export const statusOrderText = {
  NEW_ORDER: "Đơn hàng mới",
  CONFIRM: "Đã xác nhận",
  PICKING_UP_GOODS: "Đang lấy hàng",
  TOOK_THE_GOODS: "Đã lấy hàng",
  DELIVERING: "Đang giao",
  DELIVERED: "Đã giao",
  SUCCESS: "Hoàn thành",
  CUSTOMER_CANCEL: "Khách hủy",
  CANCEL: "Hủy",
};

export const statusTransactionReceipt = {
  CONFIRM: "confirm",
  NEW_CREATED: "new_created",
};

export const statusTransactionReceiptText = {
  confirm: "Đã nhận",
  new_created: "Mới tạo",
};

export const UnitDefault = Object.freeze([
  {
    name: "kg",
    isFloat: true,
  },
  {
    name: "hộp",
    isFloat: false,
  },
  {
    name: "cái",
    isFloat: false,
  },
  {
    name: "đĩa",
    isFloat: false,
  },
  {
    name: "suất",
    isFloat: false,
  },
  {
    name: "bó",
    isFloat: false,
  },
  {
    name: "cây",
    isFloat: false,
  },
  {
    name: "bát",
    isFloat: false,
  },
  {
    name: "con",
    isFloat: false,
  },
  {
    name: "túi",
    isFloat: false,
  },
  {
    name: "chai",
    isFloat: false,
  },
  {
    name: "set",
    isFloat: false,
  },
  {
    name: "khay",
    isFloat: false,
  },
  {
    name: "chiếc",
    isFloat: false,
  },
]);
