var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Báo cáo sản phẩm"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('date-picker',{staticClass:"ml-3",attrs:{"value-type":"format","range":"","format":"DD-MM-YYYY","placeholder":"Chọn ngày để xem"},on:{"change":_vm.onChangeParam},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('div',{staticClass:"col-md-6"},[_c('b-form-select',{staticClass:"col-md-5 pull-left",attrs:{"required":""},on:{"change":_vm.onChangeParam},model:{value:(_vm.idCategory),callback:function ($$v) {_vm.idCategory=$$v},expression:"idCategory"}},[_c('option',{attrs:{"value":""}},[_vm._v("Chọn danh mục")]),_vm._l((_vm.categoryList),function(category){return _c('option',{key:category.index,domProps:{"value":category.id}},[_vm._v(_vm._s(category.name))])})],2)],1)],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive datatable-vue product-list"},[_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.productReportData,"fields":_vm.tablefields,"tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(revenue)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.revenue))+" ")])]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]),(item.unit)?_c('span',[_vm._v("("+_vm._s(item.unit)+")")]):_vm._e()]}},{key:"cell(value_return)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.value_return))+" ")])]}},{key:"cell(net_total)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.net_total))+" ")])]}}])}),(!_vm.isLoading && _vm.productReportList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalProductRecord > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalProductRecord,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }