import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class CityService {
  async getCity() {
    return axiosInstance.get(apiRoutesEnum.city);
  }

  async getDistrict(params) {
    return axiosInstance.get(apiRoutesEnum.city, { params: params });
  }
}

export default new CityService();
