<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="page-wrapper">
      <b-navbar fixed="top" type="dark" style="background-color: #42b983">
        <b-nav-text class="header-nav-shipper"><b>THÔNG BÁO</b></b-nav-text>
      </b-navbar>
      <div class="header-navbar-custom">
        <Notify :notifyList="notifyList" />
      </div>
      <VueBottomNavigation
        :options="options"
        v-model="selected"
        foregroundColor="#42b983"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import VueBottomNavigation from "bottom-navigation-vue";
import Loader from "../../components/loading/loader.vue";
import Notify from "../../../src/components/shipper/notify.vue";

export default {
  components: { VueBottomNavigation, Loader, Notify },
  created() {
    this.getNotifyList();
  },
  computed: {
    ...mapState("notify", ["notifyList", "isLoading"]),
  },
  methods: {
    ...mapActions("notify", ["getNotifyList"]),
  },
  data() {
    return {
      selected: 4,
      options: [
        {
          id: 4,
          icon: "fas fa-bell",
          title: "Thông báo",
          path: { name: "notify-shipper" },
        },
        {
          id: 1,
          icon: "fas fa-home",
          title: "Đơn hàng",
          path: { name: "shipper-order" },
        },
        {
          id: 5,
          icon: "fas fa-user",
          title: "Tài khoản",
          path: { name: "shipper-info" },
        },
        {
          id: 6,
          icon: "fas fa-chart-bar",
          title: "Thống kê",
          path: { name: "shipper-statistic" },
        },
      ],
    };
  },
};
</script>
<style scoped>
.header-navbar-custom {
  margin-top: 60px;
  margin-bottom: 50px;
}
</style>
