import ProductServices from "../../services/product";

const state = {
  totalRecord: 0,
  isLoading: false,
  isListProduct: [],
  totalRecordPrice: 0,
  listPrice: [],
  processImportProduct: {
    max: 0,
    countProcess: 0,
    success: 0,
    error: 0,
    exist: 0,
  },
};
const getters = {};
const actions = {
  async importListProduct({ commit, dispatch, state }, payload) {
    let countProcess = 0;
    let success = 0;
    let error = 0;
    let exist = 0;
    for (const product of payload) {
      const { category, brand, unit, ...data } = product;
      try {
        commit("SET_PROCESS_IMPORT_PRODUCT", {
          max: payload.length,
          countProcess: countProcess++,
          error: error,
          exist: exist,
          success: success,
        });
        // check exist
        const categoryChildResult = category
          ? await dispatch(
              "category/getCategoryList",
              {
                name: category,
                level: 1,
              },
              {
                root: true,
              }
            )
          : null;
        const brandResult = brand
          ? await dispatch(
              "brand/getBrandList",
              {
                name: brand,
              },
              {
                root: true,
              }
            )
          : null;
        const categoryCheck =
          categoryChildResult?.data.data.results.length > 0
            ? categoryChildResult.data.data.results?.find((item) => {
                return item?.name.toLowerCase() === category.toLowerCase();
              })
            : null;

        // check parent category exist
        let categoryParentCheck = null;
        let categoryParentRes = null;
        if (!categoryCheck) {
          const categoryParentResult = await dispatch(
            "category/getCategoryList",
            {
              name: "RBT",
              level: 0,
            },
            {
              root: true,
            }
          );
          categoryParentCheck =
            categoryParentResult?.data.data.results.length > 0
              ? categoryParentResult.data.data.results?.find((item) => {
                  return item?.name.toLowerCase() === "rbt";
                })
              : null;
          if (!categoryParentCheck) {
            // create category parent
            categoryParentRes = await dispatch(
              "category/createCategory",
              {
                name: "RBT",
                active: true,
                parent: null,
              },
              {
                root: true,
              }
            );
          }
        }

        // check brand
        const brandCheck =
          brandResult?.data?.data?.results?.length > 0
            ? brandResult.data.data.results?.find((item) => {
                return item?.name.toLowerCase() === brand.toLowerCase() || null;
              })
            : null;

        // create child category
        let categoryRes = null,
          brandRes = null;
        if (!categoryCheck && category) {
          categoryRes = await dispatch(
            "category/createCategory",
            {
              name: category,
              active: true,
              parent:
                categoryCheck?.parent?.id ||
                categoryParentCheck?.id ||
                categoryParentRes?.data.id,
            },
            {
              root: true,
            }
          );
        }
        if (!brandCheck && brand) {
          brandRes = await dispatch(
            "brand/createBrand",
            {
              name: brand,
              description: "",
            },
            {
              root: true,
            }
          );
        }

        // check unit exsit
        const unitResult = unit
          ? await dispatch(
              "unit/getUnitList",
              {
                name: unit,
              },
              {
                root: true,
              }
            )
          : null;

        const unitCheck =
          unitResult.data?.length > 0
            ? unitResult.data?.find((item) => {
                return item?.name.toLowerCase() === unit.toLowerCase();
              })
            : null;
        let unitRes = null;
        if (!unitCheck && unit) {
          unitRes = await dispatch(
            "unit/createUnit",
            {
              name: unit,
              is_real: false,
            },
            {
              root: true,
            }
          );
        }

        await ProductServices.productAdd({
          category: categoryCheck
            ? categoryCheck?.id
            : categoryRes?.data?.id || null,
          brand: brandCheck ? brandCheck?.id : brandRes?.data?.id || null,
          unit: unitCheck
            ? unitCheck?.name?.toLowerCase()
            : unitRes?.data.name?.toLowerCase() || null,
          active: true,
          ...data,
        });
        commit("SET_PROCESS_IMPORT_PRODUCT", {
          max: payload.length,
          countProcess: countProcess,
          error: error,
          exist: exist,
          success: success++,
        });
      } catch (err) {
        if (
          err.response?.data.code?.[0] === "Mã sản phẩm đã tồn tại." ||
          err.response?.data.code?.[0] === "This product code already existed."
        ) {
          commit("SET_PROCESS_IMPORT_PRODUCT", {
            max: payload.length,
            countProcess: countProcess,
            error: error,
            exist: exist++,
            success: success,
          });
        } else {
          commit("SET_PROCESS_IMPORT_PRODUCT", {
            max: payload.length,
            countProcess: countProcess,
            error: error++,
            exist: exist,
            success: success,
          });
        }
        // await commit("SET_ERROR_LIST", err.response, { root: true });
        continue;
      }
    }
    dispatch("onListProduct", { page: 1, page_size: 10 });
  },
  async onListProduct({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      commit("setListProduct", []);
      const response = await ProductServices.getProducts(params);
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD", count);
      commit("setListProduct", results);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },
  async onDetailProduct({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.detailProduct(id);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
      return null;
    }
  },
  async productAdd({ commit, dispatch }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await ProductServices.productAdd(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm sản phẩm thành công",
          isShow: true,
        },
        { root: true }
      );
      dispatch("onListProduct", { page: 1, page_size: 10 });
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onUpdateProduct({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      await commit("SET_LOADING", true);
      const response = await ProductServices.updateProduct(data);
      await dispatch("onListProduct", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật sản phẩm thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async removeProduct({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await ProductServices.delete(id);
      await dispatch("onListProduct", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  // action price
  async removePrice({ dispatch, commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const { id, params } = payload;
      const response = await ProductServices.deletePrice(id);
      await commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async sendNotifyPromotion({ dispatch, commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const { id, params } = payload;
      const response = await ProductServices.sendNotifyPromotion(id);
      await commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Gửi thông báo thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onCreatePrice({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.createPrice(data);
      commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Tạo bảng giá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onUpdatePrice({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.updatePrice(data);
      commit("SET_LOADING", false);
      dispatch("onPriceList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật bảng giá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onPriceList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.getPriceList(params);
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD_PRICE", count);
      commit("SET_LIST_PRICE", results);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onPriceListActive({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await ProductServices.getPriceListActive();
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD_PRICE", count);
      commit("SET_LIST_PRICE", results);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_PROCESS_IMPORT_PRODUCT(state, payload) {
    state.processImportProduct = payload;
  },
  SET_LIST_PRICE(state, payload) {
    state.listPrice = payload;
  },
  SET_TOTAL_RECORD_PRICE(state, payload) {
    state.totalRecordPrice = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  setListProduct(state, payload) {
    state.isListProduct = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
