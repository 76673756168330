<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo cáo cuối ngày" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <date-picker
                  v-model="date"
                  value-type="format"
                  class="col-6 rbt-custom-input rbt-date-picker"
                  format="DD-MM-YYYY"
                  placeholder="Chọn ngày để xem"
                  @change="onChangeParam"
                ></date-picker>
                <div class="col-6">
                  <b-form-select
                    class="form-control rbt-custom-input"
                    v-model="warehouse"
                    @change="onChangeParam"
                    required=""
                  >
                    <option value="">Chọn kho</option>
                    <option
                      v-for="warehouse in warehouseList"
                      :key="warehouse.index"
                      :value="warehouse.id"
                      >{{ warehouse.name }}</option
                    >
                  </b-form-select>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-6 col-lg-3">
                  <multiselect
                    placeholder="Khách hàng"
                    :show-labels="false"
                    class="rbt-custom-input"
                    v-model="customer"
                    @search-change="searchCustomer"
                    @input="onChangeParam"
                    :internal-search="false"
                    :options="customerList"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="col-6 col-lg-3">
                  <multiselect
                    placeholder="Nhân viên"
                    :show-labels="false"
                    class="rbt-custom-input"
                    v-model="employee"
                    @search-change="searchEmployee"
                    @input="onChangeParam"
                    :internal-search="false"
                    :options="employeeList"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </div>
                <div class="col-6 col-lg-3 mt-3 mt-lg-0">
                  <b-form-select
                    class="rbt-custom-input"
                    v-model="type"
                    @change="onChangeParam"
                  >
                    <option value="">Loại đơn hàng</option>
                    <option
                      v-for="orderType in orderTypeList"
                      :key="orderType.index"
                      :value="orderType.id"
                      >{{ orderType.name }}</option
                    >
                  </b-form-select>
                </div>

                <div class="col-6 col-lg-1 text-right mt-3 mt-lg-0">
                  <b-button
                    class="btn-popup mb-0"
                    variant="primary"
                    @click="onChangeParam"
                    >Tìm</b-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="dailyReportData"
                  :fields="tablefields"
                  :tbody-tr-class="rowClass"
                >
                  <template v-slot:cell(total_invoice)="{ item }">
                    <span>
                      {{ item.total_invoice | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(additional_fee)="{ item }">
                    <span>
                      {{ item.additional_fee | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(total_refund)="{ item }">
                    <span>
                      {{ item.total_refund | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(really_received)="{ item }">
                    <span>
                      {{ item.really_received | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(order_date)="{ item }">
                    <span>
                      {{ item.order_date | showDate }}
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && dailyReportList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalDailyRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalDailyRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { convertDate } from "../../config/common";

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã giao dịch" },
        { key: "order_date", label: "Thời gian" },
        { key: "total_invoice", label: "Tổng hoá đơn" },
        { key: "additional_fee", label: "Thu khác" },
        { key: "total_refund", label: "Phí trả hàng" },
        { key: "really_received", label: "Thực thu" },
      ],
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      date: "",
      type: "",
      warehouse: "",
      customer: "",
      employee: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const dateNow = new Date().toISOString().slice(0, 10);
    const params = {
      page: pageNumber,
      page_size: this.pageSize,
      start: dateNow,
      end: dateNow,
    };
    this.date = convertDate(dateNow);
    this.getDailyReportList(params);
    this.getWarehouseList();
    this.getOrderTypeList();
    this.getCustomerList();
    this.getEmployeeList();
  },
  computed: {
    ...mapState("reports", [
      "dailyReportList",
      "totalDailyRecord",
      "totalInvoiceDaily",
      "numOrdersDaily",
      "additionalFee",
      "realityReceived",
      "remainDaily",
      "isLoading",
    ]),
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("orderType", ["orderTypeList"]),
    ...mapState("customer", ["customerList"]),
    ...mapState("employee", ["employeeList"]),
    dailyReportData() {
      const data = {
        code: `Số hoá đơn: ${this.numOrdersDaily}`,
        total_invoice: this.totalInvoiceDaily,
        additional_fee: this.additionalFee,
        total_refund: this.remainDaily,
        really_received: this.realityReceived,
        status: "awesome",
      };
      const newData = [data].concat(this.dailyReportList);
      return newData;
    },
  },
  methods: {
    ...mapActions("reports", ["getDailyReportList"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions("orderType", ["getOrderTypeList"]),
    ...mapActions("customer", ["getCustomerList"]),
    ...mapActions("employee", ["getEmployeeList"]),
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date) {
        params = {
          start: convertDate(this.date),
          end: convertDate(this.date),
          ...params,
        };
      }
      if (this.type) {
        params = {
          type: this.type,
          ...params,
        };
      }
      if (this.warehouse) {
        params = {
          warehouse: this.warehouse,
          ...params,
        };
      }
      if (this.customer) {
        params = {
          customer: this.customer.id,
          ...params,
        };
      }
      if (this.employee) {
        params = {
          employee: this.employee.id,
          ...params,
        };
      }
      this.getDailyReportList(params);
      this.$router.push({
        path: "/reports/daily",
        query: { page: this.pageNumber },
      });
    },
    onChangeParam() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date) {
        params = {
          start: convertDate(this.date),
          end: convertDate(this.date),
          ...params,
        };
      }
      if (this.type) {
        params = {
          type: this.type,
          ...params,
        };
      }
      if (this.warehouse) {
        params = {
          warehouse: this.warehouse,
          ...params,
        };
      }
      if (this.customer) {
        params = {
          customer: this.customer.id,
          ...params,
        };
      }
      if (this.employee) {
        params = {
          employee: this.employee.id,
          ...params,
        };
      }
      this.getDailyReportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/reports/daily",
          query: { page: this.pageNumber },
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "awesome") return "table-warning";
    },
    searchCustomer: _.debounce(function(query) {
      this.getCustomerList({ query: query.trim() });
    }, 250),
    searchEmployee: _.debounce(function(query) {
      this.getEmployeeList({ query: query.trim() });
    }, 250),
  },
};
</script>

<style scoped>
.rbt-custom-input {
  white-space: nowrap;
  height: 43px !important;
}
</style>

<style>
.rbt-date-picker .mx-input-wrapper,
.rbt-date-picker .mx-input-wrapper input {
  height: 100%;
}
</style>
