<template>
  <div class="container-fluid">
    <loader :is-visible="isLoading"></loader>
    <div class="page-header">
      <Breadcrumbs title="Thay đổi thông tin" />
    </div>
    <div class="row">
      <div class="col">
        <div class="card tab2-card">
          <div class="card-header">
            <h5>
              Cập nhật tài khoản
            </h5>
          </div>
          <div class="card-body">
            <validation-observer ref="observer" v-slot="{ invalid }">
              <b-form
                class="needs-validation user-add"
                @submit.prevent="onSubmit()"
                v-if="userProfile"
              >
                <div class="row">
                  <div class="col-sm-6 col-md-12">
                    <div class="form-group row">
                      <label for="validationCustom0" class="col-xl-3 col-md-4">
                        Ảnh
                      </label>

                      <div class="col-xl-8 col-md-7 px-0">
                        <div class="text-center">
                          <img
                            :src="userInfo.avatar_url"
                            width="75"
                            height="75"
                            fluid
                            rounded
                          />

                          <a
                            style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                            class="text-dark"
                            @click="onChangeAvatar()"
                            ><i data-feather="camera"
                              ><feather type="camera"></feather></i
                          ></a>
                        </div>
                        <input
                          ref="uploader"
                          @change="onFileChanged"
                          class="form-control d-none"
                          id="validationCustom0"
                          type="file"
                          accept="image/*"
                        />
                      </div>
                    </div>
                    <b-form-group
                      id="example-input-group-2"
                      label="Tên người dùng"
                      label-cols-sm="3"
                      label-cols-lg="3"
                      content-cols-sm
                      content-cols-lg="8"
                      label-for="example-input-2"
                    >
                      <b-form-input
                        id="example-input-2"
                        name="example-input-2"
                        v-model="userInfo.name"
                        placeholder="Nhập tên người dùng"
                      ></b-form-input>
                    </b-form-group>
                    <validation-provider
                      name="Số điện thoại"
                      :rules="{ regex: /^[0-9]+$/, min: 10, max: 11 }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="example-input-group-6"
                        label="Số điện thoại"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="8"
                        label-for="example-input-6"
                      >
                        <b-form-input
                          id="example-input-6"
                          name="example-input-6"
                          v-model="userInfo.phone_number"
                          placeholder="Nhập số điện thoại"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-6-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-6-live-feedback">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                    <validation-provider
                      name="Email"
                      :rules="{ required: true, email, min: 1 }"
                      v-slot="validationContext"
                    >
                      <b-form-group
                        id="example-input-group-1"
                        label-cols-sm="3"
                        label-cols-lg="3"
                        content-cols-sm
                        content-cols-lg="8"
                        label-for="example-input-1"
                      >
                        <template v-slot:label> <span>*</span>Email </template>
                        <b-form-input
                          id="example-input-1"
                          name="example-input-1"
                          v-model="userInfo.email"
                          placeholder="Nhập địa chỉ email"
                          :state="getValidationState(validationContext)"
                          aria-describedby="input-1-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-1-live-feedback">{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </div>
                <div class="pull-right">
                  <button
                    type="button"
                    class="btn btn-danger mr-3"
                    @click="handleBackList()"
                  >
                    Trở lại
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="invalid"
                  >
                    Cập nhật
                  </button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialUserInfo = {
  name: "",
  phone_number: "",
  email: "",
  avatar_url: "",
  username: "",
};
export default {
  data() {
    return {
      userInfo: { ...initialUserInfo },
    };
  },
  created() {
    this.getUserProfile();
    this.handleChangeUserProfile();
  },
  components: {
    Loader,
  },
  watch: {
    userProfile() {
      this.handleChangeUserProfile();
    },
  },
  computed: {
    ...mapState("profile", ["userProfile", "isLoading"]),
  },
  methods: {
    ...mapActions("profile", ["getUserProfile", "changeUserProfile"]),
    onSubmit() {
      const { avatar_url, ...data } = this.userInfo;
      this.changeUserProfile({ ...data });
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.userInfo.avatar_url = reader.result;
        this.changeUserProfile(this.userInfo);
      };
      reader.readAsDataURL(file);
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    handleBackList() {
      this.$router.go(-1);
    },
    handleChangeUserProfile() {
      const {
        name,
        phone_number,
        email,
        avatar_url,
        username,
      } = this.userProfile;
      this.userInfo = this.userProfile
        ? { name, phone_number, email, avatar_url, username }
        : { ...initialUserInfo };
    },
  },
};
</script>
<style scoped></style>
