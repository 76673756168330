<template>
  <div>
    <b-modal ref="modalNotify" title="Thông báo" hide-footer>
      <div>
        <p class="title-notify">{{ notifyDetail.verb }}</p>
        <p class="">{{ notifyDetail.description }}</p>
        <b-button block @click="onGoOrder(notifyDetail)">Đến đơn hàng</b-button>
      </div>
    </b-modal>
    <b-card
      v-for="notify in notifyList"
      :key="notify.id"
      @click="openNotify(notify)"
    >
      <div class="row">
        <div class="col-2 icon-notify">
          <feather
            type="bell"
            stroke="#42b983"
            stroke-width="1"
            size="15px"
            :fill="notify.unread == true ? '#ff8084' : '#42b983'"
            stroke-linejoin="round"
          ></feather>
        </div>
        <div class="col-10">
          <b-card-text class="title-notify-unread">
            {{ notify.verb }}
            <span class="pull-right date-notify">{{
              notify.timestamp | showDate
            }}</span>
          </b-card-text>
          <b-card-text>
            <span class="notify.unread">{{ notify.description }}</span>
          </b-card-text>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapActions } from "vuex";

export default {
  props: {
    notifyList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {},
  data() {
    return {
      notifyDetail: {},
    };
  },
  created() {},
  computed: {},
  watch: {},
  methods: {
    ...mapActions("notify", ["readNotify"]),
    openNotify(notify) {
      this.$refs.modalNotify.show();
      this.notifyDetail = notify;
      const { id } = notify;
      this.readNotify(id);
    },
    onGoOrder(data) {
      const { sender } = data;
      this.$router.push({
        name: "order-detail",
        query: { id: sender },
      });
    },
  },
};
</script>
<style>
.date-notify {
  font-size: 12px;
  color: #aba8a8;
}
.content-notify {
  color: #c3baba;
}
.title-notify-unread {
  font-size: 14px;
  color: #42b983;
  font-weight: 900;
}
.title-notify {
  font-size: 14px;
  color: #97d9bb;
  font-weight: 900;
}
.icon-notify {
  display: flex;
  align-items: center;
}
</style>
