import warehouseService from "../../services/warehouse";

const state = {
  warehouseInventoryList: [],
  warehouseList: [],
  warehouseImportList: [],
  totalRecord: 0,
  totalRecordImport: 0,
  isLoading: false,
  isLoadingImport: false,
};
const getters = {};
const actions = {
  // action CRU check warehouse
  async getCheckListWarehouse({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await warehouseService.inventorySheetList(params);
      const { count, results } = response.data.data;
      commit("setInventorySheetList", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("setInventorySheetList", []);
    }
  },

  async warehouseCheckDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await warehouseService.detailCheckWarehouse(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async addCheckWarehouse({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await warehouseService.addCheckWarehouse(data);
      await dispatch("getCheckListWarehouse", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Tạo kiểm kho thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateCheckWarehouse({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await warehouseService.updateCheckWarehouse(data);
      await dispatch("getCheckListWarehouse", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật trạng thái kiểm kho thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  // action CRU warehouse import
  async getWarehouseImportList({ commit }, payload) {
    try {
      commit("SET_LOADING_IMPORT", true);
      const response = await warehouseService.warehouseImportList(payload);
      const { count, results } = response.data.data;
      commit("SET_LIST_WAREHOUSE_IMPORT", results);
      commit("SET_TOTAL_RECORD_IMPORT", count);
      commit("SET_LOADING_IMPORT", false);
    } catch (error) {
      commit("SET_LOADING_IMPORT", false);
    }
  },

  async warehouseImportDetail({ commit }, id) {
    try {
      commit("SET_LOADING_IMPORT", true);
      const response = await warehouseService.warehouseImportDetail(id);
      commit("SET_LOADING_IMPORT", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING_IMPORT", false);
      return {};
    }
  },

  async onApprovedWarehouseImport({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await warehouseService.warehouseImportApproved(data);
      await dispatch("getWarehouseImportList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Duyệt phiếu nhập kho thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onUpdateWarehouseImport({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await warehouseService.warehouseImportUpdate(data);
      await dispatch("getWarehouseImportList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật phiếu nhập kho thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onWarehouseImportCancel({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await warehouseService.warehouseImportCancel(data);
      await dispatch("getWarehouseImportList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Huỷ phiếu nhập kho thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async onWarehouseImport({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      const response = await warehouseService.warehouseImport(data);
      await dispatch("getWarehouseImportList", params);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Tạo phiếu nhập kho thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  // action CRUD warehouse
  async getWarehouseList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await warehouseService.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_WAREHOUSE", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getWarehouseDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await warehouseService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createWarehouse({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await warehouseService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm kho hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateWarehouse({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await warehouseService.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deleteWarehouse({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await warehouseService.delete(id);
      await dispatch("getWarehouseList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  setInventorySheetList(state, payload) {
    state.warehouseInventoryList = payload;
  },
  SET_LIST_WAREHOUSE_IMPORT(state, payload) {
    state.warehouseImportList = payload;
  },
  SET_TOTAL_RECORD_IMPORT(state, payload) {
    state.totalRecordImport = payload;
  },
  SET_LIST_WAREHOUSE(state, payload) {
    state.warehouseList = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_LOADING_IMPORT(state, payload) {
    state.isLoadingImport = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
