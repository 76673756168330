import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class TypeServices {
  async getAll() {
    return axiosInstance.get(apiRoutesEnum.orderType);
  }
}

export default new TypeServices();
