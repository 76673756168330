<template>
  <div>
    <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4">
        Bạn có muốn hủy nhập kho
      </p>
    </b-modal>
    <b-modal size="lg" id="modal-import-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Báo giá nhà cung cấp
        </h3>
      </template>
      <div>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex">
            <input
              v-model="dataSubmit.name"
              placeholder="Tên báo giá"
              class="form-control rbt-custom-input mr-3"
              id="validationCustom1"
              type="text"
            />
            <div class="col-12 col-md-6 d-flex align-items-center">
              <multiselect
                v-if="!dataSubmit.id"
                class="rbt-custom-input px-0"
                placeholder="Chọn nhà cung cấp"
                v-model="dataSubmit.supplier"
                :show-labels="false"
                :custom-label="searchingSelect"
                :options="supplierList"
              >
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
              <input
                v-else
                disabled
                v-model="dataSubmit.supplier.name"
                placeholder="Nhà cung cấp"
                class="form-control rbt-custom-input"
                id="validationCustom1"
                type="text"
              />
              <div class="d-flex align-items-center">
                <b-spinner
                  v-if="isLoading"
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
              </div>
            </div>
          </div>
          <div
            v-if="!dataSubmit.id"
            class="d-flex align-items-center"
            style="width: atuo !important"
          >
            <b-form-file v-model="fileExcel" plain></b-form-file>
            <feather
              v-if="fileExcel"
              style="cursor: pointer;"
              stroke="#F72E9F"
              size="14px"
              stroke-width="5"
              type="x"
              @click="removeExcel()"
            ></feather>
          </div>
        </div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Are you sure!</p>
          </b-modal>
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="listProductTable"
            :fields="tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>

            <template v-slot:cell(unit_price)="{ item }">
              <span v-if="fileExcel">
                {{ item.unit_price | formatCurrency }}
              </span>
              <div v-else>
                <input
                  :value="item.unit_price"
                  placeholder="Đơn giá"
                  class="form-control rbt-custom-input mr-3"
                  @keyup="
                    onChangePrice($event, {
                      key: 'unit_price',
                      id: item.product,
                    })
                  "
                />
              </div>
            </template>
            <template v-slot:cell(price_recommended)="{ item }">
              <span>
                {{ item.price_recommended | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <span>
                {{ item.quantity }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span style="font-size: 20px; color: #FA399F;">
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                  @click="removeProductInTable(item.sno)"
                ></feather>
              </span>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="listProductTable.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="listProductTable.length"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
        <ListProductBottom
          v-if="
            isListProduct.length > 0 &&
              !fileExcel &&
              dataSubmit.supplier &&
              !dataSubmit.id
          "
          :productList="productsActive"
          @onAddProductInTab="onAddProductInTab"
        />
      </div>
      <div>
        <textarea
          v-model="dataSubmit.note"
          rows="2"
          placeholder="Ghi chú"
          class="form-control"
        ></textarea>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-end">
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              :disabled="checkDisable"
              class="btn-popup m-0"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              Tạo báo giá</b-button
            >
            <b-button
              v-else
              class="btn-popup m-0"
              :disabled="checkDisable"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              Cập nhật báo giá</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import ListProductBottom from "../../components/UI/listProductBottom.vue";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import XLSX from "xlsx";
import { addCommaPrice } from "../../config/common";

const keyExcelToJson = {
  "Mã sản phẩm": "id",
  Tên: "name",
  "Số lượng": "quantity",
  Giá: "unit_price",
  "Giá khuyến nghị": "price_recommended",
};

const initialDataSubmit = {
  product_quotation: [],
  supplier: null,
  name: "",
  note: "",
};

export default {
  props: {
    quotationsInfo: {
      type: Object,
      default() {
        return {};
      },
      required: true,
    },
  },
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quantity", label: "Số lượng" },
        { key: "unit_price", label: "Đơn giá" },
        { key: "price_recommended", label: "Giá khuyến nghị" },
        { key: "actions", label: "Xóa" },
      ],
      productQuotes: [],
      downloadLoading: false,
      excelName: "",
      fileExcel: null,
      excelData: {
        header: null,
        results: null,
      },
      dataSubmit: { ...initialDataSubmit },
      checkDisable: false,
    };
  },
  components: {
    Loader,
    ListProductBottom,
    IncreaseAndDecrease,
  },
  created() {
    this.getSupplierList();
  },
  computed: {
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", ["isListProduct", "isLoading"]),
    listProductTable() {
      return this.productQuotes.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    productsActive() {
      let data = [];
      const idPurchasedProduct = this.productQuotes?.map((item) => {
        return item.product;
      });
      data = this.isListProduct?.filter((item) => {
        return !idPurchasedProduct?.includes(item.id);
      });
      return data;
    },
  },
  watch: {
    quotationsInfo() {
      this.removeExcel();
      if (this.quotationsInfo?.id) {
        this.dataSubmit = { ...this.quotationsInfo };
        const products = this.quotationsInfo?.product_quotation.map((item) => {
          const {
            unit_price,
            choice,
            price_recommended,
            quotation_price,
            quantity,
            id,
          } = item;
          return {
            name: item?.product?.name,
            unit_price: addCommaPrice(unit_price),
            choice,
            quantity,
            price_recommended: addCommaPrice(price_recommended),
            quotation_price: addCommaPrice(quotation_price),
            product: item.product.id,
          };
        });
        // this.onListProduct({ supplier_id: this.quotationsInfo?.supplier?.id });
        this.productQuotes = [...products];
      } else {
        this.dataSubmit = { ...this.initialDataSubmit };
        this.productQuotes = [];
      }
    },
    isListProduct() {
      this.productQuotes =
        this.isListProduct?.map((item) => {
          const { id, name, price } = item;
          return {
            product: id,
            name,
            unit_price: addCommaPrice(price),
            quantity: 1,
            price_recommended: addCommaPrice(item?.price),
          };
        }) || [];
    },
    fileExcel() {
      this.handleClick();
      if (!this.fileExcel) {
        this.productQuotes = [];
        if (this.dataSubmit.supplier?.id) {
          const params = {
            supplier_id: this.dataSubmit.supplier?.id,
          };
          this.onListProduct(params);
        }
      }
    },
    "dataSubmit.supplier"() {
      if (
        this.dataSubmit.supplier?.id &&
        !this.fileExcel &&
        !this.dataSubmit?.id
      ) {
        const params = {
          supplier_id: this.dataSubmit.supplier?.id,
        };
        this.onListProduct(params);
      }
    },
  },
  methods: {
    ...mapActions("supplier", [
      "getSupplierList",
      "onQuotations",
      "onQuotationsUpdate",
    ]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions(["setMessage"]),
    onChangePrice(event, { key, id }) {
      if (event.target.value) {
        this.checkDisable = false;
        const productIndex = this.productQuotes.findIndex(
          (product) => product.product === id
        );
        this.productQuotes[productIndex][key] = addCommaPrice(
          event.target.value
        );
      } else {
        this.checkDisable = true;
        this.setMessage({
          type: "error",
          content: "Giá không được để trống",
          isShow: true,
        });
        const productIndex = this.productQuotes.findIndex(
          (product) => product.product === id
        );
        this.productQuotes[productIndex][key] = "";
        return;
      }
    },
    removeExcel() {
      this.fileExcel = null;
    },
    replaceKeyExcelToData(tab) {
      let replacedItems = Object.keys(tab).map((key) => {
        const newKey = keyExcelToJson[key] || key;
        return { [newKey]: tab[key] };
      });
      const newTab = replacedItems.reduce((a, b) => Object.assign({}, a, b));
      return newTab;
    },
    generateData({ header, results }) {
      this.excelData.header = header;
      this.excelData.results = results;
      const newResults = results.map((item) => {
        return this.replaceKeyExcelToData(item);
      });
      this.productQuotes = [...newResults];
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    readerData(rawFile) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    beforeUpload(file) {
      var extension = file.name.split(".").pop();
      if (["xls", "xlsx"].indexOf(extension) < 0) {
        this.setMessage({
          type: "error",
          content: "Đây không phải file excel",
          isShow: true,
        });
        return false;
      }
      return true;
    },
    upload(rawFile) {
      if (!this.beforeUpload(rawFile)) {
        // this.readerData(rawFile);
        this.fileExcel = null;
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    handleClick() {
      let files = this.fileExcel;
      const rawFile = files;
      if (!rawFile) return;
      this.upload(rawFile);
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    submit() {
      if (
        this.productQuotes.length > 0 &&
        this.dataSubmit.supplier &&
        this.dataSubmit.name
      ) {
        const { sno, supplier, user, ...data } = this.dataSubmit;
        // check condition exist excel file
        if (this.fileExcel) {
          const productQuotations = this.productQuotes.map((item) => {
            const { unit_price, id, price_recommended, quantity } = item;
            return {
              unit_price: unit_price,
              choice: true,
              price_recommended: price_recommended,
              product: id || item?.product,
              quantity,
            };
          });
          if (!this.dataSubmit?.id) {
            this.$emit("onCreateQuotations", {
              ...data,
              supplier: supplier?.id,
              product_quotation: [...productQuotations],
            });
          } else {
            this.$emit("onUpdateQuotations", {
              ...data,
              supplier: supplier?.id,
              product_quotation: [...productQuotations],
            });
          }
          this.dataSubmit = { ...initialDataSubmit };
          this.$bvModal.hide("modal-import-warehouse");
          this.removeExcel();
        } else {
          // check condition not exist excel file
          const productQuotations = this.productQuotes.map((item) => {
            const { unit_price, id, price_recommended, quantity } = item;
            return {
              unit_price: Number(unit_price.replace(/,/g, "")),
              choice: true,
              price_recommended: Number(price_recommended.replace(/,/g, "")),
              product: id || item?.product,
              quantity,
            };
          });
          if (!this.dataSubmit?.id) {
            this.$emit("onCreateQuotations", {
              ...data,
              supplier: supplier?.id,
              product_quotation: [...productQuotations],
            });
          } else {
            this.$emit("onUpdateQuotations", {
              ...data,
              supplier: supplier?.id,
              product_quotation: [...productQuotations],
            });
          }
          this.dataSubmit = { ...initialDataSubmit };
          this.$bvModal.hide("modal-import-warehouse");
          this.removeExcel();
        }
      } else {
        this.setMessage({
          type: "error",
          content: "Tên báo giá, nhà cung cấp, sản phẩm không được để trống",
          isShow: true,
        });
        return;
      }
    },
    removeProductInTable(index) {
      this.productQuotes.splice(index - 1, 1);
    },
    onIncreAndDecre({ quantity, id }) {
      const productIndex = this.productQuotes.findIndex(
        (product) => product.product === id
      );
      this.productQuotes[productIndex].quantity = quantity;
    },
    searchingSelect({ name }) {
      return name;
    },
    onAddProductInTab(item) {
      const { id, name, price } = item;
      this.productQuotes.push({
        product: id,
        name,
        unit_price: addCommaPrice(price),
        quantity: 1,
        price_recommended: addCommaPrice(price),
      });
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>
