<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách người dùng" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật người dùng
              </h5>
              <h5 v-else>
                Thêm người dùng
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="userInfo"
                >
                  <div class="form-group row">
                    <label for="validationCustom0" class="col-xl-2 col-md-3">
                      Ảnh đại diện</label
                    >

                    <div class="col-xl-8 col-md-7 px-0">
                      <div class="text-center">
                        <img
                          v-if="userInfo.avatar_url"
                          :src="userInfo.avatar_url"
                          width="75"
                          height="75"
                          fluid
                          rounded
                        />
                        <img
                          v-else
                          src="../../assets/images/dashboard/avatar.png"
                          width="75"
                          height="75"
                          fluid
                          rounded
                        />
                        <a
                          style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                          class="text-dark"
                          @click="onChangeAvatar()"
                          ><i data-feather="camera"
                            ><feather type="camera"></feather></i
                        ></a>
                      </div>
                      <input
                        ref="uploader"
                        @change="onFileChanged"
                        class="form-control d-none"
                        id="validationCustom0"
                        type="file"
                        accept="image/*"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider v-if="checkDataInfo">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Mã người dùng
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="userInfo.code"
                            :disabled="checkDataInfo"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        name="Tên đăng nhập"
                        :rules="{
                          required: true,
                          regexUserName: /^[a-z0-9@._-]{3,50}$/,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên đăng nhập
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="userInfo.username"
                            placeholder="Nhập tên đăng nhập"
                            :state="getValidationState(validationContext)"
                            :disabled="checkDataInfo"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mật khẩu"
                        :rules="{
                          required: checkDataInfo ? false : true,
                          min: 8,
                          regexPassword: /[A-Z]/i,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-2"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            <span v-if="!checkDataInfo">*</span>Mật khẩu
                          </template>
                          <b-form-input
                            v-if="!checkDataInfo"
                            id="example-input-2"
                            name="example-input-2"
                            placeholder="******"
                            type="password"
                            v-model="userInfo.password"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-2-live-feedback"
                          ></b-form-input>
                          <b-button
                            v-else
                            class="btn btn-info"
                            size="sm"
                            @click="changePassword(userInfo.id)"
                          >
                            Đổi mật khẩu
                          </b-button>

                          <b-form-invalid-feedback id="input-2-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Email"
                        :rules="{ required: true, email: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            <span>*</span>Email
                          </template>
                          <b-form-input
                            id="example-input-5"
                            name="example-input-5"
                            v-model="userInfo.email"
                            placeholder="Nhập email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-5-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="col-sm-6">
                      <validation-provider
                        name="Vai trò"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <template v-slot:label>
                            <span>*</span>Vai trò
                          </template>
                          <multiselect
                            placeholder="Chọn vai trò"
                            v-model="userInfo.groups"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            :show-labels="false"
                            :options="roleList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Số điện thoại"
                        :rules="{ regexPhone: /^[0-9]+$/, min: 10, max: 11 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label="Số điện thoại"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <b-form-input
                            v-model="userInfo.phone_number"
                            placeholder="Nhập số điện thoại"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên người dùng"
                        :rules="{ required: true, max: 255 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên người dùng
                          </template>
                          <b-form-input
                            v-model="userInfo.name"
                            placeholder="Nhập tên người dùng"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  username: "",
  password: "",
  name: "",
  phone_number: "",
  avatar_url: null,
  groups: "",
  email: "",
};

export default {
  data() {
    const userInfo = this.$route.query.id ? this.userInfo : initialData;
    return {
      userInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.userInfo = await this.getUserDetail(this.$route.query.id);
    } else {
      this.userInfo = {
        ...initialData,
      };
    }
    this.getRoleList();
  },
  computed: {
    ...mapState("user", ["isLoading"]),
    ...mapState("role", ["roleList"]),
    checkDataInfo() {
      if (this.$route.query.id) return true;
      else return false;
    },
  },
  watch: {},
  methods: {
    ...mapActions("user", ["createUser", "updateUser", "getUserDetail"]),
    ...mapActions("role", ["getRoleList"]),
    async onSubmit() {
      const {
        avatar_url,
        city,
        district,
        groups,
        warehouse,
        ...data
      } = this.userInfo;

      const groupConvert = [];
      groupConvert.push(Array.isArray(groups) ? groups[0]?.id : groups?.id);

      const dataSubmit = {
        groups: groupConvert,
        city: city?.id,
        district: district?.id,
        warehouse: warehouse?.id,
        ...data,
      };

      if (this.userInfo.id) {
        const idUser = this.userInfo.id;
        const dataUpdate = { id: idUser, data: dataSubmit };
        await this.updateUser(dataUpdate);
        this.userInfo = await this.getUserDetail(this.userInfo?.id);
      } else {
        if (this.userInfo.avatar_url) {
          const dataWithImage = {
            avatar_url: this.userInfo.avatar_url,
            data: dataSubmit,
          };
          this.createUser(dataWithImage);
        } else {
          this.createUser(dataSubmit);
        }
      }
    },
    changePassword(value) {
      this.$router.push({
        name: "change-password",
        query: { id: value },
      });
    },
    cancelUpdate() {
      this.userInfo = {
        username: "",
        password: "",
        name: "",
        phone_number: "",
        avatar_url: null,
        groups: "",
        email: "",
      };
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.userInfo.avatar_url = reader.result;
        if (this.$route.query.id) {
          const idUser = this.userInfo.id;
          const {
            avatar_url,
            username,
            city,
            district,
            groups,
            ...data
          } = this.userInfo;
          const dataSubmit = {
            avatar_url: this.userInfo.avatar_url,
            groups: Array.from(
              String(Array.isArray(groups) ? groups[0]?.id : groups?.id),
              Number
            ),
            city: city?.id,
            district: district?.id,
            ...data,
          };
          const userData = { id: idUser, data: dataSubmit };
          this.updateUser(userData);
        }
      };
      reader.readAsDataURL(file);
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    searchingSelect({ name }) {
      return name;
    },
    handleBackList() {
      this.$router.push({ name: "user-list" });
    },
  },
};
</script>
<style scoped></style>
