import cityService from "../../services/city";

const state = {
    cityList: [],
    districtList: [],
};
const getters = {
};
const actions = {

    async getCityList({ commit }) {
        try {
            const response = await cityService.getCity();
            commit("SET_LIST_CITY", response.data)
        } catch (error) { }
    },

    async getDistrictList({ commit }, params) {
        try {
            const response = await cityService.getDistrict(params);
            commit("SET_LIST_DISTRICT", response.data[0].districts)
        } catch (error) { }
    },

};
const mutations = {
    SET_LIST_CITY(state, payload) {
        state.cityList = payload;
    },

    SET_LIST_DISTRICT(state, payload) {
        state.districtList = payload;
    },
};
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
