import axios from "axios";
import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class AuthServices {
  signIn(payload) {
    const response = axios.post(
      `${process.env.VUE_APP_API_URL}${apiRoutesEnum.signIn}`,
      payload.data
    );
    return response;
  }
  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.signUp, payload);
  }
}

export default new AuthServices();
