var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Chiết khấu"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"}),_c('div',{staticClass:"card-body"},[_c('router-link',{attrs:{"to":"/settings/create-discount"}},[_c('b-button',{staticClass:"btn-popup pull-right"},[_vm._v("Thêm mới")])],1),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[_c('b-modal',{attrs:{"id":"modal-2","title":"Xác nhận"},on:{"ok":function($event){return _vm.onDelete(_vm.isId)}}},[_c('p',{staticClass:"my-4"},[_vm._v(" Xoá chiết khấu từ "),_c('b',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.startAmount)))]),_vm._v(" đến "),_c('b',[_vm._v(_vm._s(_vm._f("formatCurrency")(_vm.endAmount)))])])]),(_vm.discountList.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.discountList,"fields":_vm.tablefields},scopedSlots:_vm._u([{key:"cell(start_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.start_amount))+" ")])]}},{key:"cell(discount_percent)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.discount_percent)+"% ")])]}},{key:"cell(end_amount)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.end_amount))+" ")])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px","color":"blue"}},[_c('feather',{staticStyle:{"cursor":"pointer"},attrs:{"type":"edit-2","stroke":"#3758FD","stroke-width":"1","size":"18px","fill":"#3758FD","stroke-linejoin":"round"},on:{"click":function($event){return _vm.onEdit(item)}}})],1),_c('span',{staticStyle:{"font-size":"20px","color":"#FA399F"}},[_c('feather',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-2",modifiers:{"modal-2":true}}],staticStyle:{"cursor":"pointer"},attrs:{"type":"trash","stroke":"#F72E9F","size":"18px","fill":"#F72E9F"},on:{"click":function($event){return _vm.setItem(item)}}})],1)]}}],null,false,1824095804)}):_vm._e(),(_vm.discountList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }