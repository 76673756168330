import OrderServices from "../../services/order";
import LocalStorageService from "../../utils/index";

const state = {
  currentTab: null,
  tabList: [],
  orderActive: [],
  subContentActive: {},
  tabListContent: {},
  totalRecord: 0,
  orderList: [],
  orderShipperList: [],
  orderOnlineDetail: [],
  orderShipperDetail: {},
  transactionList: [],
  isLoading: false,
  subContentInTab: {},
  billInTabs: {},
  billActive: {},
  orderDetail: {},
  isUpdateOrder: false,
  orderProductDetail: [],
  isDisableSubmit: false,
  isChangePrice: false,

  orderShipperStatisticList: [],
  totalOrderShipper: 0,
  totalCustomerGave: 0,
  totalInvoiceShipper: 0,
  isOrderSubmit: false,
  isOrderSuccess: false,
  isUpdatePaymentNotShipSuccess: false,
  isUpdateOrderOnline: false,
  isConfirmOrderOnline: false,
};

const actions = {
  onChangeNote({ commit }, payload) {
    commit("ON_CHANGE_NOTE", payload);
  },
  onUpdateOrderLocal({ commit }, check) {
    commit("ON_UPDATE_ORDER_LOCAL", check);
  },
  async onOrder({ commit, dispatch }, payload) {
    try {
      commit("SET_IS_ORDER_SUBMIT", false);
      commit("SET_IS_ORDER_SUCCESS", false);
      commit("SET_LOADING", true);
      commit("SET_DISABLED_SUBMIT", true);
      const response = await OrderServices.order(payload);
      commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Tạo đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
      commit("SET_IS_ORDER_SUBMIT", true);
      dispatch("getOrderDetail", response.data?.id);
      dispatch(
        "product/onListProduct",
        { warehouse_id: payload.store, active: true },
        { root: true }
      );
      dispatch("customer/getCustomerList", "", { root: true });
      commit("SET_IS_ORDER_SUCCESS", true);
      return response;
    } catch (error) {
      commit("SET_IS_ORDER_SUBMIT", false);
      commit("SET_IS_ORDER_SUCCESS", false);
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
      commit("SET_DISABLED_SUBMIT", false);
    }
  },

  async updatePaymentNotShip({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      commit("SET_UPDATE_PAYMENT_NOT_SHIP", false);
      const response = await OrderServices.updatePaymentNotShip(payload);
      commit("SET_LOADING", false);
      commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Đơn hàng hoàn thành",
          isShow: true,
        },
        { root: true }
      );
      commit("SET_UPDATE_PAYMENT_NOT_SHIP", true);
    } catch (error) {
      commit("SET_UPDATE_PAYMENT_NOT_SHIP", false);
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async getOrderDetail({ commit }, id) {
    try {
      const response = await OrderServices.getItem(id);
      commit("SET_ORDER_DETAIL", response.data);
    } catch (error) {
      await commit("SET_ERROR_LIST", error.response, { root: true });
      console.error(error);
    }
  },

  async getOrderList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      commit("SET_DISABLED_SUBMIT", false);
      const response = await OrderServices.getAll(params);
      console.log("response order", response);
      const { results, count } = response.data.data;
      commit("SET_LIST_ORDER", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
      return results;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getOrderShipperList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await OrderServices.getAllOrderShipper(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_ORDER_SHIPPER", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getShipperStatisticOrder({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await OrderServices.getShipperStatisticOrder(params);
      const { results, count } = response.data.data;
      const { num_orders, total_customer_gave, total_invoice } = response.data;
      commit("SET_LIST_ORDER_SHIPPER_STATISTIC", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_TOTAL_ORDER_SHIPPER", num_orders);
      commit("SET_TOTAL_CUSTOMER_GAVE", total_customer_gave);
      commit("SET_TOTAL_INVOICE_SHIPPER", total_invoice);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getDetailOrderShipper({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await OrderServices.getDetailOrderShipper(payload);
      commit("SET_ORDER_SHIPPER_DETAIL", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async updateOrderShipper({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await OrderServices.updateOrderShipper(data);
      if (params) {
        await dispatch("getOrderShipperList", params);
      } else {
        const { id } = data;
        await dispatch("getDetailOrderShipper", id);
      }
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async cancelOrderShipper({ commit, dispatch }, payload) {
    try {
      const { data, params } = payload;
      const response = await OrderServices.updateOrderShipper(data);
      if (params) {
        await dispatch("getOrderShipperList", params);
      } else {
        const { id } = data;
        await dispatch("getDetailOrderShipper", id);
      }
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Đã huỷ đơn hàng",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async getTransactionList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await OrderServices.getAllTransaction(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_TRANSACTION", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async updateOrderOnline({ commit }, payload) {
    try {
      commit("SET_UPDATE_ORDER_ONLINE", false);
      await commit("SET_LOADING", true);
      const response = await OrderServices.update(payload);
      await commit("SET_LOADING", false);
      await commit("SET_DISABLED_CHANGE_PRICE", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
      commit("SET_UPDATE_ORDER_ONLINE", true);
    } catch (error) {
      commit("SET_UPDATE_ORDER_ONLINE", false);
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
      await commit("SET_DISABLED_CHANGE_PRICE", true);
    }
  },

  async updateStatusOrder({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await OrderServices.updateStatusOrder(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật trạng thái đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async confirmOrderOnline({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      await commit("SET_CONFIRM_ORDER_ONLINE", false);
      const response = await OrderServices.updateStatusOrder(payload);
      await commit("SET_LOADING", false);
      await commit("SET_CONFIRM_ORDER_ONLINE", true);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xác nhận đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_CONFIRM_ORDER_ONLINE", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async cancelOrderOnline({ commit }, payload) {
    try {
      commit("SET_IS_ORDER_SUCCESS", false);
      commit("SET_LOADING", true);
      const response = await OrderServices.updateStatusOrder(payload);
      commit("SET_LOADING", false);
      commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Huỷ đơn hàng thành công",
          isShow: true,
        },
        { root: true }
      );
      commit("SET_IS_ORDER_SUCCESS", true);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async getOrderOnlineId({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await OrderServices.getItem(payload);
      commit("SET_ORDER_ONLINE_DETAIL", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  onRemoveProductInTab({ commit }, id) {
    commit("removeProductInTab", id);
  },
  onAddQuantity({ commit }, payload) {
    commit("setQuantity", payload);
  },
  addProductInTab({ commit }, payload) {
    commit("setProductInTab", payload);
  },
  onRemoveTabList({ commit, state }, index) {
    commit("removeTabList", index);
  },
  onCurrentTab({ commit }, payload) {
    commit("setCurrentTab", payload);
  },
  onTabList({ commit }, payload) {
    commit("setTabList", payload);
  },
  onTabListContent({ commit }, payload) {
    commit("setTabListContent", payload);
  },
  onSetSubContentInTab({ commit }, payload) {
    commit("setSubContentInTab", payload);
  },
  handlePriceInTab({ commit }, payload) {
    commit("tabListContentHandledPrice", payload);
  },
  handleBillInTab({ commit }, payload) {
    commit("HANDLE_BILL_IN_TAB", payload);
  },
};
const mutations = {
  SET_CONFIRM_ORDER_ONLINE(state, status) {
    state.isConfirmOrderOnline = status;
  },
  SET_UPDATE_ORDER_ONLINE(state, status) {
    state.isUpdateOrderOnline = status;
  },
  SET_UPDATE_PAYMENT_NOT_SHIP(state, status) {
    state.isUpdatePaymentNotShipSuccess = status;
  },
  SET_IS_ORDER_SUBMIT(state, status) {
    state.isOrderSubmit = status;
  },
  SET_IS_ORDER_SUCCESS(state, status) {
    state.isOrderSuccess = status;
  },
  SET_DISABLED_SUBMIT(state, payload) {
    state.isDisableSubmit = payload;
  },
  SET_DISABLED_CHANGE_PRICE(state, payload) {
    state.isChangePrice = payload;
  },
  ON_CHANGE_NOTE(state, payload) {
    const { value, id, index } = payload;
    state.tabListContent[state.currentTab][index]["note"] = value;
    state.orderActive = state.tabListContent[state.currentTab];
  },
  ON_UPDATE_ORDER_LOCAL(state, check) {
    state.isUpdateOrder = check;
  },
  SET_ORDER_DETAIL(state, payload) {
    state.orderDetail = payload;
    state.orderProductDetail = payload.products.map((item) => {
      const { product, quantity } = item;
      return {
        price: item.product.price,
        product,
        quantity,
      };
    });
  },
  HANDLE_BILL_IN_TAB(state, payload) {
    state.billInTabs[state.currentTab] = payload;
    state.billActive = state.billInTabs[state.currentTab];
  },
  tabListContentHandledPrice(state, payload) {
    state.tabListContent = payload;
    state.orderActive = state.tabListContent[state.currentTab];
  },
  removeProductInTab(state, id) {
    const order = state.tabListContent[state.currentTab];
    const index = order.findIndex((item) => {
      return item.product.id === id;
    });
    order.splice(index, 1);
    state.orderActive = order;
  },
  setQuantity(state, payload) {
    const { product, quantity } = payload;
    const order = state.tabListContent[state.currentTab];
    order.map((item) => {
      return item.product.id === product.id
        ? (item.quantity = quantity)
        : item.quantity;
    });
    state.orderActive = order;
  },
  setProductInTab(state, payload) {
    if (!state.isUpdateOrder) {
      const order = state.tabListContent[state.currentTab];
      order.push(payload);
      state.orderActive = order;
      // state.subContentActive = state.subContentInTab[state.currentTab];
    } else {
      state.orderActive = payload;
      state.orderProductDetail = payload;
      state.tabListContent[state.currentTab] = payload;
    }
  },
  removeTabList(state, index) {
    const isFindIndex = state.tabList.findIndex((x) => {
      return x.name === index;
    });
    state.tabList.splice(isFindIndex, 1);
    delete state.billInTabs[state.currentTab];
  },
  setCurrentTab(state, payload) {
    if (
      !state.isUpdateOrder ||
      LocalStorageService.getLocalUserRole !== "CSKH"
    ) {
      state.currentTab = payload;
      state.orderActive = state.tabListContent[state.currentTab];
      state.billActive = state.billInTabs[state.currentTab];
      // state.subContentActive = state.subContentInTab[state.currentTab];
    }
  },
  setTabList(state, payload) {
    if (!Array.isArray(payload)) {
      state.tabList.push(payload);
    } else {
      state.tabList = payload;
    }
  },
  setTabListContent(state, { key, value }) {
    state.tabListContent[key] = value;
    // state.subContentInTab[key] = {
    //   warehouse: null,
    //   priceList: null,
    // };
  },
  setSubContentInTab(state, payload) {
    state.subContentActive = payload;
  },
  SET_LIST_ORDER(state, payload) {
    state.orderList = payload;
  },
  SET_LIST_ORDER_SHIPPER(state, payload) {
    state.orderShipperList = payload;
  },
  SET_ORDER_ONLINE_DETAIL(state, payload) {
    state.orderOnlineDetail = payload;
  },
  SET_ORDER_SHIPPER_DETAIL(state, payload) {
    state.orderShipperDetail = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },

  SET_LIST_TRANSACTION(state, payload) {
    state.transactionList = payload;
  },
  SET_LIST_ORDER_SHIPPER_STATISTIC(state, payload) {
    state.orderShipperStatisticList = payload;
  },
  SET_TOTAL_ORDER_SHIPPER(state, payload) {
    state.totalOrderShipper = payload;
  },
  SET_TOTAL_CUSTOMER_GAVE(state, payload) {
    state.totalCustomerGave = payload;
  },
  SET_TOTAL_INVOICE_SHIPPER(state, payload) {
    state.totalInvoiceShipper = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
