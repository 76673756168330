<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Quản lý Slider" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <router-link to="/settings/create-image">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && homepageImageList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="homepageImageList"
                  :fields="tableFields"
                >
                  <template #cell(image)="{item}">
                    <img
                      v-if="item.image"
                      height="80px"
                      :src="item.image"
                      width="80px"
                    />
                    <img
                      v-if="!item.image"
                      height="80px"
                      src="../../assets/images/image_default.png"
                      width="80px"
                    />
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="item.name">
                      {{ getLimitContent(item.name, 120) }}
                    </span>
                  </template>
                  <template v-slot:cell(disable)="{ item }">
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeStatus($event, item)"
                      v-model="item.disable"
                    /><span> Ẩn trên trang chủ</span><br />
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && homepageImageList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tableFields: [
        { key: "image", label: "Hình ảnh" },
        { key: "name", label: "Tên" },
        { key: "type", label: "Loại hình ảnh" },
        { key: "disable", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      isName: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    this.getHomepageImageList();
  },
  computed: {
    ...mapState("homepageImage", ["homepageImageList", "isLoading"]),
  },
  watch: {},
  methods: {
    ...mapActions("homepageImage", [
      "getHomepageImageList",
      "deleteHomepageImage",
      "updateHomepageImage",
    ]),
    onDelete(id) {
      this.deleteHomepageImage({ id: id });
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onEdit(item) {
      this.$router.push({
        name: "create-image",
        query: { id: item.id },
      });
    },
    getLimitContent(value, size) {
      if (!value) return "";
      value = value.toString();

      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + "...";
    },
    onChangeStatus(event, item) {
      const value = event.target.checked;
      const { image, disable, ...data } = item;
      const dataSubmit = { disable: value, ...data };
      const newsItemData = {
        id: item.id,
        data: dataSubmit,
      };
      this.updateHomepageImage(newsItemData);
    },
  },
};
</script>
