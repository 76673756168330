<template>
  <div>
    <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4">
        Bạn có muốn hủy nhập kho
      </p>
    </b-modal>
    <b-modal size="xl" id="modal-import-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Khách trả hàng
        </h3>
      </template>
      <div class="d-flex align-items-center">
        <input
          v-model="dataSubmit.name"
          :disabled="!!dataSubmit.id"
          placeholder="Tên phiếu đổi trả hàng"
          class="form-control rbt-custom-input mb-3"
          style="max-width: 100%"
          id="validationCustom1"
          type="text"
        />
      </div>
      <div class="d-sm-flex align-items-center">
        <div class="mr-3">
          <div class="d-flex align-items-center">
            <p class="mr-3 mb-0">Khách hàng</p>
            <multiselect
              v-if="!dataSubmit.id"
              ref="selectCustomer"
              @search-change="onChangeCustomer"
              placeholder="Chọn khách hàng"
              :show-labels="false"
              :custom-label="searchingSelect"
              class="rbt-custom-input"
              v-model="dataSubmit.customer"
              :options="customerList"
              :internal-search="false"
            >
              <span slot="noResult">Không tìm thấy kết quả!!!</span>
              <template slot="singleLabel" slot-scope="props"
                ><span class="option__desc"
                  ><span class="option__title">{{
                    props.option.name ||
                      props.option.phone_number ||
                      props.option.email
                  }}</span>
                </span></template
              >
              <template slot="option" slot-scope="props">
                <!-- <div class="option__desc">
              <p class="option__title">{{ props.option.phone_number }}</p>
            </div> -->
                <div class="option__desc d-block">
                  <p class="option__title font-weight-bold">
                    {{
                      props.option.name ||
                        props.option.phone_number ||
                        props.option.email
                    }}
                  </p>
                  <p class="option__small mb-0">
                    {{ props.option.phone_number }}
                  </p>
                </div>
              </template>
            </multiselect>
            <input
              v-if="dataSubmit.customer && dataSubmit.id"
              :disabled="dataSubmit.id"
              v-model="dataSubmit.customer.name"
              placeholder="Khách hàng"
              class="form-control rbt-custom-input mr-3"
              style="max-width: 100%"
              id="validationCustom1"
              type="text"
            />
          </div>
        </div>
        <div class="mr-3" style="width: 350px">
          <div class="d-flex align-items-center" style="width: 100%">
            <p style="white-space: nowrap;" class="mr-3 mb-0">Đơn hàng</p>
            <multiselect
              v-if="!dataSubmit.id"
              style="max-width: 100%"
              class="rbt-custom-input"
              placeholder="Tìm kiếm đơn hàng"
              v-model="dataSubmit.order"
              @search-change="searchOrder"
              :internal-search="false"
              :show-labels="false"
              :options="orderList"
            >
              <span slot="noResult">Không tìm thấy kết quả!!!</span>
              <template slot="singleLabel" slot-scope="props"
                ><span class="option__desc"
                  ><span class="option__title">{{
                    props.option.code
                  }}</span></span
                ></template
              >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <p class="option__title">
                    {{ props.option.code }}
                  </p>
                </div>
              </template>
            </multiselect>
            <input
              v-if="dataSubmit.order && dataSubmit.id"
              :disabled="dataSubmit.id"
              v-model="dataSubmit.order.code"
              placeholder="Khách hàng"
              class="form-control rbt-custom-input mr-3"
              style="max-width: 100%"
              id="validationCustom1"
              type="text"
            />
          </div>
        </div>
        <div class="mr-3">
          <b-spinner
            v-if="isLoading"
            variant="primary"
            label="Spinning"
          ></b-spinner>
        </div>
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Are you sure!</p>
          </b-modal>
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="refundTable"
            :fields="tableFields"
            :per-page="perPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span>
                {{ item.product.name }}
              </span>
            </template>
            <template v-slot:cell(refund)="{ item }">
              <span>
                {{ (item.quantity_return * item.price) | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(note)="{ item }">
              <div>
                <textarea
                  :disabled="dataSubmit.id"
                  v-model="item.note"
                  rows="2"
                  placeholder="Ghi chú"
                  class="form-control"
                ></textarea>
              </div>
            </template>
            <template v-slot:cell(quantity_exchange)="{ item }">
              <IncreaseAndDecrease
                v-if="!dataSubmit.id"
                style="max-width: 150px"
                :isNumber="Number(item.quantity_exchange) || 0"
                @onChangeData="onQuantityExchange"
                :product="item.product"
                :isDiscount="true"
              />
              <span v-else>
                {{ item.quantity_exchange }}
              </span>
            </template>
            <template v-slot:cell(quantity_return)="{ item }">
              <IncreaseAndDecrease
                v-if="!dataSubmit.id"
                style="max-width: 150px"
                :isNumber="Number(item.quantity_return) || 0"
                @onChangeData="onQuantityReturn"
                :product="item.product"
                :isDiscount="true"
              />
              <span v-else>
                {{ item.quantity_return }}
              </span>
            </template>
            <template v-slot:cell(return_warehouse)="{ item }">
              <IncreaseAndDecrease
                v-if="!dataSubmit.id"
                style="max-width: 150px"
                :isNumber="Number(item.return_warehouse) || 0"
                @onChangeData="onReturnWarehouse"
                :product="item.product"
                :isDiscount="true"
              />
              <span v-else>
                {{ item.return_warehouse }}
              </span>
            </template>
            <template v-slot:cell(cancel_quantity)="{ item }">
              {{ item.total_quantity - item.return_warehouse }}
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{ item.totalPrice | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span
                v-if="!dataSubmit.id"
                style="font-size: 20px; color: #FA399F;"
                @click="removeItemInTable(item.sno)"
              >
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                ></feather>
              </span>
            </template>
          </b-table>
        </div>
        <!-- <div>
          <textarea
            v-model="dataSubmit.reason"
            rows="2"
            placeholder="Lý do trả hàng"
            class="form-control"
          ></textarea>
        </div> -->
        <div class="mt-3">
          <p>
            Tổng tiền trả lại:
            <b v-if="totalPriceRefund()">
              {{ totalPriceRefund() | formatCurrency }}
            </b>
            <b v-else>
              0 đ
            </b>
          </p>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-end">
          <div class="d-flex">
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              class="btn-popup my-0 mr-3 d-flex align-items-center"
              @click="submit(1)"
            >
              <feather class="mr-2" type="dollar-sign"></feather>
              <span>
                Tiền mặt
              </span>
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              class="btn-popup my-0 d-flex align-items-center"
              @click="submit(2)"
            >
              <feather class="mr-2" type="credit-card"></feather>
              <span>
                Tài khoản
              </span>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { statusCheckWarehouse } from "../../config/status-default";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { lowerCase } from "lodash";

const initalDataImport = {
  unit: null,
  price: 0,
  quantity_exchange: 0, //Số lượng đổi
  quantity_return: 0, // Số lượng trả lại
  total: 0,
  product: 0,
  note: "",
  return_warehouse: 0, //Số lượng trả kho
  refund: 0, //giá trị cần hoàn
};

const initalDataSubmit = {
  products: [],
  name: "",
  total_change_return: 0, //Tổng giá trị đổi trả
  refund: 0, // Số tiền hoàn
  customer: null,
  status: 0,
  order: null,
  payment_method: 0,
};

export default {
  name: "CustomerRefundModal",
  components: {
    IncreaseAndDecrease,
  },
  props: {
    refundInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      perPage: 10,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quantity_exchange", label: "Số lượng đổi hàng" },
        { key: "quantity_return", label: "Số lượng trả hàng" },
        { key: "price", label: "Đơn giá" },
        { key: "totalPrice", label: "Thành tiền" },
        { key: "refund", label: "Hoàn Tiền cho khách" },
        { key: "return_warehouse", label: "Nhập lại kho" },
        { key: "cancel_quantity", label: "Hàng hủy" },
        { key: "note", label: "Ghi chú" },
        { key: "actions", label: "xóa", ignore: true },
      ],
      productRefund: [],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      refundTable: [],
    };
  },
  created() {
    this.getCustomerList();
  },
  methods: {
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions("customer", ["getCustomerList"]),
    ...mapActions("order", ["getOrderList", "getOrderDetail"]),
    ...mapActions(["setMessage"]),
    onChangeCustomer: _.debounce(function(value) {
      const params = { query: value.trim() };
      this.getCustomerList(params);
    }, 250),
    searchOrder: _.debounce(function(value) {
      const customer = this.dataSubmit.customer;
      const params = { customer: customer?.id, search: value.trim() };
      this.getOrderList(params);
    }, 250),
    totalPriceRefund() {
      let total = 0;
      if (this.refundTable.length > 0) {
        this.refundTable.forEach((item) => {
          total += item.quantity_return * item.price;
        });
      }
      return total;
    },
    onReturnWarehouse({ quantity, product }) {
      this.refundTable.forEach((item) => {
        if (item.product.id === product.id && quantity <= item.total_quantity) {
          item.return_warehouse = quantity;
        } else if (item.product.id === product.id) {
          this.setMessage({
            type: "error",
            content: "Vượt quá số lượng",
            isShow: true,
          });
        }
      });
      this.totalPriceRefund();
    },
    onQuantityExchange({ quantity, product }) {
      this.refundTable.forEach((item) => {
        const { total_quantity } = item;
        if (
          item.product.id === product.id &&
          quantity + item.quantity_return <= total_quantity
        ) {
          item.quantity_exchange = quantity;
        } else if (item.product.id === product.id) {
          this.setMessage({
            type: "error",
            content: "Vượt quá số lượng",
            isShow: true,
          });
        }
      });
      this.totalPriceRefund();
    },
    onQuantityReturn({ quantity, product }) {
      this.refundTable.forEach((item) => {
        const { total_quantity } = item;
        if (
          item.product.id === product.id &&
          item.quantity_exchange + quantity <= total_quantity
        ) {
          item.quantity_return = quantity;
        } else if (item.product.id === product.id) {
          this.setMessage({
            type: "error",
            content: "Vượt quá số lượng",
            isShow: true,
          });
        }
      });
      this.totalPriceRefund();
    },
    removeItemInTable(index) {
      this.refundTable?.splice(index - 1, 1);
      this.refundTable = this.refundTable.map((item, index) => {
        const { sno, ...data } = item;
        return {
          sno: index + 1,
          ...data,
        };
      });
      this.totalPriceRefund();
    },
    submit(paymentMethod) {
      this.dataSubmit.products = this.refundTable.map((item) => {
        const {
          sno,
          totalPrice,
          total_quantity,
          cancel_quantity,
          product,
          refund,
          ...data
        } = item;
        return {
          ...data,
          refund: item.price * item.quantity_return,
          unit: item.product.unit,
          product: product.id,
        };
      });
      const {
        products,
        customer,
        order,
        name,
        payment_method,
        ...data
      } = this.dataSubmit;
      if (products.length > 0 && customer && order && name) {
        this.$emit("onSubmit", {
          name,
          products,
          customer: customer.id,
          order: order.id,
          payment_method: paymentMethod,
          ...data,
        });
        this.$bvModal.hide("modal-import-warehouse");
      } else {
        this.setMessage({
          type: "error",
          content:
            "Thiếu thông tin khách hàng, tên phiếu trả hàng hoặc sản phẩm",
          isShow: true,
        });
      }
    },
    update() {
      this.$bvModal.hide("modal-import-warehouse");
    },
    onEmitSubmit({ type }) {},
    openModelCancel() {
      this.$bvModal.show("model-cancel-import");
    },
    cancelImport() {},
    addProduct() {
      const { product, quantity, price } = this.dataImport;
      if ((product, quantity > 0, price > 0)) {
        this.dataSubmit?.product?.push({
          product: product,
          unit: product?.unit,
          quantity,
          price,
        });
        this.dataImport = { ...initalDataImport };
      } else {
        alert("Sản phẩm, đơn giá và số lượng không được để trống !");
      }
    },
    searchingSelect({ name }) {
      return name;
    },
  },
  computed: {
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", ["isListProduct"]),
    ...mapState("customer", ["customerList"]),
    ...mapState("order", ["orderList", "isLoading", "orderDetail"]),
    tableFields() {
      const tableField = this.dataSubmit.id
        ? this.tablefields.filter((item) => !item.ignore)
        : this.tablefields;
      return tableField;
    },
    // productsActive() {
    //   const idProductInTable = this.dataSubmit?.product?.map((item) => {
    //     return item.product.id;
    //   });
    //   return this.isListProduct?.filter((item) => {
    //     return !idProductInTable?.includes(item.id);
    //   });
    // },
    itemsImport() {
      return this.dataSubmit.products?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    sortOptions() {
      // Create an options list from our fields
      return this.tableFields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  watch: {
    orderDetail() {
      if (this.orderDetail && !this.dataSubmit.id) {
        this.refundTable = this.orderDetail.products.map((item, index) => {
          const { price, quantity, product, price_after_promotion } = item;
          return {
            sno: index + 1,
            product,
            quantity_return: 0,
            total_quantity: quantity,
            quantity_exchange: quantity,
            price: price_after_promotion,
            totalPrice: price_after_promotion * quantity,
            refund: 0,
            cancel_quantity: 0,
            return_warehouse: quantity,
            note: "",
          };
        });
      }
    },
    "dataSubmit.order"() {
      if (!this.dataSubmit.id) {
        const order = this.dataSubmit.order;
        if (order) {
          this.getOrderDetail(order.id);
        }
        this.refundTable = [];
      }
    },
    "dataSubmit.customer"() {
      if (!this.dataSubmit.id) {
        const customer = this.dataSubmit.customer;
        if (customer) {
          this.getOrderList({ customer: customer.id });
        } else {
          this.getOrderList();
        }
        this.dataSubmit.order = null;
      }
    },
    isListProduct() {
      this.productRefund =
        this.isListProduct?.map((item) => {
          const { id, name, price, unit } = item;
          return {
            id,
            name,
            price,
            unit,
            quantity: 1,
          };
        }) || [];
    },
    refundInfo() {
      if (this.refundInfo.id) {
        this.dataSubmit = { ...this.refundInfo };
        this.refundTable = this.refundInfo.products.map((item, index) => {
          const {
            price,
            product,
            return_warehouse,
            note,
            quantity_cancel,
            quantity_exchange,
            quantity_return,
            price_after_promotion,
          } = item;
          return {
            sno: index + 1,
            product,
            quantity_return,
            total_quantity: quantity_cancel + return_warehouse,
            quantity_exchange,
            price: price,
            refund: quantity_return * price,
            cancel_quantity: quantity_cancel,
            return_warehouse,
            totalPrice: price * (quantity_cancel + return_warehouse),
            note,
          };
        });
      } else {
        this.dataSubmit = { ...initalDataSubmit };
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>
