<template>
  <div>
    <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4" v-if="this.dataSubmit.warehouse_import">
        Bạn có muốn hủy nhập kho
        <b>{{ this.dataSubmit.warehouse_import.name }}</b>
      </p>
    </b-modal>
    <b-modal
      size="lg"
      :no-close-on-backdrop="true"
      id="modal-import-warehouse"
      centered
    >
      <template #modal-header>
        <h3
          v-if="dataSubmit.warehouse_export"
          class="mx-auto"
          style="color: #ff8084"
        >
          Xuất kho
        </h3>
        <h3 v-else class="mx-auto" style="color: #ff8084">
          Nhập kho
        </h3>
      </template>
      <validation-provider
        name="Tên phiếu nhập"
        :rules="{ required: true }"
        v-slot="validationContext"
      >
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label>
            Tên phiếu nhập<span class="validate-star"> *</span></template
          >
          <b-form-input
            :disabled="dataSubmit.status >= 1"
            v-model="dataSubmit.name"
            placeholder="Nhập tên phiếu nhập"
            :state="getValidationState(validationContext)"
          ></b-form-input>
          <b-form-invalid-feedback>{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div class="form-row mt-4">
        <div class="col-md-6">
          <validation-provider
            name="Kho xuất"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-md="2"
              label-cols-lg="4"
              content-colsmd
              content-cols-lg="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Kho xuất
              </template>
              <multiselect
                :disabled="
                  dataSubmit.status >= 1 || !!dataSubmit.order_supplier
                "
                class="rbt-custom-input"
                style="max-width: 350px !important;"
                v-model="dataSubmit.warehouse_export"
                placeholder="Chọn kho xuất"
                :show-labels="false"
                :custom-label="searchingSelect"
                @input="onChange"
                :options="warehouseList"
              >
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="col-md-6">
          <validation-provider
            name="Kho xuất"
            :rules="{
              required: true,
            }"
          >
            <b-form-group
              id="exampleInputGroup3"
              label-cols-md="4"
              label-cols-lg="3"
              content-cols-lg
              content-cols-md="8"
              label-for="exampleInput3"
            >
              <template v-slot:label>
                Kho nhập <span class="validate-star"> *</span>
              </template>
              <multiselect
                :disabled="dataSubmit.status >= 1"
                class="rbt-custom-input"
                style="max-width: 350px !important;"
                v-model="dataSubmit.warehouse_import"
                placeholder="Chọn kho nhập"
                :show-labels="false"
                :custom-label="searchingSelect"
                :options="warehouseList"
              >
                <span slot="noResult">Không tìm thấy kết quả!!!</span>
                <template slot="singleLabel" slot-scope="props"
                  ><span class="option__desc"
                    ><span class="option__title">{{
                      props.option.name
                    }}</span></span
                  ></template
                >
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <p class="option__title">
                      {{ props.option.name }}
                    </p>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
          </validation-provider>
        </div>
      </div>
      <validation-provider
        name="Đơn hàng nhà cung cấp"
        :rules="{ required: false }"
        v-slot="validationContext"
        v-if="dataSubmit.order_supplier || !dataSubmit.id"
      >
        <b-form-group
          label-cols-md="2"
          label-cols-lg="2"
          content-cols-sm
          content-cols-md="10"
        >
          <template v-slot:label> Đơn hàng nhà cung cấp</template>
          <multiselect
            :disabled="!!dataSubmit.warehouse_export || dataSubmit.status >= 1"
            v-model="dataSubmit.order_supplier"
            class="rbt-custom-input"
            style="max-width: 100% !important"
            placeholder="Đơn hàng nhà cung cấp"
            :show-labels="false"
            @input="onChangeOrderSupplier"
            @search-change="searchOrder"
            :internal-search="false"
            :options="ordersSupplier"
          >
            <span slot="noOptions">
              Không có sản phẩm
            </span>
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
          <b-form-invalid-feedback>{{
            validationContext.errors[0]
          }}</b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
      <div
        v-if="
          !dataSubmit.order_supplier &&
            (dataSubmit.status < 2 || !dataSubmit.id)
        "
        class="mt-3 form-row"
      >
        <div class="col-md-2 mt-2">
          Sản phẩm
        </div>
        <div class="col-md-3 mr-3">
          <multiselect
            class="rbt-custom-input"
            v-model="dataImport.product"
            placeholder="Chọn sản phẩm"
            :show-labels="false"
            @search-change="search"
            @input="changeProduct"
            :internal-search="false"
            :options="productsActive"
          >
            <span slot="noOptions">
              Không có sản phẩm
            </span>
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
        </div>
        <div class="col-md-3 d-flex">
          <validation-provider
            name="Số lượng"
            :rules="{ greaterThanZero: true }"
            v-slot="validationContext"
          >
            <b-form-group>
              <b-form-input
                v-model="dataImport.quantity"
                placeholder="Số lượng"
                class="form-control rbt-custom-input"
                id="validationCustom1"
                @change="changeNumber"
                type="number"
                min="1"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <span
            class="m-3"
            v-if="
              dataImport && dataImport.product && dataSubmit.warehouse_export
            "
            >{{ dataImport.product.product_inventory_quantity }}</span
          >
        </div>
        <div class="col-md-3">
          <validation-provider
            name="Giá"
            :rules="{}"
            v-slot="validationContext"
          >
            <b-form-group>
              <b-form-input
                v-model="dataImport.price"
                placeholder="Giá"
                class="form-control rbt-custom-input"
                id="validationCustom1"
                :state="getValidationState(validationContext)"
              ></b-form-input>
              <b-form-invalid-feedback>{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </div>
        <div class="d-flex align-items-center mb-3 ml-3">
          <div
            class="d-flex"
            style="border-radius: 50%; background-color: #28a745"
            @click="addProduct()"
          >
            <feather
              class="rbt-icon-plus"
              style="cursor: pointer"
              type="plus"
              stroke="#f5fbf6"
              size="28px"
              fill="#f5fbf6"
            ></feather>
          </div>
        </div>
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="itemsImport"
            :fields="computedFields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.product.name }} </span>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                <template v-if="item.product.unit == item.product.import_unit">
                  {{ item.price | formatCurrency }}
                </template>
                <template v-else>
                  {{ item.product.price | formatCurrency }}
                </template>
                <span v-if="item.product.unit">/{{ item.product.unit }}</span>
              </span>
            </template>
            <template v-slot:cell(product_inventory_quantity)="{ item }">
              <span>
                {{ item.product.product_inventory_quantity }}
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <div
                class="d-flex justify-content-center align-items-center"
                v-if="dataSubmit.status != 2 && dataSubmit.status != 3"
              >
                <IncreaseAndDecrease
                  style="max-width: 150px"
                  :isNumber="Number(item.quantity) || 0"
                  @onChangeData="onIncreAndDecre"
                  :isGreaterZero="true"
                  :product="item.product"
                  :isWarehouseExport="dataSubmit.warehouse_export"
                  :isDiscount="true"
                />
              </div>
              <div v-else>
                <span>
                  {{ item.quantity || 0 }}
                </span>
              </div>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span v-if="item.product.unit == item.product.import_unit">
                {{
                  Math.trunc(
                    item.quantity * Number(item.price.replace(/,/g, ""))
                  ) | formatCurrency
                }}
              </span>
              <span v-else>
                {{
                  Math.trunc(item.quantity * item.product.price)
                    | formatCurrency
                }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span
                v-if="dataSubmit.status != 2 && dataSubmit.status != 3"
                style="font-size: 20px; color: #FA399F;"
                @click="removeItemInTable(item.sno)"
              >
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                ></feather>
              </span>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="itemsImport.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="itemsImport.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
        <div>
          <textarea
            :disabled="dataSubmit.status >= 2"
            v-model="dataSubmit.note"
            rows="2"
            @change="changeNote"
            placeholder="Ghi chú nhập kho"
            class="form-control"
          ></textarea>
        </div>
        <div class="mt-3" v-if="!this.dataSubmit.order_supplier">
          <p>
            Tổng tiền:
            <b v-if="totalPriceAll">
              {{ totalPriceAll | formatCurrency }}
            </b>
            <b v-else>
              0 đ
            </b>
          </p>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <b-button
              v-if="dataSubmit.status === 1"
              type="button"
              class="m-0 btn btn-danger"
              @click="openModelCancel()"
            >
              <span>
                Hủy nhập kho
              </span>
            </b-button>
          </div>
          <div>
            <b-button
              class="mr-3"
              size="sm"
              variant="danger"
              @click="
                cancel();
                checkDisable = false;
                dataSubmit.products.splice(0, dataSubmit.products.length);
              "
            >
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.status"
              :disabled="!isComplete"
              class="btn-popup m-0"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              Nhập kho</b-button
            >
            <b-button
              v-if="dataSubmit.status === 1"
              class="btn-popup mr-3 my-0"
              :disabled="!isComplete"
              @click="update()"
            >
              Cập nhật</b-button
            >
            <b-button
              v-if="dataSubmit.status === 1"
              :disabled="checkDisable"
              class="btn-popup m-0"
              @click="approved()"
            >
              Duyệt</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { statusCheckWarehouse } from "../../config/status-default";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { extend } from "vee-validate";
import { addCommaPrice } from "../../config/common";

extend("greaterThanZero", (value) => {
  if (value > 0) return true;
  return "Số lượng phải lớn hơn 0";
});

const initalDataImport = {
  product: null,
  quantity: null,
  price: null,
};

const initalDataSubmit = {
  products: [],
  name: "",
  warehouse_import: null,
  warehouse_export: null,
  note: "",
  order_supplier: null,
};

export default {
  props: {
    warehouseImportInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    IncreaseAndDecrease,
  },
  name: "WarehouseImport",
  data() {
    return {
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quantity", label: "Số lượng" },
        { key: "product_inventory_quantity", label: "Tồn kho" },
        { key: "price", label: "Đơn giá" },
        { key: "totalPrice", label: "Thành tiền" },
        {
          key: "actions",
          label: "Xóa",
          require: true,
        },
      ],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      checkDisable: false,
      orderSupplierImport: null,
    };
  },
  async created() {
    this.getWarehouseList();
    this.onListProduct();
    this.getUnitList();
  },
  methods: {
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions("supplier", ["getOrderSupplier"]),
    removeItemInTable(index) {
      this.dataSubmit?.products.splice(index - 1, 1);
      this.checkDisable = true;
    },
    submit() {
      if (
        this.dataSubmit?.products?.length > 0 &&
        this.dataSubmit.warehouse_import
      ) {
        this.onEmitSubmit({
          type: "add",
        });
        this.$bvModal.hide("modal-import-warehouse");
        this.dataSubmit.products.splice(0, this.dataSubmit.products.length);
      } else {
        alert("Vui lòng chọn sản phẩm và kho muốn nhập");
      }
    },
    approved() {
      this.$emit("onApproved", {
        id: this.dataSubmit.id,
        status: statusCheckWarehouse.APPROVED,
      });
      this.$bvModal.hide("modal-import-warehouse");
    },
    update() {
      if (
        this.dataSubmit?.products?.length > 0 &&
        this.dataSubmit.warehouse_import
      ) {
        this.onEmitSubmit({
          type: "update",
        });
        this.$bvModal.hide("modal-import-warehouse");
      } else {
        alert("Vui lòng chọn sản phẩm và kho muốn nhập");
      }
    },
    onEmitSubmit({ type }) {
      const {
        products,
        warehouse_import,
        warehouse_export,
        order_supplier,
        ...data
      } = this.dataSubmit;
      const productsId = products?.map((item) => {
        const { price, quantity } = item;
        return {
          product: item.product.id,
          price: Number(price.replace(/,/g, "")),
          quantity: parseFloat(quantity),
        };
      });
      if (type === "add") {
        this.$emit("onSubmit", {
          products: productsId,
          created_at: new Date(),
          order_supplier: order_supplier?.id,
          warehouse_import: warehouse_import?.id,
          warehouse_export: warehouse_export?.id,
          ...data,
        });
        this.checkDisable = false;
      } else if (type === "update") {
        this.$emit("onUpdate", {
          products: productsId,
          created_at: new Date(),
          order_supplier: order_supplier?.id,
          warehouse_import: warehouse_import?.id,
          warehouse_export: warehouse_export?.id,
          ...data,
        });
        this.checkDisable = false;
      }
    },
    openModelCancel() {
      this.$bvModal.show("model-cancel-import");
    },
    cancelImport() {
      this.$emit("onCancelWarehouseImport", {
        id: this.dataSubmit?.id,
        status: statusCheckWarehouse.CANCEL,
      });
      this.$bvModal.hide("modal-import-warehouse");
    },
    addProduct() {
      const { product, quantity, price } = this.dataImport;
      if (product && quantity > 0 && Number(price.replace(/,/g, "")) >= 0) {
        this.dataSubmit?.products?.push(this.dataImport);
        this.dataImport = { ...initalDataImport };
        this.checkDisable = true;
      } else {
        alert("Sản phẩm, đơn giá và số lượng không được để trống !");
      }
    },
    searchingSelect({ name }) {
      return name;
    },
    getValidationState({ validated, valid = null }) {
      valid = valid ? null : valid;
      return validated ? valid : null;
    },
    async onChange(warehouse) {
      this.dataImport.product = null;
      this.dataSubmit.products = [];
      if (warehouse) {
        const { id } = warehouse;
        await this.onListProduct({ warehouse_id: id });
      } else {
        await this.onListProduct();
      }
    },
    search: _.debounce(function(query) {
      if (this?.dataSubmit?.warehouse_export) {
        const { id } = this?.dataSubmit?.warehouse_export;
        this.onListProduct({ name: query, warehouse_id: id });
      } else {
        this.onListProduct({ name: query });
      }
    }, 350),
    onIncreAndDecre({ quantity, product }) {
      this.dataSubmit?.products?.forEach((item) => {
        if (item.product.id === product.id) {
          item.quantity = quantity;
        }
      });
      this.checkDisable = true;
    },
    changeNumber(value) {
      if (this.dataSubmit.warehouse_export) {
        const { product_inventory_quantity } = this?.dataImport?.product;
        if (
          product_inventory_quantity &&
          value > Number(product_inventory_quantity)
        ) {
          alert("Số lượng nhập đang lớn hơn tồn kho");
          this.dataImport.quantity = "";
        }
      }
    },
    changeNote() {
      this.checkDisable = true;
    },
    changeProduct(product) {
      if (product) {
        const { product_inventory_quantity } = product;
        if (
          this.dataSubmit.warehouse_export &&
          product_inventory_quantity <= 0
        ) {
          alert("Sản phầm này tồn kho <= 0, chọn sản phẩm khác");
          this.dataImport.product = "";
        }
      }
    },
    onChangeOrderSupplier() {
      this.dataSubmit.products = [];
      this.dataSubmit.order_supplier?.product.forEach((item) => {
        const { price, quantity, product } = item;
        this.dataImport = {
          price: price.toString(),
          quantity,
          product,
        };
        this.addProduct();
        this.dataImport = { ...initalDataImport };
      });
    },
    searchOrder: _.debounce(function(value) {
      const params = { search: value.trim() };
      this.getOrderSupplier(params);
    }, 250),
  },
  computed: {
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("product", ["isListProduct"]),
    ...mapState("unit", ["unitList"]),
    ...mapState("supplier", ["ordersSupplier"]),
    totalPriceAll() {
      let total = 0;
      if (this.dataSubmit?.products?.length > 0) {
        this.dataSubmit?.products?.forEach((item) => {
          total += item.quantity * Number(item.price.replace(/,/g, ""));
        });
      }
      return parseInt(total);
    },
    productsActive() {
      const idProductInTable = this.dataSubmit?.products?.map((item) => {
        return item.product.id;
      });
      return this.isListProduct?.filter((item) => {
        return !idProductInTable?.includes(item.id);
      });
    },
    itemsImport() {
      return this.dataSubmit?.products?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    computedFields() {
      if (
        this.dataSubmit.status != 2 &&
        this.dataSubmit.status != 3 &&
        !this.dataSubmit.order_supplier
      )
        return this.tablefields;
      else return this.tablefields.filter((field) => !field.require);
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    warehouseImportInfo() {
      if (this.warehouseImportInfo.id) {
        const { products, ...data } = this.warehouseImportInfo;
        const productsConvertPrice = products?.map((item) => {
          const { price, ...data } = item;
          return {
            price: addCommaPrice(price),
            ...data,
          };
        });
        this.dataSubmit = { products: productsConvertPrice, ...data };
      } else {
        this.dataSubmit = { ...initalDataSubmit };
      }
    },
    "dataImport.quantity": {
      handler: function(valueAfter, valuebBefore) {
        if (valueAfter) {
          const result = this.unitList.filter(
            (item) => item.name == this.dataImport?.product?.unit
          );
          if (result && result.length > 0) {
            const { is_real } = result[0];
            if (is_real) {
              this.$nextTick(
                () => (this.dataImport.quantity = parseFloat(valueAfter))
              );
            } else {
              this.$nextTick(
                () => (this.dataImport.quantity = parseInt(valueAfter))
              );
            }
          } else {
            this.$nextTick(
              () => (this.dataImport.quantity = parseInt(valueAfter))
            );
          }
        }
      },
      deep: true,
    },
    "dataImport.price"() {
      const priceImport = this.dataImport.price;
      if (typeof priceImport == "string") {
        const result = addCommaPrice(priceImport);
        this.$nextTick(() => (this.dataImport.price = result));
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.validate-star {
  color: red;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>
