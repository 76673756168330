<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo cáo doanh thu" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <date-picker
                  v-model="date"
                  value-type="format"
                  range
                  class="col-md-4 ml-3"
                  format="DD-MM-YYYY"
                  placeholder="Chọn ngày để xem"
                  @change="onChangeParam"
                ></date-picker>
                <div class="col-md-1">
                  <b-button
                    class="btn-popup mb-0"
                    variant="primary"
                    @click="onChangeParam"
                    >Tìm</b-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="orderProfitReportList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(total)="{ item }">
                    <span>
                      {{ item.total | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(discount)="{ item }">
                    <span>
                      {{ Math.abs(item.discount) | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(revenue)="{ item }">
                    <span>
                      {{ item.revenue | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(date)="{ item }">
                    <span>
                      {{ item.date | dateFormat }}
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && orderProfitReportList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalProfitRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalProfitRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { convertDate } from "../../config/common";

export default {
  data() {
    return {
      tablefields: [
        { key: "date", label: "Thời gian" },
        { key: "total", label: "Tổng tiền" },
        { key: "discount", label: "Giảm giá" },
        { key: "revenue", label: "Doanh thu" },
      ],
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      date: "",
      customer: "",
      status: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getOrderProfitReportList(params);
  },
  computed: {
    ...mapState("reports", [
      "orderProfitReportList",
      "totalProfitRecord",
      "isLoading",
    ]),
  },
  methods: {
    ...mapActions("reports", ["getOrderProfitReportList"]),
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.customer) {
        params = { customer: this.customer.id, ...params };
      }
      if (this.status) {
        params = { status: this.status, ...params };
      }
      this.getOrderProfitReportList(params);
      this.$router.push({
        path: "/reports/order-profit",
        query: { page: this.pageNumber },
      });
    },
    onChangeParam() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.customer) {
        params = { customer: this.customer.id, ...params };
      }
      if (this.status) {
        params = { status: this.status, ...params };
      }
      this.getOrderProfitReportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/reports/order-profit",
          query: { page: this.pageNumber },
        });
      }
    },
  },
};
</script>
