<template>
  <div>
    <!-- <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4">
        Bạn có muốn hủy nhập kho
      </p>
    </b-modal> -->
    <b-modal hide-footer size="lg" id="modal-import-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Nấu món ăn
        </h3>
      </template>
      <!-- <div class="d-flex align-items-center"></div> -->

      <validation-observer ref="observer" v-slot="{ invalid }">
        <form
          class="needs-validation add-product-form"
          @submit.stop.prevent="onSubmit()"
        >
          <div class="form">
            <div class="form-group mb-3">
              <validation-provider
                :rules="{ required: true }"
                v-slot="validationContext"
                name="Ngày dự định nấu"
              >
                <b-form-group
                  id="example-input-group-1"
                  label-cols-xl="3"
                  content-cols-xl="9"
                  label-cols-sm="3"
                  content-cols-sm="9"
                  label-for="example-input-1"
                >
                  <template v-slot:label>
                    Ngày dự định nấu <span> *</span>
                  </template>
                  <b-form-datepicker
                    :disabled="!!dataSubmit.id && dataSubmit.status !== 0"
                    v-model="dataSubmit.cooking_date"
                    :state="getValidationState(validationContext)"
                    label-today
                    class="rbt-custom-input rbt-date-food-modal"
                    label-help
                    id="datepicker-placeholder"
                    placeholder=""
                    style="font-size: 14px"
                    locale="vi"
                  ></b-form-datepicker>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="form-group mb-3">
              <validation-provider
                :rules="{ required: true }"
                v-slot="validationContext"
                name="Tên món ăn"
              >
                <b-form-group
                  id="example-input-group-1 d-flex align-items-center"
                  label-cols-xl="3"
                  content-cols-xl="9"
                  label-cols-sm="3"
                  content-cols-sm="9"
                  label-for="example-input-1"
                >
                  <template v-slot:label> Tên món ăn<span> *</span> </template>
                  <multiselect
                    v-model="dataSubmit.food"
                    :disabled="!!dataSubmit.id"
                    class="rbt-custom-input px-0"
                    placeholder=""
                    :show-labels="false"
                    :custom-label="searchingSelect"
                    :state="getValidationState(validationContext)"
                    :options="isListProduct"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="form-group mb-3">
              <validation-provider
                :rules="{ required: true }"
                v-slot="validationContext"
                name="Số lượng"
              >
                <b-form-group
                  id="example-input-group-1"
                  label-cols-xl="3"
                  content-cols-xl="9"
                  label-cols-sm="3"
                  content-cols-sm="9"
                  label-for="example-input-1"
                >
                  <template v-slot:label> Số lượng<span> *</span> </template>
                  <b-form-input
                    :disabled="!!dataSubmit.id && dataSubmit.status !== 0"
                    v-model="dataSubmit.quantity"
                    :state="getValidationState(validationContext)"
                    id="example-input-1"
                    name="example-input-1"
                    aria-describedby="input-1-live-feedback"
                    type="number"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="form-group mb-3">
              <validation-provider
                :rules="{ required: false }"
                v-slot="validationContext"
                name="Ghi chú nấu ăn"
              >
                <b-form-group
                  id="example-input-group-1"
                  label-cols-xl="3"
                  content-cols-xl="9"
                  label-cols-sm="3"
                  content-cols-sm="9"
                  label-for="example-input-1"
                >
                  <template v-slot:label>
                    Ghi chú nấu ăn
                  </template>
                  <b-form-textarea
                    :disabled="!!dataSubmit.id && dataSubmit.status !== 0"
                    v-model="dataSubmit.note"
                    :state="getValidationState(validationContext)"
                    id="example-input-1"
                    size="sm"
                    name="example-input-1"
                  ></b-form-textarea>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="form-group mb-3">
              <validation-provider
                :rules="{ required: true }"
                v-slot="validationContext"
                name="Bếp"
              >
                <b-form-group
                  id="example-input-group-1 d-flex align-items-center"
                  label-cols-xl="3"
                  content-cols-xl="9"
                  label-cols-sm="3"
                  content-cols-sm="9"
                  label-for="example-input-1"
                >
                  <template v-slot:label> Bếp<span> *</span> </template>
                  <multiselect
                    v-model="dataSubmit.kitchen_warehouse"
                    :disabled="!!dataSubmit.id"
                    class="rbt-custom-input px-0"
                    placeholder=""
                    :show-labels="false"
                    :custom-label="searchingSelect"
                    :state="getValidationState(validationContext)"
                    :options="kitchenWarehouse"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="form-group mb-3">
              <validation-provider
                :rules="{ required: true }"
                v-slot="validationContext"
                name="Kho nguyên liệu"
              >
                <b-form-group
                  id="example-input-group-1 d-flex align-items-center"
                  label-cols-xl="3"
                  content-cols-xl="9"
                  label-cols-sm="3"
                  content-cols-sm="9"
                  label-for="example-input-1"
                >
                  <template v-slot:label>
                    Kho nguyên liệu<span> *</span>
                  </template>
                  <multiselect
                    :disabled="!!dataSubmit.id && dataSubmit.status !== 0"
                    v-model="dataSubmit.material_warehouse"
                    class="rbt-custom-input px-0"
                    placeholder=""
                    :show-labels="false"
                    :custom-label="searchingSelect"
                    :state="getValidationState(validationContext)"
                    :options="materialWarehouse"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </b-form-group>
              </validation-provider>
            </div>
          </div>
          <!-- </form>
      </validation-observer> -->

          <div class="mt-5">
            <h6 class="font-weight-bold black-text">
              Định mức
            </h6>
            <div class="table-responsive datatable-vue text-center mt-4">
              <b-modal
                id="model-remove"
                title="Xác nhận"
                @ok="removeProductInTab()"
              >
                <p class="my-4">Are you sure!</p>
              </b-modal>
              <b-table
                show-empty
                striped
                hover
                head-variant="light"
                bordered
                stacked="md"
                :fields="tablefields"
                :per-page="perPage"
                :items="dataTable"
              >
                <template #empty>
                  <span class="text-danger">
                    Chưa có sản phẩm nào
                  </span>
                </template>
                <template v-slot:cell(actions)="{ item }">
                  <span style="font-size: 20px; color: #FA399F;">
                    <feather
                      style="cursor: pointer"
                      type="trash"
                      stroke="#F72E9F"
                      size="18px"
                      fill="#F72E9F"
                    ></feather>
                  </span>
                </template>
              </b-table>
            </div>
          </div>
          <div style="width: 100%" class="d-flex justify-content-between">
            <div>
              <b-button
                v-if="dataSubmit.id && dataSubmit.status === 0"
                style="background-color: red !important"
                class="mr-3"
                size="sm"
                variant="danger"
                @click="cancelFood()"
              >
                Hủy
              </b-button>
            </div>
            <div class="pull-right">
              <b-button
                class="mr-3"
                size="sm"
                variant="danger"
                @click="modalCancel()"
              >
                Trở lại
              </b-button>
              <b-button
                v-if="!dataSubmit.id"
                type="submit"
                class="btn-popup m-0"
              >
                Lưu lại
              </b-button>
              <b-button
                v-if="dataSubmit.id && dataSubmit.status === 0"
                class="btn-popup m-0"
                @click="onSubmit()"
              >
                Duyệt
              </b-button>
            </div>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";

const initalDataSubmit = {
  quantity: null,
  cooking_date: null,
  note: null,
  status: 0,
  food: null,
  kitchen_warehouse: null,
  material_warehouse: null,
};

export default {
  props: {
    foodInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      perPage: 10,
      tablefields: [
        { key: "sno", label: "STT", sortable: true },
        { key: "name", label: "Nguyên liệu", sortable: false },
        { key: "quantity", label: "Số lượng", sortable: true },
        // { key: "actions", label: "xóa", sortable: true },
      ],
      dataSubmit: { ...initalDataSubmit },
      kitchenWarehouse: [],
      materialWarehouse: [],
    };
  },
  async created() {
    const params = { have_quantitations: true };
    this.onListProduct(params);
    const warehouseTemp = await this.getWarehouseList();
    this.kitchenWarehouse = warehouseTemp?.data.data.results || [];
    this.materialWarehouse = warehouseTemp?.data.data.results || [];
  },
  methods: {
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions(["setMessage"]),
    ...mapActions("product", ["onListProduct"]),
    cancelFood() {
      this.$emit("onUpdate", {
        id: this.dataSubmit.id,
        status: 2,
      });
      this.$bvModal.hide("modal-import-warehouse");
    },
    modalCancel() {
      this.$bvModal.hide("modal-import-warehouse");
    },
    async handleChangeFoodAndQuantity() {
      const { quantity, food } = this.dataSubmit;
      if (!this.dataSubmit?.id) {
        this.dataSubmit.material_warehouse = null;
      }
      if (quantity && food) {
        const warehouseTemp = await this.getWarehouseList({
          product_id: food.id,
          quantity,
        });
        const responseWarehouse = warehouseTemp?.data.data.results;
        if (responseWarehouse?.length <= 0) {
          this.dataSubmit.material_warehouse = null;
        }
        this.materialWarehouse = responseWarehouse || [];
      }
    },
    onSubmit() {
      const {
        sno,
        material_warehouse,
        kitchen_warehouse,
        quantitations,
        food,
        status,
        quantity,
        cooking_date,
        ...data
      } = this.dataSubmit;
      if (
        kitchen_warehouse &&
        material_warehouse &&
        food &&
        quantity &&
        cooking_date
      ) {
        if (!this.dataSubmit.id) {
          const dataSubmit = {
            material_warehouse: material_warehouse?.id || null,
            kitchen_warehouse: kitchen_warehouse?.id || null,
            food: food?.id || null,
            status,
            quantity: Number(quantity),
            cooking_date,
            ...data,
          };
          this.$emit("onCreate", dataSubmit);
        } else {
          const dataUpdate = {
            material_warehouse: material_warehouse?.id || null,
            kitchen_warehouse: kitchen_warehouse?.id || null,
            food: food?.id || null,
            status: 1,
            quantity: Number(quantity),
            cooking_date,
            ...data,
          };
          this.$emit("onUpdate", dataUpdate);
        }
        this.$bvModal.hide("modal-import-warehouse");
      } else {
        this.setMessage({
          type: "error",
          content: "Bạn phải điền đủ các trường cần thiết !",
          isShow: true,
        });
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    searchingSelect({ name }) {
      return name;
    },
  },
  computed: {
    ...mapState("product", ["isListProduct"]),
    dataTable() {
      return this.dataSubmit?.food
        ? this.dataSubmit.food?.quantitations?.map((item, index) => {
            return {
              sno: index + 1,
              name: item.product?.name || "",
              quantity:
                item.quantity * this.dataSubmit.quantity || item.quantity,
            };
          })
        : [];
    },
  },
  watch: {
    "dataSubmit.food"() {
      this.handleChangeFoodAndQuantity();
    },
    "dataSubmit.quantity"() {
      this.handleChangeFoodAndQuantity();
    },
    foodInfo() {
      if (this.foodInfo.id) {
        this.dataSubmit = { ...this.foodInfo };
        this.handleChangeFoodAndQuantity();
      } else {
        this.dataSubmit = { ...initalDataSubmit };
      }
    },
  },
};
</script>

<style>
.rbt-date-food-modal button {
  padding: 0 !important;
}
.rbt-date-food-modal label {
  padding-left: 10px !important;
  padding-top: 3px !important;
}
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
#example-input-group-1 {
  display: flex;
  align-items: center;
}
.rbt-custom-date {
  max-width: 192px;
  white-space: nowrap;
  /* height: 43px !important; */
}
.rbt-box-input-192 {
  width: 192px;
}
.rbt-custom-input {
  /* max-width: 192px; */
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
/* input {
  height: 100% !important;
} */

/* css switch */
.rbt-switch-price .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.rbt-switch-price .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.rbt-switch-price .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.rbt-switch-price .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.rbt-switch-price input:checked + .slider {
  background-color: #008a00;
}

.rbt-switch-price input:focus + .slider {
  box-shadow: 0 0 1px #008a00;
}

.rbt-switch-price input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.rbt-switch-price .slider.round {
  border-radius: 34px;
}

.rbt-switch-price .slider.round:before {
  border-radius: 50%;
}
</style>
