<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách khách hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5>{{ totalRecord }} kết quả</h5>
              <div>
                <b-form-file v-model="fileExcel" plain></b-form-file>
              </div>
            </div>
            <div v-if="processImportProduct.countProcess > 0" class="mt-3 px-5">
              <div>
                <p>
                  Thành công:
                  <span style="color: #00a8ff">{{
                    processImportProduct.success
                  }}</span>
                </p>
                <p>
                  Lỗi:
                  <span style="color: red">
                    {{ processImportProduct.error }}</span
                  >
                </p>
                <p>
                  Không có số điện thoại:
                  <span style="color: #FFB319">
                    {{ processImportProduct.exist }}
                  </span>
                </p>
              </div>
              <b-progress
                v-if="processImportProduct.countProcess > 0"
                v-model="processImportProduct.countProcess"
                :max="processImportProduct.max"
                show-value
              ></b-progress>
              <!-- class="mb-3" -->
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="col-md-3">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-select
                  v-model="ordering"
                  @change="onSearch()"
                  class="col-md-2"
                >
                  <option value="">Sắp xếp theo</option>
                  <option value="most_cash">Tài khoản có</option>
                  <option value="most_debt">Tài khoản nợ</option>
                </b-form-select>
                <b-form-group class="col-md-3">
                  <b-form-select v-model="filter" @change="onSearch()">
                    <option value="">Lọc theo</option>
                    <option value="auto_discount">Chiết khấu tự động</option>
                    <option value="default_discount">Chiết khấu riêng</option>
                  </b-form-select>
                </b-form-group>
                <div class="col-md-4">
                  <b-button class="btn-popup" variant="primary" @click="onSearch()"
                    >Tìm</b-button
                  >
                  <router-link to="/customer/create-customer">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xoá <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && customerList.length > 0"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="customerList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="item.name">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(address)="{ item }">
                    <span>
                      {{ item.address }}
                      <span v-if="item.district">
                        - {{ item.district.name }}</span
                      >
                      <span v-if="item.city"> - {{ item.city.name }}</span>
                    </span>
                  </template>
                  <template v-slot:cell(phone_number)="{ item }">
                    <span v-if="item.phone_number">
                      {{ item.phone_number }}
                    </span>
                  </template>
                  <template v-slot:cell(payment_term)="{ item }">
                    <span v-if="item.payment_term">
                      {{ item.payment_term }} Ngày
                    </span>
                  </template>
                  <template v-slot:cell(surplus)="{ item }">
                    <span v-if="item.user_balances">
                      {{
                        (item.user_balances[1].balance -
                          Math.abs(item.user_balances[0].balance))
                          | formatCurrency
                      }}
                    </span>
                  </template>
                  <template v-slot:cell(user_balances)="{ item }">
                    <span v-if="item.user_balances">
                      <b>Nợ</b>
                      {{
                        Math.abs(item.user_balances[0].balance) | formatCurrency
                      }} </span
                    ><br />
                    <span v-if="item.user_balances">
                      <b>Có</b>
                      {{ item.user_balances[1].balance | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(last_transaction)="{ item }">
                    {{ item.last_transaction | showDate }}
                  </template>
                  <template v-slot:cell(debt_amount)="{ item }">
                    {{ item.debt_amount | formatCurrency }}
                  </template>
                  <template v-slot:cell(account_number)="{ item }">
                    <span>
                      {{ item.account_number }}
                    </span>
                  </template>
                  <template v-slot:cell(discount)="{ item }">
                    <span v-if="item && item.discount && item.discount.auto"
                      >{{ item.discount.discount_auto }}% (Tự động)</span
                    >
                    <span v-if="item && item.discount && !item.discount.auto"
                      >{{ item.discount.discount }}% (Riêng)</span
                    >
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && customerList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import XLSX from "xlsx";

const keyExcelToJson = {
  "Tên khách hàng": "category",
  "Điện thoại": "phone_number",
  "Địa chỉ": "address",
  Email: "email",
  "Ngày tạo": "date_joined",
};

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã khách hàng" },
        { key: "name", label: "Tên khách hàng" },
        { key: "address", label: "Địa chỉ" },
        { key: "phone_number", label: "Số điện thoại" },
        { key: "user_balances", label: "Tài khoản" },
        { key: "surplus", label: "Số dư" },
        { key: "payment_term", label: "Kì hạn thanh toán" },
        { key: "debt_amount", label: "Nợ tối đa" },
        { key: "last_transaction", label: "Giao dịch cuối" },
        { key: "account_number", label: "Số tài khoản" },
        { key: "discount", label: "Chiết khấu" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      filter: "",
      ordering: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      fileExcel: null,
      excelData: {
        header: null,
        results: null,
      },
      listUserImport: null,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getCustomerList(params);
  },
  watch: {
    listUserImport() {
      if (this.listUserImport?.length > 0) {
        this.importListCustomer(this.listUserImport);
      }
    },
    fileExcel() {
      this.handleClick();
    },
  },
  mounted() {},
  computed: {
    ...mapState("customer", [
      "customerList",
      "totalRecord",
      "isLoading",
      "processImportProduct",
    ]),
  },
  methods: {
    ...mapActions("customer", [
      "getCustomerList",
      "deleteCustomer",
      "importListCustomer",
    ]),
    replaceKeyExcelToData(tab) {
      // const tab = { abc: 1, def: 40, xyz: 50 };
      // const replacements = { abc: "a_b_c", def: "d_e_f" };
      let replacedItems = Object.keys(tab).map((key) => {
        const newKey = keyExcelToJson[key] || key;
        return { [newKey]: tab[key] };
      });
      const newTab = replacedItems.reduce((a, b) => Object.assign({}, a, b));
      return newTab;
    },
    generateData({ header, results }) {
      this.excelData.header = header;
      const convertResult = results.map((item) => {
        const date = new Date(
          Math.round((item["Ngày tạo"] - (25567 + 2)) * 86400 * 1000)
        );
        const converted_date = date.toISOString().split("T")[0];
        return {
          name: item["Tên khách hàng"],
          phone_number: item["Điện thoại"],
          address: item["Địa chỉ"],
          date_joined: converted_date,
          email: item["Email"] || `${item["Điện thoại"]}@gmail.com`,
          username: item["Điện thoại"],
          password: `abc${item["Điện thoại"]}`,
        };
      });
      this.excelData.results = convertResult;
      const newResults = convertResult.map((item) => {
        return this.replaceKeyExcelToData(item);
      });
      this.listUserImport = newResults;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    readerData(rawFile) {
      // this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          // this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    beforeUpload(file) {
      var extension = file.name.split(".").pop();
      if (["xls", "xlsx"].indexOf(extension) < 0) {
        alert("Đây không phải file excel");
        return false;
      }
      return true;
    },
    upload(rawFile) {
      if (!this.beforeUpload(rawFile)) {
        // this.readerData(rawFile);
        this.fileExcel = null;
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    handleClick() {
      let files = this.fileExcel;
      const rawFile = files;
      if (!rawFile) return;
      this.upload(rawFile);
    },
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.deleteCustomer({ id: id, params });
      this.search = "";
      this.ordering = "";
      this.filter = "";
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onEdit(item) {
      this.$router.push({
        name: "create-customer",
        query: { id: item.id },
      });
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.filter) {
        params = {
          filter: this.filter,
          ...params,
        };
      }
      this.getCustomerList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/customer/customer-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.filter) {
        params = {
          filter: this.filter,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      this.getCustomerList(params);
      this.$router.push({
        path: "/customer/customer-list",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>
