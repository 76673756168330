export const apiRoutesEnum = {
  signIn: "/sign-in",
  signUp: "/sign-up",
  refreshToken: "/token/refresh",
  updateCategory: "update-category/",
  userProfile: "/user-profile",
  changeProfile: "/change-profile",
  changePassword: "/change-password",
  updateStaff: "/update-staff/",
  brand: "/brands",
  searchBranch: "/brand/search",
  categories: "/categories",
  warehouse: "/warehouses",
  checkWarehouse: "/check-warehouses",
  warehousesReceipt: "/warehouses-receipt",
  city: "/cities",
  article: "/articles",
  customer: "/customers",
  user: "/users",
  overviewReport: "/report/overview",
  revenueReport: "/report/revenue",
  employee: "/employees",
  inventory: "/inventories",
  newsCategory: "/news-categories",
  order: "/orders",
  dailyReport: "/report/daily",
  productReport: "/report/product",
  saleReport: "/report/sale/time",
  customerReport: "/report/customer",
  supplierReport: "/report/supplier",
  orderProfitReport: "/report/order-profit",
  statusOrder: "/status-order",
  statusPayment: "/status-payment",
  role: "/roles",
  supplier: "/suppliers",
  adminChangePassword: "/admin/change-password",
  transactionReceipts: "/transaction-receipts",
  shipper: "/shippers",
  calculationUnit: "/calculation-units",
  discountSetting: "/discount-settings",
  personDiscount: "/person-discount",
  food: "/foods",
  orderType: "/order-type",
  orderSupplier: "/order-suppliers",
  orderReturnSupplier: "/order-return-suppliers",
  refundCustomer: "/change-return-goods-receipt",
  quotation: "/quotations",
  productPriceList: "/product-price-list",
  shipperOrder: "/shipper/orders",
  notify: "/notifications",
  maskAsRead: "/unread-notifications/mark-as-read",
  deviceGCM: "/device/gcm",
  deviceAPNS: "/device/apns",
  orderAdmin: "/admin/orders",
  homepageImage: "/homepage-images",
  shipperStatisticOrder: "/shipper/statisticals-orders",
  sendNotifyPromotion: "/send-notify-promotion",
  sendNotifyFood: "/send-notify-foods",
  paymentNotShip: "/payment-order-online-not-ship",
};
