<template>
  <div>
    <!-- Container-fluid starts-->
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs main="" title="Dashboard" />
      </div>
      <Widget></Widget>
      <div class="row">
        <!-- <MarketChart></MarketChart> -->
        <!-- <OrderTable :order="orderList"></OrderTable> -->
      </div>
      <!-- <SecondWidget></SecondWidget> -->
      <div class="row">
        <!-- <BuySellChart></BuySellChart> -->
        <!-- <GoodsReturnTable :data="orderList"></GoodsReturnTable> -->
        <SalesStatus></SalesStatus>
        <OrderTable></OrderTable>
        <EmployeeStatus :employee="employee"></EmployeeStatus>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Widget from "../components/dashboard/widget.vue";
import MarketChart from "../components/dashboard/market-chart.vue";
import SecondWidget from "../components/dashboard/secondWidget/secondWidget.vue";
import OrderTable from "../components/dashboard/order-table.vue";
import BuySellChart from "../components/dashboard/buy-sell-chart.vue";
import GoodsReturnTable from "../components/dashboard/goods-returns-table.vue";
import EmployeeStatus from "../components/dashboard/employee-status-table.vue";
import SalesStatus from "../components/dashboard/sales-status/sales-status.vue";
import LocalStorageService from "../utils/index";
import { Roles } from "../types/roles"
export default {
  components: {
    Widget,
    MarketChart,
    SecondWidget,
    OrderTable,
    BuySellChart,
    GoodsReturnTable,
    EmployeeStatus,
    SalesStatus,
  },

  data() {
    return {};
  },
  created() {
    // this.$store.dispatch("order/getOrderList");
    const params = { order: "is_online" };
    this.getEmployeeList(params);
  },
  computed: {
    ...mapGetters({
      employee: "employee/getEmployeeList",
    }),
  },
  methods: {
    ...mapActions("employee", ["getEmployeeList"]),
  },
  mounted() {
    const role = LocalStorageService.getLocalUserRole();
    if (role === Roles.shipper) {
      this.$router.push("/shipper-order");
      return
    }
  },
};
</script>
