var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"form-horizontal auth-form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onRegister()}}},[_c('validation-provider',{attrs:{"name":"Tên đăng nhập","rules":{ required: true, regexUserName: /^[a-z0-9@._-]{3,50}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"example-input-1","name":"example-input-1","placeholder":"Tên đăng nhập","state":_vm.getValidationState(validationContext),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.dataSubmit.username),callback:function ($$v) {_vm.$set(_vm.dataSubmit, "username", $$v)},expression:"dataSubmit.username"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"example-input-1","name":"example-input-1","placeholder":"Email","state":_vm.getValidationState(validationContext),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.dataSubmit.email),callback:function ($$v) {_vm.$set(_vm.dataSubmit, "email", $$v)},expression:"dataSubmit.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min: 8, regex: /[A-Z]/i },"name":"Mật khẩu mới","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('b-form-input',{attrs:{"type":"password","state":errors[0] ? false : valid ? true : null,"validateOnInput":"","placeholder":"********"},model:{value:(_vm.dataSubmit.password),callback:function ($$v) {_vm.$set(_vm.dataSubmit, "password", $$v)},expression:"dataSubmit.password"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","name":"Mật khẩu nhập lại"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{attrs:{"label-for":"example-input-3"}},[_c('b-form-input',{attrs:{"type":"password","placeholder":"********","state":errors[0] ? false : valid ? true : null},model:{value:(_vm.dataSubmit.rePassword),callback:function ($$v) {_vm.$set(_vm.dataSubmit, "rePassword", $$v)},expression:"dataSubmit.rePassword"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)}),_c('div',{staticClass:"form-button"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" Đăng kí ")])])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }