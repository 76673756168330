import reportService from "../../services/report";

const state = {
  // state daily report
  dailyReportList: [],
  numOrdersDaily: 0,
  totalInvoiceDaily: 0,
  realityReceived: 0,
  remainDaily: 0,
  additionalFee: 0,
  totalDailyRecord: 0,

  // state product report
  productReportList: [],
  totalProductRecord: 0,
  totalRevenueProduct: 0,
  numOrdersProduct: 0,
  numProducts: 0,
  numReturnProduct: 0,
  totalReturnProduct: 0,
  totalNetProduct: 0,

  // state sale report
  saleReportList: [],
  totalSaleRecord: 0,
  totalValueSale: 0,
  numOrdersSale: 0,
  numOrdersReturnSale: 0,
  totalRefundSale: 0,
  totalNetSale: 0,

  numProductsSale: 0,

  // state customer report
  customerReportList: [],
  totalCustomerRecord: 0,
  totalOrderCustomer: 0,
  numOrdersCustomer: 0,
  quantityCustomer: 0,
  numOrdersReturnCustomer: 0,
  totalReturnOrderCustomer: 0,
  totalNetOrderCustomer: 0,

  // state supplier report
  supplierReportList: [],
  totalSupplierRecord: 0,
  totalOrderSupplier: 0,
  totalValueSupplier: 0,
  totalReturnOrdersSupplier: 0,
  totalValueReturnsSupplier: 0,
  totalValueNetSupplier: 0,

  // order profit report
  orderProfitReportList: [],
  totalProfitRecord: 0,

  // state status order
  statusOrderList: [],
  statusPayment: [],

  isLoading: false,
};
const getters = {};
const actions = {
  async getDailyReportList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await reportService.getListDailyReport(params);
      const { results, count } = response.data.data;
      const {
        num_orders,
        total_invoice,
        additional_fee,
        reality_received,
        total_refund,
      } = response.data;
      commit("SET_LIST_DAILY_REPORT", results);
      commit("SET_TOTAL_RECORD_DAILY", count);
      commit("SET_ORDER_DAILY", num_orders);
      commit("SET_TOTAL_DAILY", total_invoice);
      commit("SET_REALITY_RECEIVED", reality_received);
      commit("SET_REMAIN_DAILY", total_refund);
      commit("SET_ADDITIONAL_FEE_DAILY", additional_fee);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getProductReportList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await reportService.getListProductReport(params);
      const { results, count } = response.data.data;
      const {
        num_product,
        num_order,
        revenue,
        num_return,
        return_value,
        net_total,
      } = response.data;
      commit("SET_LIST_PRODUCT_REPORT", results);
      commit("SET_TOTAL_RECORD_PRODUCT", count);
      commit("SET_NUM_PRODUCT", num_product);
      commit("SET_NUM_ORDER_PRODUCT", num_order);
      commit("SET_TOTAL_REVENUE_PRODUCT", revenue);
      commit("SET_NUM_RETURN_PRODUCT", num_return);
      commit("SET_TOTAL_RETURN_PRODUCT", return_value);
      commit("SET_NET_TOTAL_PRODUCT", net_total);

      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getSaleReportList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await reportService.getListSaleReport(params);
      const { results, count } = response.data.data;
      const {
        total_value,
        order,
        order_return,
        total_refund,
        net_total,
      } = response.data;
      commit("SET_LIST_SALE_REPORT", results);
      commit("SET_TOTAL_RECORD_SALE", count);
      commit("SET_NUM_ORDER_SALE", order);
      commit("SET_TOTAL_VALUE_SALE", total_value);
      commit("SET_TOTAL_REFUND_SALE", total_refund);
      commit("SET_NUM_ORDER_RETURN_SALE", order_return);
      commit("SET_TOTAL_NET_SALE", net_total);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getCustomerReportList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await reportService.getListCustomerReport(params);
      const { results, count } = response.data.data;
      const {
        quantity_customer,
        num_order,
        total_order,
        num_return,
        total_return,
        net_total,
      } = response.data;
      commit("SET_LIST_CUSTOMER_REPORT", results);
      commit("SET_TOTAL_RECORD_CUSTOMER", count);
      commit("SET_NUM_ORDER_CUSTOMER", num_order);
      commit("SET_TOTAL_ORDER_CUSTOMER", total_order);
      commit("SET_QUANTITY_CUSTOMER", quantity_customer);
      commit("SET_NUM_ORDER_RETURN_CUSTOMER", num_return);
      commit("SET_TOTAL_RETURN_ORDER_CUSTOMER", total_return);
      commit("SET_TOTAL_NET_ORDER_CUSTOMER", net_total);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getSupplierReportList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await reportService.getListSupplierReport(params);
      const { results, count } = response.data.data;
      const {
        total_order,
        total_value,
        total_return_order,
        total_value_return,
        net_total,
      } = response.data;
      commit("SET_LIST_SUPPLIER_REPORT", results);
      commit("SET_TOTAL_RECORD_SUPPLIER", count);
      commit("SET_TOTAL_ORDER_SUPPLIER", total_order);
      commit("SET_TOTAL_VALUE_SUPPLIER", total_value);
      commit("SET_TOTAL_RETURN_ORDER_SUPPLIER", total_return_order);
      commit("SET_TOTAL_VALUE_RETURN_SUPPLIER", total_value_return);
      commit("SET_TOTAL_NET_VALUE__SUPPLIER", net_total);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getOrderProfitReportList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await reportService.getOrderProfitReport(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_ORDER_PROFIT_REPORT", results);
      commit("SET_TOTAL_RECORD_ORDER_PROFIT", count);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getOrderStatusList({ commit }) {
    try {
      const response = await reportService.getListStatusOrder();
      commit("SET_LIST_ORDER_STATUS", response.data);
      return response;
    } catch (error) {
      console.log(error);
    }
  },

  async getPaymentStatusList({ commit }) {
    try {
      const response = await reportService.getListStatusPayment();
      commit("SET_LIST_PAYMENT_STATUS", response.data);
    } catch (error) {
      console.log(error);
    }
  },
};
const mutations = {
  // mutation daily report
  SET_LIST_DAILY_REPORT(state, payload) {
    state.dailyReportList = payload;
  },
  SET_ORDER_DAILY(state, payload) {
    state.numOrdersDaily = payload;
  },
  SET_TOTAL_DAILY(state, payload) {
    state.totalInvoiceDaily = payload;
  },
  SET_REALITY_RECEIVED(state, payload) {
    state.realityReceived = payload;
  },
  SET_REMAIN_DAILY(state, payload) {
    state.remainDaily = payload;
  },
  SET_TOTAL_RECORD_DAILY(state, payload) {
    state.totalDailyRecord = payload;
  },
  SET_ADDITIONAL_FEE_DAILY(state, payload) {
    state.additionalFee = payload;
  },

  // mutation product report
  SET_LIST_PRODUCT_REPORT(state, payload) {
    state.productReportList = payload;
  },
  SET_TOTAL_REVENUE_PRODUCT(state, payload) {
    state.totalRevenueProduct = payload;
  },
  SET_NUM_ORDER_PRODUCT(state, payload) {
    state.numOrdersProduct = payload;
  },
  SET_NUM_PRODUCT(state, payload) {
    state.numProducts = payload;
  },
  SET_TOTAL_RECORD_PRODUCT(state, payload) {
    state.totalProductRecord = payload;
  },
  SET_NUM_RETURN_PRODUCT(state, payload) {
    state.numReturnProduct = payload;
  },
  SET_TOTAL_RETURN_PRODUCT(state, payload) {
    state.totalReturnProduct = payload;
  },
  SET_NET_TOTAL_PRODUCT(state, payload) {
    state.totalNetProduct = payload;
  },

  // mutation sale report
  SET_LIST_SALE_REPORT(state, payload) {
    state.saleReportList = payload;
  },
  SET_TOTAL_REFUND_SALE(state, payload) {
    state.totalRefundSale = payload;
  },
  SET_NUM_ORDER_SALE(state, payload) {
    state.numOrdersSale = payload;
  },
  SET_TOTAL_VALUE_SALE(state, payload) {
    state.totalValueSale = payload;
  },
  SET_TOTAL_RECORD_SALE(state, payload) {
    state.totalSaleRecord = payload;
  },
  SET_NUM_ORDER_RETURN_SALE(state, payload) {
    state.numOrdersReturnSale = payload;
  },
  SET_TOTAL_NET_SALE(state, payload) {
    state.totalNetSale = payload;
  },

  // mutation customer report
  SET_LIST_CUSTOMER_REPORT(state, payload) {
    state.customerReportList = payload;
  },
  SET_TOTAL_ORDER_CUSTOMER(state, payload) {
    state.totalOrderCustomer = payload;
  },
  SET_NUM_ORDER_CUSTOMER(state, payload) {
    state.numOrdersCustomer = payload;
  },
  SET_TOTAL_RECORD_CUSTOMER(state, payload) {
    state.totalCustomerRecord = payload;
  },
  SET_QUANTITY_CUSTOMER(state, payload) {
    state.quantityCustomer = payload;
  },

  SET_NUM_ORDER_RETURN_CUSTOMER(state, payload) {
    state.numOrdersReturnCustomer = payload;
  },

  SET_TOTAL_RETURN_ORDER_CUSTOMER(state, payload) {
    state.totalReturnOrderCustomer = payload;
  },
  SET_TOTAL_NET_ORDER_CUSTOMER(state, payload) {
    state.totalNetOrderCustomer = payload;
  },

  // mutation supplier report
  SET_LIST_SUPPLIER_REPORT(state, payload) {
    state.supplierReportList = payload;
  },
  SET_TOTAL_RECORD_SUPPLIER(state, payload) {
    state.totalSupplierRecord = payload;
  },
  SET_TOTAL_ORDER_SUPPLIER(state, payload) {
    state.totalOrderSupplier = payload;
  },
  SET_TOTAL_VALUE_SUPPLIER(state, payload) {
    state.totalValueSupplier = payload;
  },
  SET_TOTAL_RETURN_ORDER_SUPPLIER(state, payload) {
    state.totalReturnOrdersSupplier = payload;
  },
  SET_TOTAL_VALUE_RETURN_SUPPLIER(state, payload) {
    state.totalValueReturnsSupplier = payload;
  },
  SET_TOTAL_NET_VALUE__SUPPLIER(state, payload) {
    state.totalValueNetSupplier = payload;
  },

  // mutation order profit
  SET_LIST_ORDER_PROFIT_REPORT(state, payload) {
    state.orderProfitReportList = payload;
  },
  SET_TOTAL_RECORD_ORDER_PROFIT(state, payload) {
    state.totalProfitRecord = payload;
  },

  SET_LIST_ORDER_STATUS(state, payload) {
    state.statusOrderList = payload;
  },

  SET_LIST_PAYMENT_STATUS(state, payload) {
    state.statusPayment = payload;
  },

  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
