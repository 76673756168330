<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh mục bài viết" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-6">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <div class="col-md-6">
                  <b-button class="btn-popup" variant="primary" @click="onSearch()"
                    >Tìm</b-button
                  >
                  <router-link to="/news-category/create-news-category">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && newsCategoryList.length > 0"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="newsCategoryList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="item.name">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(description)="{ item }">
                    <span>
                      {{ item.description }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && newsCategoryList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "name", label: "Tên danh mục bài viết" },
        { key: "description", label: "Mô tả" },
        { key: "actions", label: "Hành động" },
      ],
      filter: null,
      isId: "",
      isName: "",
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getNewsCategoryList(params);
  },
  computed: {
    ...mapState("newsCategory", [
      "newsCategoryList",
      "totalRecord",
      "isLoading",
    ]),
  },
  watch: {},
  methods: {
    ...mapActions("newsCategory", [
      "getNewsCategoryList",
      "deleteNewsCategory",
    ]),
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.deleteNewsCategory({ id: id, params });
      this.search = "";
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onEdit(item) {
      this.$router.push({
        name: "create-news-category",
        query: { id: item.id },
      });
    },
    onSearch() {
      const params = {
        search: this.search.trim(),
        page: 1,
        page_size: this.pageSize,
      };
      this.getNewsCategoryList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/news-category/news-category-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      this.getNewsCategoryList(params);
      this.$router.push({
        path: "/news-category/news-category-list",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>
