<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Thiết lập chiết khấu" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật chiết khấu
              </h5>
              <h5 v-else>
                Thêm chiết khấu
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="discountInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <ValidationProvider
                        name="Mức tiền"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label> <span>*</span>Từ</template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="discountInfo.start_amount"
                            placeholder="Nhập khoảng tiền bắt đầu"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </div>

                    <div class="col-sm-6 col-md-12">
                      <ValidationProvider
                        name="Khoảng tiền bắt đầu"
                        rules="required|greater:@Mức tiền"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label> <span>*</span>Đến</template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="discountInfo.end_amount"
                            placeholder="Nhập khoảng tiền kết thúc"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </div>

                    <div class="col-sm-6 col-md-12">
                      <validation-provider
                        name="Chiết khấu"
                        :rules="{
                          required: true,
                          percentGreaterZero: true,
                          regexDiscount: /^[0-9]+$/,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Chiết khấu</template
                          >
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="discountInfo.discount_percent"
                            type="number"
                            placeholder="Nhập chiết khấu"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      class="btn btn-danger mr-3"
                      type="button"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Loader from "../../components/loading/loader.vue";
import { extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import { addCommaPrice } from "../../config/common";

extend("amountGreaterZero", (value) => {
  if (value >= 0) return true;
  return "Mức tiền phải là số dương";
});

extend("percentGreaterZero", (value) => {
  if (value >= 0 && value <= 100) return true;
  return "Chiết khấu phải dương và không vượt quá 100";
});
extend("regexDiscount", {
  ...regex,
  message: "Chiết khấu chứa kí tự không hợp lệ",
});

extend("greater", {
  params: ["target"],
  validate(value, { target }) {
    if (typeof value == "string") {
      return Number(value.replace(/,/g, "")) > Number(target.replace(/,/g, ""));
    }
  },
  message: "Số tiền sau phải lớn hơn số tiền trước",
});

const initialData = {
  start_amount: "",
  end_amount: "",
  discount_percent: "",
};

export default {
  data() {
    const discountInfo = this.$route.query.id ? this.discountInfo : initialData;
    return {
      discountInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.discountInfo = await this.getDiscountDetail(this.$route.query.id);
      this.discountInfo.start_amount = addCommaPrice(
        this.discountInfo.start_amount
      );
      this.discountInfo.end_amount = addCommaPrice(
        this.discountInfo.end_amount
      );
    } else {
      this.discountInfo = {
        ...initialData,
      };
    }
  },
  computed: {
    ...mapState("discount", ["isLoading"]),
  },
  watch: {
    "discountInfo.start_amount": {
      handler: function(value) {
        if (typeof value == "string") {
          const result = addCommaPrice(value);
          this.$nextTick(() => (this.discountInfo.start_amount = result));
        }
      },
      deep: true,
    },
    "discountInfo.end_amount": {
      handler: function(value) {
        if (typeof value == "string") {
          const result = addCommaPrice(value);
          this.$nextTick(() => (this.discountInfo.end_amount = result));
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("discount", [
      "createDiscount",
      "updateDiscount",
      "getDiscountDetail",
    ]),
    onSubmit() {
      const { start_amount, end_amount, ...data } = this.discountInfo;
      const dataSubmit = {
        start_amount: Number(start_amount.replace(/,/g, "")),
        end_amount: Number(end_amount.replace(/,/g, "")),
        ...data,
      };
      if (this.discountInfo.id) {
        const unitData = { id: this.discountInfo.id, data: dataSubmit };
        this.updateDiscount(unitData);
      } else {
        this.createDiscount(dataSubmit);
      }
    },
    cancelUpdate() {
      this.discountInfo = {
        ...initialData,
      };
    },
    handleBackList() {
      this.$router.push({ name: "discount-list" });
    },
    getValidationState({ validated, valid = null }) {
      valid = valid ? null : valid;
      return validated ? valid : null;
    },
  },
};
</script>
<style scoped></style>
