import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class DiscountServices {
  async getAll() {
    return axiosInstance.get(apiRoutesEnum.discountSetting);
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.discountSetting}/${id}`);
  }

  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.discountSetting, payload);
  }

  async update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.discountSetting}/${id}`, data);
  }

  async delete(id) {
    return axiosInstance.delete(`${apiRoutesEnum.discountSetting}/${id}`);
  }
}

export default new DiscountServices();
