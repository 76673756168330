import router from "../../router/index.js";
import authServices from "../../services/auth";
import profileServices from "../../services/profile";
import LocalStorageService from "../../utils/index";
import { Roles } from "../../types/roles";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export const authentication = {
  namespaced: true,
  state: {
    initialState,
    errorList: {},
  },
  getters: {},
  actions: {
    async signIn({ commit }, data) {
      try {
        const response = await authServices.signIn({ data });
        const { access, refresh } = response.data;
        LocalStorageService.saveLocalAccessToken(access);
        LocalStorageService.saveLocalRefreshToken(refresh);
        const userProfile = await profileServices.getProfile();
        const userRoleName = userProfile?.data.groups[0]?.name;
        LocalStorageService.saveLocalUserRole(userRoleName);
        switch (userRoleName) {
          default:
            router.push("/dashboard/default");
            break;
          case Roles.employee:
            router.push("/sales/order");
            break;
          case Roles.shipper:
            router.push("/shipper-order");
            break;
          case Roles.accountant:
            router.push("/settings/transaction-receipt");
            break;
        }
      } catch (error) {
        commit("SET_ERROR_LIST", error.response.data);
      }
    },

    async signUp({ commit, dispatch }, data) {
      try {
        const response = await authServices.create(data);
        await commit(
          "SET_MESSAGE",
          {
            type: "success",
            content: "Đăng kí thành công",
            isShow: true,
          },
          { root: true }
        );
        const dataLogin = {
          username: data.username,
          password: data.password,
        };
        dispatch("signIn", dataLogin);
      } catch (error) {
        commit("SET_ERROR_LIST", error.response, { root: true });
      }
    },

    logout({ commit, dispatch }) {
      LocalStorageService.removeLocalAccessToken();
      LocalStorageService.removeLocalRefreshToken();
      commit("profile/SET_USER_PROFILE", null, { root: true });
      commit("role/SET_ROLE_DETAIL", {}, { root: true });
      commit("logout");
    },
  },
  mutations: {
    loginRequest(state, user) {
      state.status = { loggingIn: true };
      state.user = user;
    },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    loginFailure(state) {
      state.status = {};
      state.user = null;
    },
    logout(state) {
      state.status = {};
      state.user = null;
    },
    SET_ERROR_LIST(state, payload) {
      state.errorList = payload;
    },
  },
};
