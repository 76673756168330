<template>
  <b-modal
    :no-close-on-backdrop="true"
    id="modal-print-order-supplier"
    size="xl"
  >
    <template #modal-header>
      <div class="mx-auto">
        <h3 class="mb-0" style="color: #ff8084">
          Ruộng bậc thang
        </h3>
      </div>
    </template>
    <div v-if="orderInfo" id="rbtContentPrint">
      <div class="mx-auto">
        <div class="text-center mx-auto">
          <p>
            D/C: Số 82 - 84 Ngọc Hân Công Chúa - Tp. Bắc Ninh
          </p>
          <p>
            Điện thoại: 0862968081 - 0988861479
          </p>
        </div>
        <hr />
        <div class="text-center">
          <h3 style="color: #ff8084">
            ĐƠN HÀNG NHÀ CUNG CẤP
          </h3>
          <h5>
            {{ orderInfo.code }}
          </h5>
          <p>
            Ngày dự kiến nhận hàng: {{ orderInfo.recieve_time | dateFormat }}
          </p>
        </div>
        <div class="row">
          <div
            class="col-12 col-md-6 d-flex align-items-center"
            v-if="orderInfo.supplier"
          >
            <b>Nhà cung cấp: </b> {{ orderInfo.supplier.name }}
          </div>
          <div
            class="col-12 col-md-6 d-flex align-items-center justify-content-end"
            v-if="orderInfo.created_by"
          >
            <b>
              Người tạo:
            </b>
            {{ orderInfo.created_by.name }}
          </div>
        </div>
        <div>
          <div class="datatable-vue text-center mt-4">
            <b-table
              show-empty
              striped
              hover
              head-variant="light"
              bordered
              :fields="tablefields"
              :items="itemsTable"
            >
              <template #empty>
                <span class="text-danger">
                  Chưa có sản phẩm nào
                </span>
              </template>

              <template v-slot:cell(actions)="{ item }">
                <span
                  style="font-size: 20px; color: #FA399F;"
                  @click="removeItemInTable(item.sno)"
                >
                  <feather
                    style="cursor: pointer"
                    type="trash"
                    stroke="#F72E9F"
                    size="18px"
                    fill="#F72E9F"
                  ></feather>
                </span>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{cancel}">
      <div style="width: 100%" class="d-flex justify-content-end">
        <div>
          <b-button @click="cancel()" class="mr-3" size="sm" variant="danger">
            Đóng
          </b-button>
          <b-button v-print="`#rbtContentPrint`" class="btn-popup m-0">
            <span style="font-size: 24px; font-weight: 100">
              +
            </span>
            <span>
              In hóa đơn
            </span>
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "WarehouseImport",
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quoted_quantity", label: "Số lượng cần nhập" },
        { key: "count", label: "Thực tế" },
        { key: "note", label: "Ghi chú" },
      ],
    };
  },
  created() {},
  computed: {
    itemsTable() {
      const orders = this.orderInfo.product?.map((item, index) => {
        return {
          sno: index + 1,
          name: item?.product.name,
          price: item.price,
          quantity: item.quantity,
          price: item?.price,
          quantity: item?.quantity,
          price_recommend: item?.price_recommend,
          quoted_quantity: item?.quoted_quantity,
        };
      });
      return orders;
    },
  },
  methods: {
    closeOrder() {
      this.$emit("onCloseOrder");
    },
    onPrintElement() {},
  },
};
</script>
