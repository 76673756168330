<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Chiết khấu khách hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header"></div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-6">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <div class="col-md-6">
                  <b-button class="btn-popup" variant="primary" @click="onSearch()"
                    >Tìm</b-button
                  >
                  <router-link to="/settings/create-person-discount">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="personDiscountList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="personDiscountList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(start_amount)="{ item }">
                    <span>
                      {{ item.start_amount | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(user)="{ item }">
                    <span>
                      {{ item.user.name }}
                    </span>
                  </template>
                  <template v-slot:cell(phone_number)="{ item }">
                    <span>
                      {{ item.user.phone_number }}
                    </span>
                  </template>
                  <template v-slot:cell(discount)="{ item }">
                    <span> {{ item.discount }}% </span>
                  </template>
                  <template v-slot:cell(discount_auto)="{ item }">
                    <span> {{ item.discount_auto }}% </span>
                  </template>
                  <template v-slot:cell(auto)="{ item }">
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeStatus($event, item)"
                      :checked="item.auto"
                    /><span> Sử dụng</span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="onDelete(item.id)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div class="text-center" v-if="personDiscountList.length === 0">
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "user", label: "Khách hàng" },
        { key: "phone_number", label: "Số điện thoại" },
        { key: "discount", label: "Chiết khấu riêng" },
        { key: "discount_auto", label: "Chiết khấu tự động" },
        { key: "auto", label: "Dùng chiết khấu tự động" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getPersonDiscountList(params);
  },
  computed: {
    ...mapState("personDiscount", [
      "personDiscountList",
      "isLoading",
      "totalRecord",
    ]),
  },
  watch: {},
  methods: {
    ...mapActions("personDiscount", [
      "getPersonDiscountList",
      "updatePersonDiscount",
      "deletePersonDiscount",
    ]),
    onDelete(id) {
      this.deletePersonDiscount({ id: id });
      this.search = "";
    },
    onEdit(item) {
      this.$router.push({
        name: "create-person-discount",
        query: { id: item.id },
      });
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = { search: this.search.trim(), ...params };
      }
      this.getPersonDiscountList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/settings/person-discount-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          search: this.search.trim(),
          ...params,
        };
      }
      this.getPersonDiscountList(params);
      this.$router.push({
        path: "/settings/person-discount-list",
        query: { page: this.pageNumber },
      });
    },
    onChangeStatus(event, item) {
      const value = event.target.checked;
      const { auto, user, ...data } = item;
      const dataSubmit = {
        auto: value,
        user: user?.id,
        ...data,
      };
      const personDiscountData = {
        id: item.id,
        data: dataSubmit,
      };
      this.updatePersonDiscount(personDiscountData);
    },
  },
};
</script>
