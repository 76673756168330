<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Quản lí bài viết" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật bài viết
              </h5>
              <h5 v-else>
                Thêm bài viết
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="articleInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <div class="form-group row">
                        <label
                          for="validationCustom0"
                          class="col-xl-3 col-md-4"
                        >
                          Ảnh
                        </label>
                        <div class="col-xl-8 col-md-7 px-0">
                          <div class="text-center">
                            <img
                              v-if="articleInfo.image"
                              :src="articleInfo.image"
                              width="75"
                              height="75"
                              fluid
                              rounded
                            />
                            <img
                              v-else
                              src="../../assets/images/image_default.png"
                              width="75"
                              height="75"
                              fluid
                              rounded
                            />
                            <a
                              style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                              class="text-dark"
                              @click="onChangeAvatar()"
                              ><i data-feather="camera"
                                ><feather type="camera"></feather></i
                            ></a>
                            <input
                              ref="uploader"
                              @change="onFileChanged"
                              class="form-control d-none"
                              id="validationCustom0"
                              type="file"
                              accept="image/*"
                            />
                          </div>
                        </div>
                      </div>
                      <validation-provider
                        name="Tên bài viết"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên bài viết
                          </template>
                          <b-form-textarea
                            id="textarea-rows"
                            rows="3"
                            v-model="articleInfo.name"
                            placeholder="Nhập tên bài viết"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Nôi dung bài viết"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Nội dung bài viết
                          </template>
                          <vue-editor
                            v-model="articleInfo.description"
                          ></vue-editor>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Danh mục bài viết"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Danh mục bài viết
                          </template>
                          <multiselect
                            placeholder="Chọn danh mục bài viết"
                            v-model="articleInfo.category"
                            :show-labels="false"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :options="newsCategoryList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Trạng thái
                          </template>
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="articleInfo.display"
                            name="checkbox-1"
                          >
                            Xuất bản
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Bài viết đầu trang
                          </template>
                          <b-form-checkbox
                            id="checkbox-3"
                            v-model="articleInfo.position_header"
                            name="checkbox-3"
                          >
                            Hiển thị
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Bài viết cuối trang
                          </template>
                          <b-form-checkbox
                            id="checkbox-2"
                            v-model="articleInfo.position_footer"
                            name="checkbox-2"
                          >
                            Hiển thị
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>

                      <div class="d-flex">
                        <div class="col-sm-4 col-lg-3 p-0">Nguyên liệu</div>
                        <div class="col-md-4 p-0">
                          <multiselect
                            class="rbt-custom-input"
                            v-model="ingredient.product"
                            placeholder="Lựa chọn nguyên liệu"
                            :show-labels="false"
                            @search-change="search"
                            :options="productsActive"
                            :internal-search="false"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>
                        </div>
                        <div class="col-md-3 d-flex">
                          <input
                            v-model="ingredient.quantity"
                            placeholder="Số lượng"
                            class="form-control rbt-custom-input"
                            id="validationCustom1"
                            type="number"
                            min="0"
                          />
                          <span
                            class="p-2"
                            v-if="ingredient && ingredient.product"
                            >{{ ingredient.product.unit }}</span
                          >
                        </div>

                        <div class="d-flex align-items-center">
                          <div
                            class="d-flex"
                            style="border-radius: 50%; background-color: #28a745"
                            @click="addProduct()"
                          >
                            <feather
                              class="rbt-icon-plus"
                              style="cursor: pointer"
                              type="plus"
                              stroke="#f5fbf6"
                              size="28px"
                              fill="#f5fbf6"
                            ></feather>
                          </div>
                        </div>
                      </div>
                      <div
                        class="table-responsive datatable-vue text-center mt-4"
                      >
                        <b-table
                          show-empty
                          striped
                          hover
                          head-variant="light"
                          bordered
                          stacked="md"
                          :items="itemsImport"
                          :fields="tablefields"
                        >
                          <template #empty>
                            <span class="text-danger">
                              Chưa có nguyên liệu nào
                            </span>
                          </template>
                          <template v-slot:cell(name)="{ item }">
                            <span> {{ item.product.name }} </span>
                          </template>
                          <template v-slot:cell(unit)="{ item }">
                            <span> {{ item.product.unit }} </span>
                          </template>
                          <template v-slot:cell(actions)="{ item }">
                            <span
                              style="font-size: 20px; color: #FA399F;"
                              @click="removeItemInTable(item.sno)"
                            >
                              <feather
                                style="cursor: pointer"
                                type="trash"
                                stroke="#F72E9F"
                                size="18px"
                                fill="#F72E9F"
                              ></feather>
                            </span>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xoá
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import Loader from "../../components/loading/loader.vue";

const initalDataImport = {
  product: null,
  quantity: null,
  unit: null,
};

const initalDataSubmit = {
  name: "",
  image: null,
  description: "",
  display: true,
  category: "",
  ingredient: [],
  position_header: false,
  position_footer: false,
};

export default {
  data() {
    const articleInfo = this.$route.query.id
      ? this.articleInfo
      : initalDataSubmit;
    return {
      articleInfo,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Nguyên liệu" },
        { key: "quantity", label: "Số lượng" },
        { key: "unit", label: "Đơn vị" },
        { key: "actions", label: "Xóa" },
      ],
      ingredient: initalDataImport,
      checkQuantityIngredient: false,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.articleInfo = await this.getArticleDetail(this.$route.query.id);
    } else {
      this.articleInfo = { ...initalDataSubmit };
      this.articleInfo.ingredient = [];
    }
    this.ingredient = {
      ...initalDataImport,
    };

    await this.getNewsCategoryList();
    if (this.isListProduct?.length <= 0) {
      await this.onListProduct();
    }
  },
  watch: {
    checkQuantityIngredient() {
      if (this.checkQuantityIngredient) {
        this.$toasted.show("Nguyên liệu và số lượng không được để trống !", {
          type: "info",
        });
        this.checkQuantityIngredient = false;
      }
    },
  },
  computed: {
    ...mapState("product", ["isListProduct"]),
    ...mapState("newsCategory", ["newsCategoryList"]),
    ...mapState("article", ["isLoading"]),
    productsActive() {
      const idProductInTable = this.articleInfo?.ingredient?.map((item) => {
        return item.product.id;
      });
      return this.isListProduct?.filter((item) => {
        return !idProductInTable?.includes(item.id);
      });
    },
    itemsImport() {
      return this.articleInfo?.ingredient?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    checkIngredient() {
      if (this.articleInfo?.ingredient?.length > 0) return true;
      else return false;
    },
  },
  methods: {
    ...mapActions("article", [
      "createArticle",
      "updateArticle",
      "getArticleDetail",
    ]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("newsCategory", ["getNewsCategoryList"]),
    onSubmit() {
      const { image, ingredient, category, ...data } = this.articleInfo;
      const productsId = ingredient?.map((item) => {
        const { quantity, unit } = item;
        return {
          product: item.product.id,
          quantity: quantity,
          unit: unit,
        };
      });
      let dataSubmit = {
        ingredient: productsId,
        category: category?.id,
        ...data,
      };
      if (this.$route.query.id) {
        const idArticle = this.articleInfo.id;
        this.updateArticle({ id: idArticle, data: dataSubmit });
      } else {
        if (this.articleInfo.image) {
          dataSubmit = {
            image: this.articleInfo.image,
            ...dataSubmit,
          };
        }
        this.createArticle(dataSubmit);
      }
    },
    cancelUpdate() {
      this.articleInfo = {
        ...initalDataSubmit,
      };
      this.ingredient = {
        ...initalDataImport,
      };
      this.articleInfo.ingredient = [];
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = async () => {
        this.articleInfo.image = await reader.result;
        if (this.$route.query.id) {
          const idArticle = this.articleInfo.id;
          const { image, category, ingredient, ...data } = this.articleInfo;
          const productsId = ingredient?.map((item) => {
            const { quantity, unit } = item;
            return {
              product: item.product.id,
              quantity: quantity,
              unit: unit,
            };
          });
          const articleInfo = {
            image: this.articleInfo.image,
            category: category?.id,
            ingredient: productsId,
            ...data,
          };
          const articleData = { id: idArticle, data: articleInfo };
          this.updateArticle(articleData);
        }
      };
      reader.readAsDataURL(file);
    },
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    handleBackList() {
      this.$router.push({ name: "article-list" });
    },
    getValidationState({ validated, valid = null }) {
      valid = valid ? null : valid;
      return validated ? valid : null;
    },
    addProduct() {
      const { product, quantity } = this.ingredient;
      if (product && quantity > 0) {
        this.articleInfo?.ingredient?.push(this.ingredient);
        this.ingredient = {
          product: "",
          quantity: "",
        };
      } else {
        this.checkQuantityIngredient = true;
      }
    },
    removeItemInTable(index) {
      this.articleInfo?.ingredient.splice(index - 1, 1);
    },
    searchingSelect({ name }) {
      return name;
    },
    search: _.debounce(function(query) {
      this.onListProduct({ name: query });
    }, 250),
  },
};
</script>
<style scoped></style>
