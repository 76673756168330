<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách sản phẩm" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header d-flex justify-content-between">
              <h5>{{ totalRecord }} kết quả</h5>
              <div>
                <b-form-file v-model="fileExcel" plain></b-form-file>
              </div>
            </div>
            <div v-if="processImportProduct.countProcess > 0" class="mt-3 px-5">
              <div>
                <p>
                  Thành công:
                  <span style="color: #00a8ff">{{
                    processImportProduct.success
                  }}</span>
                </p>
                <p>
                  Lỗi:
                  <span style="color: red">
                    {{ processImportProduct.error }}</span
                  >
                </p>
                <p>
                  Đã tồn tại:
                  <span style="color: #FFB319">
                    {{ processImportProduct.exist }}
                  </span>
                </p>
              </div>
              <b-progress
                v-if="processImportProduct.countProcess > 0"
                v-model="processImportProduct.countProcess"
                :max="processImportProduct.max"
                show-value
              ></b-progress>
              <!-- class="mb-3" -->
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-2">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-select
                  v-model="selectCategory"
                  @change="onSearch()"
                  class="col-md-2 mr-2"
                >
                  <option value="">Danh mục</option>
                  <option
                    v-for="category in categoryList"
                    :key="category.index"
                    :value="category.id"
                    >{{ category.name }}</option
                  >
                </b-form-select>
                <b-form-select
                  v-model="supplier"
                  @change="onSearch()"
                  class="col-md-2 mr-2"
                >
                  <option value="">Nhà cung cấp</option>
                  <option
                    v-for="supplier in supplierList"
                    :key="supplier.index"
                    :value="supplier.id"
                    >{{ supplier.name }}</option
                  >
                </b-form-select>
                <b-form-select
                  v-model="activeProduct"
                  @change="onSearch()"
                  class="col-md-1"
                >
                  <option value="">Trạng thái</option>
                  <option value="true">Đang kinh doanh</option>
                  <option value="false">Không kinh doanh</option>
                </b-form-select>
                <b-form-select
                  v-model="ordering"
                  @change="onSearch()"
                  class="col-md-2 ml-2"
                >
                  <option value="">Lọc theo</option>
                  <option value="expiry">Sắp hết hạn</option>
                  <option value="product_inventory_quantity">
                    Sắp hết hàng
                  </option>
                </b-form-select>
                <div class="col-md-1 mr-2">
                  <b-button class="btn-popup" variant="primary" @click="onSearch()"
                    >Tìm</b-button
                  >
                </div>
                <router-link to="/product/product-add">
                  <b-button class="btn-popup">Thêm mới</b-button></router-link
                >
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="productWithSno"
                  :fields="tablefields"
                  :filter="filter"
                >
                  <template #cell(image)="{item}">
                    <img
                      v-if="item.image"
                      height="60px"
                      :src="item.image"
                      width="60px"
                    />
                    <img
                      v-if="!item.image"
                      height="60px"
                      src="../../assets/images/image_default.png"
                      width="60px"
                    />
                  </template>
                  <template v-slot:cell(brand)="{ item }">
                    <span v-if="item.brand">
                      {{ item.brand.name }}
                    </span>
                  </template>
                  <template v-slot:cell(category)="{ item }">
                    <span v-if="item.category">
                      {{ item.category.name }}
                    </span>
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <span
                      v-if="
                        Number(item.product_inventory_quantity) <
                          Number(item.min_inventory)
                      "
                      v-b-tooltip.hover
                      title="Số lượng đã đạt đến mức tồn kho tối thiểu"
                      class="text-warning"
                    >
                      {{ item.name }}
                    </span>
                    <span v-else>{{ item.name }}</span>
                  </template>
                  <template v-slot:cell(expiry)="{ item }">
                    <span>
                      {{ item.expiry | dateFormat }}
                    </span>
                  </template>
                  <template v-slot:cell(price)="{ item }">
                    <span v-if="item.price">
                      {{ item.price | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(active)="{ item }">
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeStatus($event, item)"
                      v-model="item.active"
                    /><span> Kinh doanh</span><br />
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeBestBuy($event, item)"
                      v-model="item.is_best_buy"
                    /><span> Bán chạy</span><br />
                    <input
                      type="checkbox"
                      id="flexCheckChecked2"
                      @change="onChangeNew($event, item)"
                      v-model="item.is_new"
                    /><span> Sản phẩm mới</span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && isListProduct.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import XLSX from "xlsx";

const keyExcelToJson = {
  "Nhóm hàng": "category",
  "Mã hàng": "code",
  "Tên hàng": "name",
  "Thương hiệu": "brand",
  "Giá bán": "price",
  ĐVT: "unit",
  "Tồn lớn nhất": "preservation",
};

export default {
  data() {
    return {
      tablefields: [
        { key: "image", label: "Ảnh" },
        { key: "name", label: "Tên sản phẩm" },
        { key: "price", label: "Giá bán" },
        { key: "product_inventory_quantity", label: "Tồn kho" },
        { key: "unit", label: "Đơn vị bán" },
        { key: "expiry", label: "Hạn sử dụng" },
        { key: "category", label: "Danh mục" },
        { key: "brand", label: "Thương hiệu" },
        { key: "active", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      filter: null,
      isId: "",
      isName: "",
      search: "",
      pageNumber: 1,
      activeProduct: "",
      ordering: "",
      selectCategory: "",
      supplier: "",
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      fileExcel: null,
      excelData: {
        header: null,
        results: null,
      },
      listProductImport: null,
    };
  },
  components: {
    Loader,
  },
  created() {
    this.pageNumber = this.$route.query.page || 1;
    this.selectCategory = this.$route.query.category_id || "";
    this.search = this.$route.query.search || "";
    this.supplier = this.$route.query.supplier_id || "";
    this.active = this.$route.query.active || "";
    this.ordering = this.$route.query.ordering || "";

    let params = {
      page: this.pageNumber,
      page_size: this.pageSize,
    };
    if (this.$route?.query?.category_id) {
      params = {
        category_id: this.selectCategory,
        ...params,
      };
    }
    if (this.$route?.query?.search) {
      params = {
        search: this.search,
        ...params,
      };
    }
    if (this.$route?.query?.supplier_id) {
      params = {
        supplier_id: this.supplier,
        ...params,
      };
    }
    if (this.$route?.query?.active) {
      params = {
        active: this.active,
        ...params,
      };
    }
    if (this.$route?.query?.ordering) {
      params = {
        ordering: this.ordering,
        ...params,
      };
    }
    this.onListProduct(params);
    this.getCategoryList();
    this.getSupplierList();
  },
  computed: {
    ...mapState("category", ["categoryList"]),
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", [
      "isListProduct",
      "totalRecord",
      "isLoading",
      "processImportProduct",
    ]),
    productWithSno() {
      const listProduct =
        this.isListProduct?.map((product, index) => {
          return {
            sno: index + 1,
            ...product,
          };
        }) || [];
      return listProduct;
    },
  },
  watch: {
    listProductImport() {
      if (this.listProductImport?.length > 0) {
        this.importListProduct(this.listProductImport);
      }
    },
    fileExcel() {
      this.handleClick();
    },
  },
  methods: {
    ...mapActions("product", [
      "onListProduct",
      "onUpdateProduct",
      "removeProduct",
      "importListProduct",
    ]),
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions("supplier", ["getSupplierList"]),
    replaceKeyExcelToData(tab) {
      // const tab = { abc: 1, def: 40, xyz: 50 };
      // const replacements = { abc: "a_b_c", def: "d_e_f" };
      let replacedItems = Object.keys(tab).map((key) => {
        const newKey = keyExcelToJson[key] || key;
        return { [newKey]: tab[key] };
      });
      const newTab = replacedItems.reduce((a, b) => Object.assign({}, a, b));
      return newTab;
    },
    generateData({ header, results }) {
      this.excelData.header = header;
      const convertResult = results.map((item) => {
        return {
          category: item["Nhóm hàng(3 Cấp)"],
          code: item["Mã hàng"],
          name: item["Tên hàng"],
          brand: item["Thương hiệu"],
          unit: item["ĐVT"],
          price: item["Giá bán"],
          preservation: item["Tồn lớn nhất"],
          suppliers: item["NCC"] || [],
        };
      });
      this.excelData.results = convertResult;
      const newResults = convertResult.map((item) => {
        return this.replaceKeyExcelToData(item);
      });
      this.listProductImport = newResults;
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    readerData(rawFile) {
      // this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          // this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    beforeUpload(file) {
      var extension = file.name.split(".").pop();
      if (["xls", "xlsx"].indexOf(extension) < 0) {
        alert("Đây không phải file excel");
        return false;
      }
      return true;
    },
    upload(rawFile) {
      if (!this.beforeUpload(rawFile)) {
        // this.readerData(rawFile);
        this.fileExcel = null;
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    handleClick() {
      let files = this.fileExcel;
      const rawFile = files;
      if (!rawFile) return;
      this.upload(rawFile);
    },
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.removeProduct({ id: id, params });
      this.search = "";
      (this.activeProduct = ""),
        (this.ordering = ""),
        (this.selectCategory = "");
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onEdit(item) {
      this.$router.push({
        name: "product-add",
        query: { id: item.id },
      });
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.onListProduct(params);
      this.pageNumber = 1;
      this.$router.push({
        path: "/product/product-list",
        query: {
          page: this.pageNumber,
          search: this.search,
          category_id: this.selectCategory,
          active: this.activeProduct,
          supplier_id: this.supplier,
          ordering: this.ordering,
        },
      });
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.onListProduct(params);
      this.$router.push({
        path: "/product/product-list",
        query: {
          page: this.pageNumber,
          search: this.search,
          category_id: this.selectCategory,
          active: this.activeProduct,
          supplier_id: this.supplier,
          ordering: this.ordering,
        },
      });
    },
    onChangeStatus(event, product) {
      const value = event.target.checked;
      const {
        active,
        image,
        price,
        sale_price,
        category,
        brand,
        suppliers,
        list_images,
        quantitations,
        ...data
      } = product;
      const supplierListId = suppliers?.map((item) => {
        return item.id;
      });

      const productsId = quantitations?.map((item) => {
        const { quantity } = item;
        return {
          product: item.product.id,
          quantity: quantity,
        };
      });
      const dataSubmit = {
        active: value,
        price: parseInt(price),
        sale_price: parseInt(sale_price) || 0,
        category: category?.id,
        brand: brand?.id,
        suppliers: supplierListId,
        quantitations: productsId,
        ...data,
      };
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.onUpdateProduct({ data: dataSubmit, params });
    },
    onChangeBestBuy(event, product) {
      const value = event.target.checked;
      const {
        is_best_buy,
        image,
        price,
        sale_price,
        category,
        brand,
        suppliers,
        list_images,
        quantitations,
        ...data
      } = product;
      const supplierListId = suppliers?.map((item) => {
        return item.id;
      });

      const productsId = quantitations?.map((item) => {
        const { quantity } = item;
        return {
          product: item.product.id,
          quantity: quantity,
        };
      });
      const dataSubmit = {
        is_best_buy: value,
        price: parseInt(price),
        sale_price: parseInt(sale_price) || 0,
        category: category?.id,
        brand: brand?.id,
        suppliers: supplierListId,
        quantitations: productsId,
        ...data,
      };
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.onUpdateProduct({ data: dataSubmit, params });
    },
    onChangeNew(event, product) {
      const value = event.target.checked;
      const {
        is_new,
        image,
        price,
        sale_price,
        category,
        brand,
        suppliers,
        list_images,
        quantitations,
        ...data
      } = product;
      const supplierListId = suppliers?.map((item) => {
        return item.id;
      });

      const productsId = quantitations?.map((item) => {
        const { quantity } = item;
        return {
          product: item.product.id,
          quantity: quantity,
        };
      });
      const dataSubmit = {
        is_new: value,
        price: parseInt(price),
        sale_price: parseInt(sale_price) || 0,
        category: category?.id,
        brand: brand?.id,
        suppliers: supplierListId,
        quantitations: productsId,
        ...data,
      };
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          name: this.search.trim(),
          ...params,
        };
      }
      if (this.activeProduct) {
        params = {
          active: this.activeProduct,
          ...params,
        };
      }
      if (this.ordering) {
        params = {
          ordering: this.ordering,
          ...params,
        };
      }
      if (this.selectCategory) {
        params = {
          category_id: this.selectCategory,
          ...params,
        };
      }
      if (this.supplier) {
        params = {
          supplier_id: this.supplier,
          ...params,
        };
      }
      this.onUpdateProduct({ data: dataSubmit, params });
    },
  },
};
</script>
