import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";
class OrderServices {
  async getAll(params) {
    return axiosInstance.get(apiRoutesEnum.order, { params: params });
  }

  async getAllOrderShipper(params) {
    return axiosInstance.get(apiRoutesEnum.shipperOrder, { params: params });
  }
  async getDetailOrderShipper(id) {
    return axiosInstance.get(`${apiRoutesEnum.shipperOrder}/${id}`);
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.order}/${id}`);
  }

  async updateOrderShipper(payload) {
    const { id, ...data } = payload;
    return axiosInstance.patch(`${apiRoutesEnum.shipperOrder}/${id}`, {
      ...data,
    });
  }

  async update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.order}/${id}`, data);
  }

  async updatePaymentNotShip(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.paymentNotShip}/${id}`, data);
  }

  async updateStatusOrder(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.patch(`${apiRoutesEnum.orderAdmin}/${id}`, data);
  }

  async getAllTransaction(params) {
    return axiosInstance.get(apiRoutesEnum.transactionReceipts, {
      params: params,
    });
  }

  async getShipperStatisticOrder(params) {
    return axiosInstance.get(apiRoutesEnum.shipperStatisticOrder, {
      params: params,
    });
  }

  order(payload) {
    return axiosInstance.post(apiRoutesEnum.order, payload);
  }
}

export default new OrderServices();
