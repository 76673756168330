import Vue from "vue";

const roleInSystem = Vue.filter('roleInSystem', function (value) {
  if (value === 1) {
      return "Quản trị"
  }
  else if (value === 2) {
      return "Quản lý"
  }
  else if (value === 3) {
      return "Nhà cung cấp"
  }
  else if (value === 4) {
      return "Khách hàng"
  }
  else if (value === 5) {
      return "Giao hàng"
  }
  else if (value === 6) {
      return "Nhân viên"
  }
  else if (value === 7) {
      return "Nhà cung cấp"
  }
  else {
      return "Chưa có"
  }
})

const statusActive = Vue.filter('statusActive', function (value) {
    if (value) {
        return "online"
    } else {
        return "offline"
    }
})

const statusPublish = Vue.filter('statusPublish', function (value) {
    if (value) {
        return "Xuất bản"
    } else {
        return "Lưu nháp"
    }
})

export default { statusActive, statusPublish }
