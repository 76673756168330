<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách kho hàng" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật thông tin kho
              </h5>
              <h5 v-else>
                Thêm kho hàng
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="warehouseInfo"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider
                        name="Tên kho hàng"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên kho hàng
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="warehouseInfo.name"
                            placeholder="Nhập tên kho hàng"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Số điện thoại"
                        :rules="{
                          regexPhone: /^[0-9]+$/,
                          min: 10,
                          max: 11,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Số điện thoại"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="warehouseInfo.phone_number"
                            placeholder="Nhập số điện thoại"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Quản lý"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label="Quản lý"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <multiselect
                            placeholder="Chọn quản lý"
                            v-model="warehouseInfo.manager"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :show-labels="false"
                            :options="managerList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mô tả"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Mô tả"
                        >
                          <b-form-textarea
                            id="textarea-rows"
                            rows="8"
                            v-model="warehouseInfo.description"
                            placeholder="Nhập thông tin về kho"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-sm-6">
                      <validation-provider
                        name="Mã kho hàng"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span> Mã kho hàng
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="warehouseInfo.code"
                            placeholder="Nhập mã kho hàng"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Địa chỉ"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Địa chỉ
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="warehouseInfo.address"
                            placeholder="Nhập địa chỉ"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thành phố"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label="Thành phố"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <multiselect
                            placeholder="Chọn thành phố"
                            v-model="warehouseInfo.city"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            @input="onChangeCity"
                            :show-labels="false"
                            :options="cityList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Quận huyện"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label="Quận huyện"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <multiselect
                            placeholder="Chọn quận huyện"
                            v-model="warehouseInfo.district"
                            :show-labels="false"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            :options="districtList"
                            :disabled="!warehouseInfo.city"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Trạng thái"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Trạng thái
                          </template>
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="warehouseInfo.status"
                            name="checkbox-1"
                          >
                            Đang hoạt động
                          </b-form-checkbox>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
  code: "",
  address: "",
  phone_number: "",
  manager: "",
  description: "",
  status: true,
  district: "",
  city: "",
};

export default {
  data() {
    const warehouseInfo = this.$route.query.id
      ? this.warehouseInfo
      : initialData;
    return {
      warehouseInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.warehouseInfo = await this.getWarehouseDetail(this.$route.query.id);
    } else {
      this.warehouseInfo = {
        ...initialData,
      };
    }
    this.getEmployeeList();
    this.getCityList();
    const { city } = this.warehouseInfo;
    const idCity = city?.id;
    if (idCity) {
      this.getDistrictList({ id: idCity });
    }
  },
  computed: {
    ...mapState("warehouse", ["isLoading"]),
    ...mapState("employee", ["employeeList"]),
    ...mapState("city", ["cityList", "districtList"]),
    managerList() {
      const data = this.employeeList?.filter((item) => {
        return item.groups[0].id === 2;
      });
      return data;
    },
  },
  watch: {},
  methods: {
    ...mapActions("warehouse", [
      "updateWarehouse",
      "createWarehouse",
      "getWarehouseDetail",
    ]),
    ...mapActions("employee", ["getEmployeeList"]),
    ...mapActions("city", ["getCityList", "getDistrictList"]),
    onSubmit() {
      const { manager, city, district, ...data } = this.warehouseInfo;
      const warehouseData = {
        manager: manager?.id,
        city: city?.id,
        district: district?.id,
        ...data,
      };
      if (this.warehouseInfo.id) {
        const dataUpdate = { id: this.warehouseInfo.id, data: warehouseData };
        this.updateWarehouse(dataUpdate);
      } else {
        this.createWarehouse(warehouseData);
      }
    },
    cancelUpdate() {
      this.warehouseInfo = {
        ...initialData,
      };
    },
    searchingSelect({ name }) {
      return name;
    },
    handleBackList() {
      this.$router.push({ name: "warehouse-list" });
    },
    onChangeCity() {
      const { city } = this.warehouseInfo;
      this.getDistrictList({ id: city?.id });
      this.warehouseInfo.district = "";
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
};
</script>
<style scoped></style>
