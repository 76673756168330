<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Phân quyền hệ thống" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <div class="pull-right">
                <router-link to="/settings/create-role">
                  <b-button class="btn-popup">Thêm mới</b-button>
                </router-link>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoading && roleList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="roleList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(display_name)="{ item }">
                    <span v-if="item.display_name">
                      {{ item.display_name }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <feather
                      style="cursor: pointer"
                      type="edit"
                      stroke="#3758FD"
                      stroke-width="1"
                      size="18px"
                      stroke-linejoin="round"
                      @click="onShow(item)"
                    ></feather>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && roleList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "id", label: "Mã" },
        { key: "display_name", label: "Tên vai trò" },
        { key: "actions", label: "Hành động" },
      ],
    };
  },
  components: {
    Loader,
  },
  created() {
    this.getRoleList();
  },
  watch: {},
  computed: {
    ...mapState("role", ["roleList", "isLoading"]),
  },
  methods: {
    ...mapActions("role", ["getRoleList"]),
    onShow(item) {
      this.$router.push({
        path: "/settings/detail-role",
        query: { id: item.id },
      });
    },
  },
};
</script>
