import discountService from "../../services/discount";

const state = {
  discountList: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getDiscountList({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await discountService.getAll();
      commit("SET_LIST_DISCOUNT", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getDiscountDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await discountService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createDiscount({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await discountService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cài đặt chiết khấu thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateDiscount({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await discountService.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật chiết khấu thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deleteDiscount({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await discountService.delete(id);
      await dispatch("getDiscountList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_DISCOUNT(state, payload) {
    state.discountList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
