import Vue from "vue";
import VueRouter from "vue-router";
import Body from "../components/Body.vue";
import dashboard from "../pages/dashboard.vue";
import Order from "../pages/sales/order.vue";
import OrderOnline from "../pages/sales/order-online.vue";
import UpdateOrderOnline from "../pages/sales/update-order-online.vue";
import transactions from "../pages/sales/Transaction.vue";
import CouponList from "../pages/coupon/CouponList.vue";
import AddCoupon from "../pages/coupon/AddCoupon.vue";
import PageList from "../pages/page/PageList.vue";
import CreatePage from "../pages/page/create-page.vue";
import Media from "../pages/media.vue";
import CategoryList from "../pages/categories/category-list.vue";
import CategoryListParent from "../pages/categories/category-list-parent.vue";
import CreateCategory from "../pages/categories/create-category.vue";
import CreateCategoryParent from "../pages/categories/create-category-parent.vue";
import WarehouseList from "../pages/warehouse/warehouse-list.vue";
import Inventory from "../pages/warehouse/inventory.vue";
import CreateWarehouse from "../pages/warehouse/create-warehouse.vue";
import UserList from "../pages/users/user-list.vue";
import ChangePasswordUser from "../pages/users/change-password.vue";
import CreateCustomer from "../pages/customer/create-customer.vue";
import CustomerList from "../pages/customer/customer-list.vue";
import CreateShipper from "../pages/shipper/create-shipper.vue";
import ShipperList from "../pages/shipper/shipper-list.vue";
import CustomerReport from "../pages/reports/customer-report.vue";
import DailyReport from "../pages/reports/daily-report.vue";
import SaleReport from "../pages/reports/sale-report.vue";
import ProductReport from "../pages/reports/product-report.vue";
import SupplierReport from "../pages/reports/supplier-report.vue";
import OrderProfitReport from "../pages/reports/revenue-report.vue";
import InventoryList from "../pages/inventory/inventory-list.vue";
import CreateUser from "../pages/users/create-user.vue";
import EmployeeList from "../pages/employee/employee-list.vue";
import ShipperOrder from "../pages/shipper-order/index.vue";
import ShipperInfo from "../pages/shipper-order/shipper-profile.vue";
import ShipperStatistic from "../pages/shipper-order/shipper-statistic.vue";
import ShipperNotify from "../pages/shipper-order/notify-shipper.vue";
import OrderDetail from "../pages/shipper-order/order-detail.vue";
import CreateEmployee from "../pages/employee/create-employee.vue";
import TransactionReceiptList from "../pages/transaction-receipt/transaction-receipt-list.vue";
import CreateTransactionReceipt from "../pages/transaction-receipt/create-transaction-receipt.vue";
import NewsCategoryList from "../pages/news-category/news-category-list.vue";
import CreateNewsCategory from "../pages/news-category/create-news-category.vue";
import SupplierList from "../pages/supplier/supplier-list.vue";
import RoleList from "../pages/roles/role-list.vue";
import DetailRole from "../pages/roles/detail-role.vue";
import CreateRole from "../pages/roles/create-role.vue";
import UnitList from "../pages/calculator-unit/unit-list.vue";
import CreateImage from "../pages/homepage-image/create-image.vue";
import ImageList from "../pages/homepage-image/list-image.vue";
import CreateUnit from "../pages/calculator-unit/create-unit.vue";
import CreateSupplier from "../pages/supplier/create-supplier.vue";
import DiscountList from "../pages/discount/discount-list.vue";
import CreateDiscount from "../pages/discount/create-discount.vue";
import PersonDiscountList from "../pages/person-discount/person-discount-list.vue";
import CreatePersonDiscount from "../pages/person-discount/create-person-discount.vue";
import BrandList from "../pages/brands/brand-list.vue";
import CreateBrand from "../pages/brands/create-brand.vue";
import VendorList from "../pages/vendors/vendor-list.vue";
import CreateVendor from "../pages/vendors/create-vendor.vue";
import ArticleList from "../pages/articles/article-list.vue";
import CreateArticle from "../pages/articles/create-article.vue";
import LocalTranslation from "../pages/localization/translations.vue";
import CurrencyRates from "../pages/localization/currency-rates.vue";
import Taxes from "../pages/localization/taxes.vue";
import Reports from "../pages/reports.vue";
import Profile from "../pages/profiles/profile.vue";
import UpdateProfile from "../pages/profiles/update-profile.vue";
import ChangePassword from "../pages/profiles/change-password.vue";
import Invoice from "../pages/invoice.vue";
import Import from "../pages/warehouse/import.vue";
import Auth from "../pages/authentication/index.vue";
import firebase from "firebase/app";
import Userauth from "../auth/index.js";
import LocalStorageService from "../utils/index";
import ProductList from "../pages/product/product-list.vue";
import ProductQuantitation from "../pages/product/product-quantitation.vue";
import ProductAdd from "../pages/product/product-add.vue";
import PriceList from "../pages/product/price-list.vue";
import Food from "../pages/product/food.vue";
import SupplierQuotes from "../pages/supplier/quotes.vue";
import SubmitQuotes from "../pages/supplier/submit-quotes.vue";
import OrderSupplier from "../pages/supplier/order-supplier.vue";
import ProductRefund from "../pages/supplier/product-refund.vue";
import CustomerRefundModal from "../pages/supplier/customer-refund.vue";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  { path: "", redirect: { name: "default" } },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "default",
        name: "default",
        component: dashboard,
        meta: {
          title: "RBT - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/shipper-order",
    component: ShipperOrder,
    name: "shipper-order",
    meta: {
      title: "RBT - Premium Admin Template",
    },
  },
  {
    path: "/order-detail",
    component: OrderDetail,
    name: "order-detail",
    meta: {
      title: "RBT - Premium Admin Template",
    },
  },
  {
    path: "/shipper-profile",
    component: ShipperInfo,
    name: "shipper-info",
    meta: {
      title: "RBT - Premium Admin Template",
    },
  },
  {
    path: "/shipper-statistic",
    component: ShipperStatistic,
    name: "shipper-statistic",
    meta: {
      title: "RBT - Premium Admin Template",
    },
  },
  {
    path: "/notify-shipper",
    component: ShipperNotify,
    name: "notify-shipper",
    meta: {
      title: "RBT - Premium Admin Template",
    },
  },
  {
    path: "/product",
    component: Body,
    children: [
      {
        path: "product-list",
        name: "product-list",
        component: ProductList,
        meta: {
          title: "Danh sách sản phẩm",
        },
      },
      {
        path: "product-add",
        name: "product-add",
        component: ProductAdd,
        props: (route) => ({ query: route.query.productInfo }),
        meta: {
          title: "Thêm sản phẩm",
        },
      },
      {
        path: "food",
        name: "food",
        component: Food,
        meta: {
          title: "Bếp món ăn",
        },
      },
      {
        path: "price-list",
        name: "price-list",
        component: PriceList,
        meta: {
          title: "Danh sách giá",
        },
      },
    ],
  },
  {
    path: "/sales",
    component: Body,
    children: [
      {
        path: "order",
        name: "order",
        component: Order,
        meta: {
          title: "Order | RBT - Premium Admin Template",
        },
      },
      {
        path: "order-online",
        name: "order-online",
        component: OrderOnline,
        meta: {
          title: "Order | RBT - Premium Admin Template",
        },
      },
      {
        path: "update-order-online",
        name: "update-order-online",
        component: UpdateOrderOnline,
        meta: {
          title: "Order | RBT - Premium Admin Template",
        },
      },
      {
        path: "transactions",
        name: "transactions",
        component: transactions,
        meta: {
          title: "Transactions | RBT - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/coupons",
    component: Body,
    children: [
      {
        path: "coupon-list",
        name: "coupon-list",
        component: CouponList,
        meta: {
          title: "Coupon | RBT - Premium Admin Template",
        },
      },
      {
        path: "add-coupon",
        name: "add-coupon",
        component: AddCoupon,
        meta: {
          title: "Coupon | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/pages",
    component: Body,
    children: [
      {
        path: "page-list",
        name: "page-list",
        component: PageList,
        meta: {
          title: "Pages | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-page",
        name: "create-page",
        component: CreatePage,
        meta: {
          title: "Pages | RBT - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/reports",
    component: Body,
    children: [
      {
        path: "daily",
        name: "daily",
        component: DailyReport,
        meta: {
          title: "Report | RBT - Premium Admin Template",
        },
      },
      {
        path: "sale",
        name: "sale",
        component: SaleReport,
        meta: {
          title: "Report | RBT - Premium Admin Template",
        },
      },
      {
        path: "customer",
        name: "customer",
        component: CustomerReport,
        meta: {
          title: "Report | RBT - Premium Admin Template",
        },
      },
      {
        path: "product",
        name: "product",
        component: ProductReport,
        meta: {
          title: "Report | RBT - Premium Admin Template",
        },
      },
      {
        path: "supplier",
        name: "supplier",
        component: SupplierReport,
        meta: {
          title: "Report | RBT - Premium Admin Template",
        },
      },
      {
        path: "order-profit",
        name: "order-profit",
        component: OrderProfitReport,
        meta: {
          title: "Report | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/categories",
    name: "categories",
    component: Body,
    children: [
      {
        path: "category-list",
        name: "category-list",
        component: CategoryList,
        meta: {
          title: "Category | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-category",
        name: "create-category",
        component: CreateCategory,
        props: (route) => ({ query: route.query.categoryInfo }),
        meta: {
          title: "Category | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/categories",
    name: "categories",
    component: Body,
    children: [
      {
        path: "category-list-parent",
        name: "category-list-parent",
        component: CategoryListParent,
        meta: {
          title: "Category | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-category-parent",
        name: "create-category-parent",
        component: CreateCategoryParent,
        props: (route) => ({ query: route.query.categoryInfo }),
        meta: {
          title: "Category | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/articles",
    name: "articles",
    component: Body,
    children: [
      {
        path: "article-list",
        name: "article-list",
        component: ArticleList,
        meta: {
          title: "article | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-article",
        name: "create-article",
        component: CreateArticle,
        props: (route) => ({ query: route.query.articleInfo }),
        meta: {
          title: "article | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "role",
        name: "role",
        component: RoleList,
        meta: {
          title: "Role | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-role",
        name: "create-role",
        component: CreateRole,
        meta: {
          title: "Role | RBT - Premium Admin Template",
        },
      },
      {
        path: "detail-role",
        name: "detail-role",
        component: DetailRole,
        meta: {
          title: "Role | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "create-image",
        name: "create-image",
        component: CreateImage,
        meta: {
          title: "Image | RBT - Premium Admin Template",
        },
      },
      {
        path: "image-list",
        name: "image-list",
        component: ImageList,
        meta: {
          title: "Image | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "transaction-receipt",
        name: "transaction-receipt",
        component: TransactionReceiptList,
        meta: {
          title: "transaction-receipt | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-transaction-receipt",
        name: "create-transaction-receipt",
        component: CreateTransactionReceipt,
        meta: {
          title: "transaction-receipt | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "product-quantitation",
        name: "product-quantitation",
        component: ProductQuantitation,
        meta: {
          title: "product-quantitation | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "calculator-unit",
        name: "calculator-unit",
        component: UnitList,
        meta: {
          title: "Unit | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-unit",
        name: "create-unit",
        component: CreateUnit,
        meta: {
          title: "Unit | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "discount-list",
        name: "discount-list",
        component: DiscountList,
        meta: {
          title: "discount | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-discount",
        name: "create-discount",
        component: CreateDiscount,
        meta: {
          title: "discount | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "person-discount-list",
        name: "person-discount-list",
        component: PersonDiscountList,
        meta: {
          title: "discount | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-person-discount",
        name: "create-person-discount",
        component: CreatePersonDiscount,
        meta: {
          title: "discount | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/customer",
    name: "customer",
    component: Body,
    children: [
      {
        path: "customer-list",
        name: "customer-list",
        component: CustomerList,
        meta: {
          title: "customer | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-customer",
        name: "create-customer",
        component: CreateCustomer,
        props: (route) => ({ query: route.query.customerInfo }),
        meta: {
          title: "Customer | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/shipper",
    name: "shipper",
    component: Body,
    children: [
      {
        path: "shipper-list",
        name: "shipper-list",
        component: ShipperList,
        meta: {
          title: "shipper | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-shipper",
        name: "create-shipper",
        component: CreateShipper,
        meta: {
          title: "shipper | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/warehouse",
    name: "warehouse",
    component: Body,
    children: [
      {
        path: "warehouse-list",
        name: "warehouse-list",
        component: WarehouseList,
        meta: {
          title: "warehouse | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-warehouse",
        name: "create-warehouse",
        component: CreateWarehouse,
        props: (route) => ({ query: route.query.warehouseInfo }),
        meta: {
          title: "warehouse | RBT - Premium Admin Template",
        },
      },
      {
        path: "import",
        name: "import",
        component: Import,
        meta: {
          title: "Thêm sản phẩm vào kho",
        },
      },
      {
        path: "inventory",
        name: "inventory",
        component: Inventory,
        meta: {
          title: "Kiểm kho",
        },
      },
    ],
  },

  {
    path: "/inventory",
    name: "inventory",
    component: Body,
    children: [
      {
        path: "inventory-list",
        name: "inventory-list",
        component: InventoryList,
        meta: {
          title: "Sản phẩm trong kho",
        },
      },
    ],
  },
  {
    path: "/users",
    name: "users",
    component: Body,
    children: [
      {
        path: "user-list",
        name: "user-list",
        component: UserList,
        meta: {
          title: "User | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-user",
        name: "create-user",
        component: CreateUser,
        props: (route) => ({ query: route.query.userInfo }),
        meta: {
          title: "User | RBT - Premium Admin Template",
        },
      },
      {
        path: "change-password",
        name: "change-password",
        component: ChangePasswordUser,
        props: (route) => ({ query: route.query.idUser }),
        meta: {
          title: "User | RBT - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/employee",
    name: "employee",
    component: Body,
    children: [
      {
        path: "employee-list",
        name: "employee-list",
        component: EmployeeList,
        meta: {
          title: "employee | Multikart - Premium Admin Template",
        },
      },
      {
        path: "create-employee",
        name: "create-employee",
        component: CreateEmployee,
        props: (route) => ({ query: route.query.employeeInfo }),
        meta: {
          title: "employee | Multikart - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/supplier",
    name: "supplier",
    component: Body,
    children: [
      {
        path: "supplier-list",
        name: "supplier-list",
        component: SupplierList,
        meta: {
          title: "supplier | Multikart - Premium Admin Template",
        },
      },
      {
        path: "create-supplier",
        name: "create-supplier",
        component: CreateSupplier,
        props: (route) => ({ query: route.query.supplierInfo }),
        meta: {
          title: "supplier | Multikart - Premium Admin Template",
        },
      },
      {
        path: "quotes",
        name: "quotes",
        component: SupplierQuotes,
        meta: {
          title: "Báo giá nhà cung cấp",
        },
      },
      {
        path: "product-refund",
        name: "product-refund",
        component: ProductRefund,
        meta: {
          title: "Trả hàng nhà cung cấp",
        },
      },
      {
        path: "customer-refund",
        name: "customer-refund",
        component: CustomerRefundModal,
        meta: {
          title: "Khách trả hàng",
        },
      },
      {
        path: "submit-quotes",
        name: "submit-quotes",
        component: SubmitQuotes,
        meta: {
          title: "Nhập báo giá nhà cung cấp",
        },
      },
      {
        path: "order-supplier",
        name: "order-supplier",
        component: OrderSupplier,
        meta: {
          title: "Đơn hàng nhà cung cấp",
        },
      },
    ],
  },
  {
    path: "/news-category",
    name: "news-category",
    component: Body,
    children: [
      {
        path: "news-category-list",
        name: "news-category-list",
        component: NewsCategoryList,
        meta: {
          title: "news | Multikart - Premium Admin Template",
        },
      },
      {
        path: "create-news-category",
        name: "create-news-category",
        component: CreateNewsCategory,
        props: (route) => ({ query: route.query.itemNewInfo }),
        meta: {
          title: "news | Multikart - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/brands",
    name: "brand",
    component: Body,
    children: [
      {
        path: "brand-list",
        name: "brand-list",
        component: BrandList,
        meta: {
          title: "Brand | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-brand",
        name: "create-brand",
        component: CreateBrand,
        props: (route) => ({ query: route.query.brandInfo }),
        meta: {
          title: "Brand | RBT - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/vendors",
    name: "vendors",
    component: Body,
    children: [
      {
        path: "vendor-list",
        name: "vendor-list",
        component: VendorList,
        meta: {
          title: "Vendor | RBT - Premium Admin Template",
        },
      },
      {
        path: "create-vendor",
        name: "create-vendor",
        component: CreateVendor,
        meta: {
          title: "Vendor | RBT - Premium Admin Template",
        },
      },
    ],
  },
  {
    path: "/localization",
    name: "vendolocalizationrs",
    component: Body,
    children: [
      {
        path: "translations",
        name: "translations",
        component: LocalTranslation,
        meta: {
          title: "Localization | RBT - Premium Admin Template",
        },
      },
      {
        path: "currency-rates",
        name: "currency-rates",
        component: CurrencyRates,
        meta: {
          title: "Localization | RBT - Premium Admin Template",
        },
      },
      {
        path: "taxes",
        name: "taxes",
        component: Taxes,
        meta: {
          title: "Localization | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/settings",
    name: "settings",
    component: Body,
    children: [
      {
        path: "profile",
        name: "profile",
        component: Profile,
        meta: {
          title: "Profile | RBT - Premium Admin Template",
        },
      },
      {
        path: "update-profile",
        name: "update-profile",
        component: UpdateProfile,
        props: (route) => ({ query: route.query.profileInfo }),
        meta: {
          title: "Profile | RBT - Premium Admin Template",
        },
      },
      {
        path: "change-password-profile",
        name: "change-password-profile",
        component: ChangePassword,
        meta: {
          title: "Profile | RBT - Premium Admin Template",
        },
      },
    ],
  },

  {
    path: "/auth/login",
    name: "login",
    component: Auth,
    meta: {
      title: "Login | RBT - Premium Admin Template",
    },
  },
  {
    path: "/app",
    component: Body,
    children: [
      {
        path: "media",
        name: "media",
        component: Media,
        meta: {
          title: "Media | RBT - Premium Admin Template",
        },
      },
      {
        path: "reports",
        name: "reports",
        component: Reports,
        meta: {
          title: "Reports | RBT - Premium Admin Template",
        },
      },
      {
        path: "invoice",
        name: "invoice",
        component: Invoice,
        meta: {
          title: "Invoice | RBT - Premium Admin Template",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isPublicPage = ["/auth/login", "/register"];
  const authRequired = !isPublicPage.includes(to.path);
  const loggedIn = LocalStorageService.getLocalAccessToken();

  if (authRequired && !loggedIn) {
    return next("/auth/login");
  } else {
    return next();
  }
});

export default router;
