<template>
  <div>
    <loader :is-visible="isLoadingRefund"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Khách trả hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordRefund }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div class="col-sm-8">
                  <div class="col-sm-7 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important; width: 30vh"
                    >
                      <b-form-input
                        type="text"
                        v-model="search"
                        placeholder="Tìm kiếm"
                        @change="onSearch()"
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModalImport()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Trả hàng</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoadingRefund"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="warehouseImportActive"
                  :fields="tablefields"
                >
                  <template v-slot:cell(updated_at)="{ item }">
                    <span v-if="item.updated_at">
                      {{ item.updated_at | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <span v-if="item.created_at">
                      {{ item.created_at | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(customer)="{ item }">
                    <span v-if="item.customer">
                      {{ item.customer.name }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="eye"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        stroke-linejoin="round"
                        @click="onShow(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoadingRefund && listRefundCustomer.length < 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordRefund > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordRefund"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <CustomerRefundModal
                :refundInfo="refundInfo"
                @onSubmit="onSubmit"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import CustomerRefundModal from "../../components/modals/CustomerRefundModal.vue";
import { statusCheckWarehouseText } from "../../config/status-default";

const colorBadgeStatus = {
  0: "warning",
  1: "success",
  2: "primary",
  3: "danger",
};

export default {
  components: {
    CustomerRefundModal,
  },
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "code", label: "Mã phiếu" },
        { key: "name", label: "Tên phiếu" },
        { key: "customer", label: "Khách hàng" },
        { key: "updated_at", label: "Thời gian tạo" },
        { key: "actions", label: "Chi tiết đơn hàng" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
      refundInfo: {},
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getRefundList(params);
  },
  computed: {
    ...mapState("customer", [
      "isLoadingRefund",
      "listRefundCustomer",
      "totalRecordRefund",
      "refundDetail",
    ]),
    colorStatus() {
      return colorBadgeStatus;
    },
    statusCheckWarehouse() {
      return statusCheckWarehouseText;
    },
    warehouseImportActive() {
      return this.listRefundCustomer?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  watch: {
    refundDetail() {
      if (this.refundDetail) {
        this.refundInfo = { ...this.refundDetail };
        this.$bvModal.show("modal-import-warehouse");
      }
    },
  },
  methods: {
    ...mapActions("warehouse", [
      "getWarehouseList",
      "deleteWarehouse",
      "onWarehouseImport",
      "onUpdateWarehouseImport",
      "onApprovedWarehouseImport",
      "onDeleteWarehouseImport",
      "onWarehouseImportCancel",
    ]),
    ...mapActions("customer", [
      "onCreateRefundCustomer",
      "getRefundList",
      "deleteRefund",
      "getRefundDetail",
    ]),
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.deleteRefund({ id, params });
    },
    onSubmit(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onCreateRefundCustomer({ data: payload, params });
    },
    openModalImport() {
      this.refundInfo = {};
      this.$bvModal.show("modal-import-warehouse");
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    onShow(item) {
      this.getRefundDetail(item.id);
    },
    onSearch() {
      const params = {
        search: this.search,
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      this.getRefundList(params);
    },
    handlePageChange(value) {
      this.pageNumber = value;
      const params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        const data = {
          search: this.search,
          ...params,
        };
        this.getRefundList(data);
      } else {
        this.getRefundList(params);
        this.$router.push({
          path: "/supplier/customer-refund",
          query: { page: this.pageNumber, page_size: this.pageSize },
        });
      }
    },
  },
};
</script>
