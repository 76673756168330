var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Báo cáo nhà cung cấp"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('date-picker',{staticClass:"ml-3",attrs:{"value-type":"format","range":"","format":"DD-MM-YYYY","placeholder":"Chọn ngày để xem"},on:{"change":_vm.onChangeParam},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('div',{staticClass:"col-md-5"},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Tìm kiếm"},on:{"change":_vm.onChangeParam},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-1"},[_c('b-button',{staticClass:"btn-popup mb-0",attrs:{"variant":"primary"},on:{"click":_vm.onChangeParam}},[_vm._v("Tìm")])],1)],1)]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"table-responsive datatable-vue product-list"},[(!_vm.isLoading && _vm.supplierReportList.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"show-empty":"","striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.supplierReportData,"fields":_vm.tablefields,"tbody-tr-class":_vm.rowClass},scopedSlots:_vm._u([{key:"cell(code)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"cell(value_order)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.value_order))+" ")])]}},{key:"cell(return_order)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.return_order)+" ")])]}},{key:"cell(value_return)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.value_return))+" ")])]}},{key:"cell(net_total)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("formatCurrency")(item.net_total))+" ")])]}}],null,false,2252705877)}):_vm._e(),(!_vm.isLoading && _vm.supplierReportList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1),_c('b-col',{staticClass:"my-1 p-0 pagination-justify",attrs:{"md":"12"}},[(_vm.totalSupplierRecord > 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalSupplierRecord,"per-page":_vm.pageSize,"aria-controls":"my-table"},on:{"change":_vm.handlePageChange},model:{value:(_vm.pageNumber),callback:function ($$v) {_vm.pageNumber=$$v},expression:"pageNumber"}}):_vm._e()],1)],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }