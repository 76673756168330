import customerService from "../../services/customer";

const state = {
  customerList: [],
  totalRecord: 0,
  isLoading: false,
  processImportProduct: {
    max: 0,
    countProcess: 0,
    success: 0,
    error: 1,
    exist: 0,
  },
  isLoadingRefund: false,
  listRefundCustomer: [],
  totalRecordRefund: 0,
  refundDetail: null,
};
const getters = {};
const actions = {
  async getRefundDetail({ commit }, id) {
    try {
      await commit("SET_LOADING_REFUND", true);
      const response = await customerService.getRefundDetail(id);
      commit("SET_REFUND_DETAIL", response.data);
      await commit("SET_LOADING_REFUND", false);
      return response.data;
    } catch (error) {
      await commit("SET_LOADING_REFUND", false);
      return {};
    }
  },
  async onCreateRefundCustomer({ dispatch, commit }, payload) {
    try {
      const { data, params } = payload;
      await commit("SET_LOADING_REFUND", true);
      const response = await customerService.createRefund(data);
      await commit("SET_LOADING_REFUND", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm phiếu trả hàng thành công",
          isShow: true,
        },
        { root: true }
      );
      dispatch("getRefundList", params);
      return response;
    } catch (error) {
      await commit("SET_LOADING_REFUND", false);
      await commit("SET_ERROR_LIST", error.response, {
        root: true,
      });
    }
  },
  async getRefundList({ commit }, params) {
    try {
      commit("SET_LOADING_REFUND", true);
      const response = await customerService.getListRefundCustomer(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_REFUND_CUSTOMER", results);
      commit("SET_TOTAL_RECORD_REFUND", count);
      commit("SET_LOADING_REFUND", false);
      return response;
    } catch (error) {
      commit("SET_LOADING_REFUND", false);
    }
  },
  async deleteRefund({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING_REFUND", true);
      const response = await customerService.deleteRefundCustomer(id);
      await dispatch("getRefundList", params);
      await commit("SET_LOADING_REFUND", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING_REFUND", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async importListCustomer({ commit, dispatch, state }, payload) {
    let countProcess = 0;
    let success = 0;
    let error = 0;
    let exist = 0;
    // const newArray = payload.slice(0, 5);
    for (const user of payload) {
      try {
        commit("SET_PROCESS_IMPORT_PRODUCT", {
          max: payload.length,
          countProcess: countProcess++,
          error: error,
          exist: exist,
          success: success,
        });
        if (user.phone_number) {
          await customerService.create(user);
          commit("SET_PROCESS_IMPORT_PRODUCT", {
            max: payload.length,
            countProcess: countProcess,
            error: error,
            exist: exist,
            success: success++,
          });
          continue;
        }
        commit("SET_PROCESS_IMPORT_PRODUCT", {
          max: payload.length,
          countProcess: countProcess,
          error: error,
          exist: exist++,
          success: success,
        });
      } catch (err) {
        commit("SET_PROCESS_IMPORT_PRODUCT", {
          max: payload.length,
          countProcess: countProcess,
          error: error++,
          exist: exist,
          success: success,
        });
        continue;
      }
    }
    dispatch("getCustomerList", { page: 1, page_size: 10 });
  },
  async getCustomerList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await customerService.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_CUSTOMER", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getCustomerDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await customerService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createCustomer({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await customerService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm khách hàng thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, {
        root: true,
      });
    }
  },

  async updateCustomer({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await customerService.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, {
        root: true,
      });
    }
  },

  async deleteCustomer({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await customerService.delete(id);
      await dispatch("getCustomerList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_REFUND_DETAIL(state, payload) {
    state.refundDetail = payload;
  },
  SET_LIST_REFUND_CUSTOMER(state, payload) {
    state.listRefundCustomer = payload;
  },
  SET_LOADING_REFUND(state, payload) {
    state.isLoadingRefund = payload;
  },
  SET_PROCESS_IMPORT_PRODUCT(state, payload) {
    state.processImportProduct = payload;
  },
  SET_LIST_CUSTOMER(state, payload) {
    state.customerList = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_TOTAL_RECORD_REFUND(state, payload) {
    state.totalRecordRefund = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
