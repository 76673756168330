var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Thiết lập chiết khấu khách hàng"}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card tab2-card"},[_c('div',{staticClass:"card-header"},[(this.$route.query.id)?_c('h5',[_vm._v(" Cập nhật chiết khấu khách hàng ")]):_c('h5',[_vm._v(" Thêm chiết khấu khách hàng ")])]),_c('div',{staticClass:"card-body"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.personDiscountInfo)?_c('b-form',{staticClass:"needs-validation user-add",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit()}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-md-12"},[_c('validation-provider',{attrs:{"name":"Khách hàng","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"id":"example-input-group-1","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"8","label-for":"example-input-1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("*")]),_vm._v("Khách hàng")]},proxy:true}],null,true)},[_c('multiselect',{staticClass:"rbt-custom-input",attrs:{"placeholder":"Chọn khách hàng","show-labels":false,"disabled":!!_vm.$route.query.id,"internal-search":false,"options":_vm.customerList},on:{"search-change":_vm.search},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_c('span',{staticClass:"option__desc"},[_c('span',{staticClass:"option__title"},[_vm._v(_vm._s(props.option.name))])])]}},{key:"option",fn:function(props){return [_c('div',{staticClass:"option__desc"},[_c('p',{staticClass:"option__title"},[_vm._v(" "+_vm._s(props.option.name)+" ")])])]}}],null,true),model:{value:(_vm.personDiscountInfo.user),callback:function ($$v) {_vm.$set(_vm.personDiscountInfo, "user", $$v)},expression:"personDiscountInfo.user"}},[_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v("Không tìm thấy kết quả!!!")])]),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Chiết khấu riêng","rules":{
                        required: true,
                        percentGreaterZero: true,
                        regexDiscount: /^[0-9]+$/,
                      }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"id":"example-input-group-1","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"8","label-for":"example-input-1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',[_vm._v("*")]),_vm._v("Chiết khấu riêng")]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"example-input-1","name":"example-input-1","type":"number","placeholder":"Nhập chiết khấu riêng cho khách","state":_vm.getValidationState(validationContext),"aria-describedby":"input-1-live-feedback"},model:{value:(_vm.personDiscountInfo.discount),callback:function ($$v) {_vm.$set(_vm.personDiscountInfo, "discount", $$v)},expression:"personDiscountInfo.discount"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-1-live-feedback"}},[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}],null,true)}),_c('validation-provider',[_c('b-form-group',{attrs:{"id":"example-input-group-1","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"8","label-for":"example-input-1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Chiết khấu tự động")]},proxy:true}],null,true)},[(
                            _vm.personDiscountInfo.user &&
                              _vm.personDiscountInfo.user.discount &&
                              !_vm.$route.query.id
                          )?_c('span',[_vm._v(_vm._s(_vm.personDiscountInfo.user.discount.discount_auto)+"%")]):(_vm.$route.query.id)?_c('span',[_vm._v(_vm._s(_vm.personDiscountInfo.discount_auto)+"%")]):_c('span',[_vm._v("__")])])],1),_c('validation-provider',[_c('b-form-group',{attrs:{"id":"example-input-group-1","label-cols-sm":"4","label-cols-lg":"3","content-cols-sm":"","content-cols-lg":"8","label-for":"example-input-1"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Sử dụng chiết khấu tự động ")]},proxy:true}],null,true)},[_c('b-form-checkbox',{attrs:{"id":"checkbox-1","name":"checkbox-1"},model:{value:(_vm.personDiscountInfo.auto),callback:function ($$v) {_vm.$set(_vm.personDiscountInfo, "auto", $$v)},expression:"personDiscountInfo.auto"}})],1)],1)],1)]),_c('div',{staticClass:"pull-right"},[_c('button',{staticClass:"btn btn-danger mr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.handleBackList()}}},[_vm._v(" Trở lại ")]),(!_vm.$route.query.id)?_c('button',{staticClass:"btn btn-danger mr-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.cancelUpdate()}}},[_vm._v(" Xóa ")]):_vm._e(),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":invalid}},[_vm._v(" Lưu ")])])]):_vm._e()]}}])})],1)])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }