import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class ShipperService {
  async getAll(params) {
    return axiosInstance.get(apiRoutesEnum.shipper, { params: params });
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.shipper}/${id}`);
  }

  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.user, payload);
  }

  async update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.user}/${id}`, data);
  }

  async delete(id) {
    return axiosInstance.delete(`${apiRoutesEnum.shipper}/${id}`);
  }
}

export default new ShipperService();
