<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh mục bài viết" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật danh mục bài viết
              </h5>
              <h5 v-else>
                Thêm danh mục bài viết
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="newsCategoryInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <validation-provider
                        name="Tên danh mục bài viết"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên danh mục bài viết
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="newsCategoryInfo.name"
                            placeholder="Nhập tên danh mục bài viết"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mô tả"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Mô tả thêm
                          </template>
                          <b-form-textarea
                            id="textarea-rows"
                            rows="8"
                            v-model="newsCategoryInfo.description"
                            placeholder="Nhập mô tả danh mục bài viết"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
  description: "",
};

export default {
  data() {
    const newsCategoryInfo = this.$route.query.id
      ? this.newsCategoryInfo
      : initialData;
    return {
      newsCategoryInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.newsCategoryInfo = await this.getNewsCategoryDetail(
        this.$route.query.id
      );
    } else {
      this.newsCategoryInfo = {
        ...initialData,
      };
    }
  },
  watch: {},
  computed: {
    ...mapState("newsCategory", ["isLoading"]),
  },
  methods: {
    ...mapActions("newsCategory", [
      "createNewsCategory",
      "updateNewsCategory",
      "getNewsCategoryDetail",
    ]),
    onSubmit() {
      if (this.newsCategoryInfo.id) {
        const itemNewData = {
          id: this.newsCategoryInfo.id,
          data: this.newsCategoryInfo,
        };
        this.updateNewsCategory(itemNewData);
      } else {
        this.createNewsCategory(this.newsCategoryInfo);
      }
    },
    cancelUpdate() {
      this.newsCategoryInfo = {
        ...initialData,
      };
    },
    handleBackList() {
      this.$router.push({ name: "news-category-list" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
};
</script>
<style scoped></style>
