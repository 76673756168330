import Vue from "vue";
import Vuex from "vuex";
import employee from "./modules/employee.js";
import menu from "./modules/menu.js";
import order from "./modules/order.js";
import product from "./modules/product.js";
import digitalProducts from "./modules/digital-products.js";
import coupon from "./modules/coupon.js";
import pages from "./modules/pages.js";
import media from "./modules/media.js";
import user from "./modules/user.js";
import vendor from "./modules/vendor.js";
import localization from "./modules/localization.js";
import invoice from "./modules/invoice.js";
import { authentication } from "./modules/authentication.js";
import reports from "./modules/reports.js";
import category from "./modules/category.js";
import warehouse from "./modules/warehouse.js";
import profile from "./modules/profile.js";
import brand from "./modules/brand.js";
import supplier from "./modules/supplier.js";
import city from "./modules/city.js";
import inventory from "./modules/inventory.js";
import role from "./modules/role.js";
import dashboard from "./modules/dashboard.js";
import customer from "./modules/customer.js";
import newsCategory from "./modules/news-category";
import article from "./modules/article.js";
import shipper from "./modules/shipper.js";
import unit from "./modules/unit.js";
import food from "./modules/food.js";
import discount from "./modules/discount.js";
import personDiscount from "./modules/person-discount.js";
import transactionReceipt from "./modules/transaction-receipt.js";
import orderType from "./modules/order-type.js";
import notify from "./modules/notify.js";
import homepageImage from "./modules/homepage-image.js";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errorList: {},
    isMessage: {
      isShow: false,
      type: "",
      content: "",
    },
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      window.location.reload();
    },
    SET_MESSAGE(state, payload) {
      state.isMessage = payload;
    },
    SET_ERROR_LIST(state, payload) {
      state.errorList = payload;
    },
  },
  actions: {
    setMessage({ commit }, payload) {
      commit("SET_MESSAGE", payload);
    },
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
  },
  modules: {
    menu,
    product,
    order,
    employee,
    digitalProducts,
    coupon,
    pages,
    media,
    user,
    vendor,
    localization,
    invoice,
    authentication,
    reports,
    category,
    warehouse,
    profile,
    brand,
    city,
    inventory,
    role,
    supplier,
    customer,
    dashboard,
    newsCategory,
    article,
    shipper,
    unit,
    discount,
    personDiscount,
    transactionReceipt,
    food,
    orderType,
    notify,
    homepageImage,
  },
  plugins: [
    createPersistedState({
      key: "order",
      paths: ["order"],
      // storage: {
      //   getItem: (key) => {
      //     // console.log(key);
      //   },
      //   setItem: (key, value) => {
      //     const valueTemp = JSON.parse(value);

      //     const currentTab = valueTemp.order?.currentTab;
      //     if (currentTab) {
      //       localStorage.setItem("currentTab", currentTab);
      //     }

      //     const tabList = valueTemp.order?.tabList;
      //     if (tabList?.length > 0) {
      //       localStorage.setItem("tabList", JSON.stringify(tabList));
      //     }

      //     const tabListContent = valueTemp.order?.tabListContent || "";
      //     console.log("tabListContent", tabListContent);
      //     if (tabListContent === {}) {
      //       localStorage.setItem(
      //         "tabListContent",
      //         JSON.stringify(tabListContent)
      //       );
      //     }
      //     // const tabListContent = valueTemp.order?.tabListContent || "";
      //     // const tabListContentKey = Object.keys(tabListContent) || "";
      //     // if (tabListContentKey?.length > 0) {
      //     //   tabListContentKey.forEach((key) => {
      //     //     // console.log("key", key);
      //     //     localStorage.setItem(
      //     //       `tab${key}`,
      //     //       JSON.stringify(tabListContent[key])
      //     //     );
      //     //   });
      //     // }
      //   },
      //   removeItem: (key) => {},
      // },
    }),
  ],
});
