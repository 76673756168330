import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class DashboardService {
  async getDashboard() {
    return axiosInstance.get(apiRoutesEnum.overviewReport);
  }

  async getRevenueReport(params) {
    return axiosInstance.get(apiRoutesEnum.revenueReport, { params: params });
  }
}

export default new DashboardService();
