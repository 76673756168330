/* eslint-disable prefer-spread */
/* eslint-disable no-underscore-dangle */
export class EventEmitter {
  constructor() {
    this._listeners = {};
  }

  /**
   * @param {string} eventName
   * @param {function} listener
   */
  addListener(eventName, listener) {
    this._on(eventName, listener);
  }

  /**
   * @param {string} eventName
   * @param {function} listener
   */
  removeListener(eventName, listener) {
    this._off(eventName, listener);
  }

  /**
   * Remove all the listener that exist in the channel
   */
  removeAllListener() {
    this._listeners = {};
  }

  emit(eventName, ...args) {
    const eventListeners = this._listeners[eventName] ?? [];
    // Passing all arguments after the eventName to the listeners.
    eventListeners.forEach((listener) => {
      Reflect.apply(listener, null, args);
    });
  }

  /**
   * @ignore
   */
  _on(eventName, listener) {
    let eventListeners = this._listeners[eventName];
    if (!eventListeners) {
      eventListeners = new Set();
      this._listeners[eventName] = eventListeners;
    }
    eventListeners.add(listener);
  }

  /**
   * @ignore
   */
  _off(eventName, listener) {
    const eventListeners = this._listeners[eventName];
    if (!eventListeners) {
      return;
    }
    eventListeners.delete(listener);
    this._listeners[eventName] = eventListeners;
  }
}
