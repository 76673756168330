<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Đơn hàng" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Chi tiết đơn hàng
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider v-if="orderOnlineDetail.store">
                        <b-form-group
                          id="example-input-group-6"
                          label="Kho hàng"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-6"
                        >
                          <div>{{ orderOnlineDetail.store.name }}</div>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mã đơn hàng"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Mã đơn hàng
                          </template>
                          <div>{{ orderOnlineDetail.code }}</div>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên đơn hàng"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-2"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            Tên đơn hàng
                          </template>
                          <div>{{ orderOnlineDetail.name }}</div>

                          <b-form-invalid-feedback id="input-2-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Nhân viên tạo đơn"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            Nhân viên tạo đơn
                          </template>

                          <multiselect
                            placeholder="Nhân viên bán hàng"
                            v-model="orderOnlineDetail.staff"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            disabled
                            :show-labels="false"
                            :options="employeeList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider v-if="shipperData">
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            Nhân viên giao hàng
                          </template>

                          <span>{{ shipperData.name }}</span>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider>
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            Trạng thái đơn hàng
                          </template>

                          <multiselect
                            placeholder="Trạng thái đơn hàng"
                            v-model="orderOnlineDetail.status_order"
                            :disabled="
                              orderOnlineDetail.status_order.code ==
                                'SUCCESS' ||
                                orderOnlineDetail.status_order.code == 'CANCEL'
                            "
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            :show-labels="false"
                            :options="customStatusOrder"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider
                        v-if="orderOnlineDetail.time_receive"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-5"
                        >
                          <template v-slot:label>
                            Thời gian nhận hàng
                          </template>

                          <span>{{
                            orderOnlineDetail.time_receive | showDate
                          }}</span>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <div class="col-sm-6">
                      <div v-if="customerData">
                        <validation-provider
                          :rules="{ max: 255 }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            id="example-input-group-6"
                            label="Khách hàng"
                            label-cols-sm="5"
                            label-cols-lg="4"
                            content-cols-sm
                            content-cols-lg="7"
                            label-for="example-input-6"
                          >
                            <div>{{ customerData.name }}</div>

                            <b-form-invalid-feedback
                              id="input-6-live-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Số điện thoại"
                          :rules="{}"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            id="example-input-group-6"
                            label="Số điện thoại"
                            label-cols-sm="5"
                            label-cols-lg="4"
                            content-cols-sm
                            content-cols-lg="7"
                            label-for="example-input-6"
                          >
                            <div v-if="customerData.phone_number">
                              {{ customerData.phone_number }}
                            </div>

                            <b-form-invalid-feedback
                              id="input-6-live-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>

                        <validation-provider
                          name="Địa chỉ"
                          :rules="{}"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            id="example-input-group-6"
                            label="Địa chỉ nhận hàng"
                            label-cols-sm="5"
                            label-cols-lg="4"
                            content-cols-sm
                            content-cols-lg="7"
                            label-for="example-input-6"
                          >
                            <div v-if="orderOnlineDetail.address">
                              {{ orderOnlineDetail.address.address }} /
                              {{ orderOnlineDetail.address.district }} /
                              {{ orderOnlineDetail.address.city }}
                            </div>

                            <b-form-invalid-feedback
                              id="input-6-live-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <div v-else>
                        <validation-provider
                          :rules="{ max: 255 }"
                          v-slot="validationContext"
                        >
                          <b-form-group
                            id="example-input-group-6"
                            label="Khách hàng"
                            label-cols-sm="5"
                            label-cols-lg="4"
                            content-cols-sm
                            content-cols-lg="7"
                            label-for="example-input-6"
                          >
                            <div>Khách lẻ</div>

                            <b-form-invalid-feedback
                              id="input-6-live-feedback"
                              >{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback
                            >
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <validation-provider
                        name="Thời gian đặt hàng"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Thời gian đặt hàng"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-6"
                        >
                          <div>
                            {{ orderOnlineDetail.order_date | showDate }}
                          </div>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        v-if="
                          orderOnlineDetail.status_order.code === 'DELIVERED'
                        "
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Thời gian giao hàng thành công"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-6"
                        >
                          <div>
                            {{ orderOnlineDetail.updated_at | showDate }}
                          </div>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-6"
                          label="Phương thức thanh toán"
                          label-cols-sm="5"
                          label-cols-lg="4"
                          content-cols-sm
                          content-cols-lg="7"
                          label-for="example-input-6"
                        >
                          <div>
                            {{ orderOnlineDetail.payment_method.name }}
                          </div>
                        </b-form-group>
                      </validation-provider>
                      <b-form-group
                        id="example-input-group-6"
                        label="Ghi chú"
                        label-cols-sm="5"
                        label-cols-lg="4"
                        content-cols-sm
                        content-cols-lg="7"
                        label-for="example-input-6"
                      >
                        <div>
                          {{ orderOnlineDetail.note }}
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="table-responsive datatable-vue text-center mt-4">
                    <b-table
                      show-empty
                      striped
                      hover
                      head-variant="light"
                      bordered
                      stacked="md"
                      :items="productData"
                      :fields="tablefields"
                    >
                      <template #empty>
                        <span class="text-danger">
                          Chưa có sản phẩm nào
                        </span>
                      </template>
                      <template v-slot:cell(name)="{ item }">
                        <span> {{ item.product.name }} </span>
                      </template>
                      <template v-slot:cell(quantity)="{ item }">
                        <span>
                          {{ item.quantity }} {{ item.product.unit }}
                        </span>
                      </template>
                      <template v-slot:cell(price)="{ item }">
                        <span>
                          {{ item.price | formatCurrency }}
                        </span>
                      </template>
                      <template v-slot:cell(price_after_promotion)="{ item }">
                        <span>
                          {{ item.price_after_promotion | formatCurrency }}
                        </span>
                      </template>
                      <template v-slot:cell(note)="{ item }">
                        <span>
                          {{ item.note }}
                        </span>
                      </template>
                      <template v-slot:cell(revenue)="{ item }">
                        <span>
                          {{ item.revenue | formatCurrency }}
                        </span>
                      </template>
                    </b-table>
                  </div>
                  <div class="row d-flex">
                    <div class="col-md-3"></div>
                    <div class="col-md-4"></div>
                    <div class="col-md-5">
                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Tiền chiết khấu</b>
                          </template>
                          <div>
                            {{ orderOnlineDetail.discount | formatCurrency }}
                          </div>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Giá trị hàng hoá thường</b>
                          </template>
                          <div>
                            {{
                              orderOnlineDetail.normal_goods_value
                                | formatCurrency
                            }}
                          </div>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Giá trị hàng hoá khuyến mãi</b>
                          </template>
                          <div>
                            {{
                              orderOnlineDetail.promotional_goods_value
                                | formatCurrency
                            }}
                          </div>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider v-slot="validationContext">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Khuyến mãi</b>
                          </template>
                          <div>
                            {{ orderOnlineDetail.promotion | formatCurrency }}
                          </div>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider v-slot="validationContext">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Tổng tiền hàng</b>
                          </template>
                          <div>
                            {{
                              orderOnlineDetail.total_invoice | formatCurrency
                            }}
                          </div>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider v-slot="validationContext">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Phụ phí</b>
                          </template>
                          <div>
                            {{
                              orderOnlineDetail.additional_fee | formatCurrency
                            }}
                          </div>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                      <validation-provider v-slot="validationContext">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="5"
                          label-cols-lg="6"
                          content-cols-sm
                          content-cols-lg="6"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <b>Tổng tiền thanh toán</b>
                          </template>
                          <div>
                            {{
                              orderOnlineDetail.total_payment | formatCurrency
                            }}
                          </div>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      voucher: "",
      tablefields: [
        { key: "name", label: "Sản phẩm" },
        { key: "quantity", label: "Số lượng" },
        { key: "price", label: "Đơn giá" },
        { key: "price_after_promotion", label: "Giá khuyến mãi" },
        { key: "revenue", label: "Thành tiền" },
        { key: "note", label: "Ghi chú" },
      ],
    };
  },
  components: {
    Loader,
  },
  async created() {
    const roleId = this.$route.query.id || 1;
    this.getOrderOnlineId(roleId);
    this.getEmployeeList();
    this.getOrderStatusList();
    this.getPaymentStatusList();
  },
  computed: {
    ...mapState(["isUpdating"]),
    ...mapState("order", ["isLoading", "errorList", "orderOnlineDetail"]),
    ...mapState("employee", ["employeeList"]),
    ...mapState("reports", ["statusOrderList", "statusPayment"]),
    customerData() {
      return this.orderOnlineDetail?.customer;
    },
    shipperData() {
      return this.orderOnlineDetail?.shipper;
    },
    productData() {
      return this.orderOnlineDetail?.products;
    },
    customStatusOrder() {
      const listCodeStatusShipper = [
        "PICKING_UP_GOODS",
        "TOOK_THE_GOODS",
        "DELIVERING",
        "DELIVERED",
        "CUSTOMER_CANCEL",
        "NEW_ORDER",
        "CONFIRM",
      ];
      const filtered = this.statusOrderList.filter((el) => {
        return listCodeStatusShipper.indexOf(el?.code) === -1;
      });
      return filtered;
    },
  },
  watch: {},
  methods: {
    ...mapActions("order", ["updateStatusOrder", "getOrderOnlineId"]),
    ...mapActions("employee", ["getEmployeeList"]),
    ...mapActions("reports", ["getOrderStatusList", "getPaymentStatusList"]),
    onSubmit() {
      this.updateStatusOrder({
        id: this.orderOnlineDetail.id,
        data: {
          status_order: this.orderOnlineDetail?.status_order?.id,
        },
      });
    },
    handleBackList() {
      this.$router.push({ name: "order-online" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    searchingSelect({ name }) {
      return name;
    },
  },
};
</script>
<style scoped></style>
