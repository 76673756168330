<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <b-modal
      id="modal-2"
      title="Khách huỷ đơn"
      @ok="onCustomerCancel(orderShipperDetail.id)"
    >
      <p class="my-4">
        Xác nhận huỷ đơn <b>{{ orderShipperDetail.code }}</b>
      </p>
    </b-modal>
    <b-modal
      id="modal-3"
      title="Trả đơn hàng"
      @ok="onReturnOrder(orderShipperDetail.id)"
    >
      <p class="my-4">
        Xác nhận trả đơn hàng <b>{{ orderShipperDetail.code }}</b>
      </p>
    </b-modal>
    <b-modal
      id="modal-4"
      title="Bỏ đơn hàng"
      @ok="onPreventOrder(orderShipperDetail.id)"
    >
      <p class="my-4">
        Xác nhận không lấy đơn <b>{{ orderShipperDetail.code }}</b>
      </p>
    </b-modal>
    <div>
      <b-navbar fixed="top" type="dark" style="background-color: #42b983">
        <b-navbar-brand @click="onBackOrder" tag="h1" class="mb-0"
          ><b-icon-arrow-left></b-icon-arrow-left
        ></b-navbar-brand>
        <b-nav-text class="header-nav-shipper"
          ><b>CHI TIẾT ĐƠN HÀNG</b></b-nav-text
        >
      </b-navbar>
    </div>
    <template v-if="idShipperOrder === idShipperProfile">
      <ProductList :productListData="productListData" />
      <b-card style="margin-bottom: 50px">
        <b-card-text>
          Mã đơn hàng:
          <span class="pull-right">{{ orderShipperDetail.code }}</span>
        </b-card-text>
        <b-card-text v-if="orderShipperDetail.desired_delivery_time">
          Thời gian muốn nhận hàng:
          <span class="pull-right">{{
            orderShipperDetail.desired_delivery_time | showDate
          }}</span>
        </b-card-text>
        <b-card-text>
          Thành tiền:
          <span class="pull-right">{{
            orderShipperDetail.total_invoice | formatCurrency
          }}</span>
        </b-card-text>
        <b-card-text>
          Phụ phí:
          <span class="pull-right">{{
            orderShipperDetail.additional_fee | formatCurrency
          }}</span>
        </b-card-text>
        <b-card-text
          >Tổng thu:
          <span class="pull-right">{{
            orderShipperDetail.total_payment | formatCurrency
          }}</span></b-card-text
        >
        <b-card-text v-if="orderShipperDetail.status_order.code === 'DELIVERED'"
          >Tiền khách đưa:
          <span class="pull-right">{{
            orderShipperDetail.pay_directly | formatCurrency
          }}</span></b-card-text
        >
        <b-card-text v-if="orderShipperDetail.status_order.code === 'DELIVERED'"
          >Hình thức thanh toán:
          <span class="pull-right">{{
            orderShipperDetail.payment_method.name
          }}</span></b-card-text
        >
        <b-card-text v-if="orderShipperDetail.status_order.code === 'DELIVERED'"
          >Đã giao lúc:
          <span class="pull-right">{{
            orderShipperDetail.updated_at | showDate
          }}</span></b-card-text
        >
        <b-card-text
          v-if="orderShipperDetail.status_order.code === 'DELIVERING'"
          >Tiền khách đưa:
          <money
            v-model="payDirectly"
            v-bind="money"
            class="pull-right form-control-custom"
          ></money>
        </b-card-text>
        <b-card-text
          v-if="orderShipperDetail.status_order.code === 'DELIVERING'"
          >Tiền trả lại khách:
          <money
            v-model="payReturn"
            v-bind="money"
            class="pull-right form-control-custom"
          ></money>
        </b-card-text>
        <template v-if="orderShipperDetail.status_order.code === 'DELIVERING'">
          <button
            v-b-modal.modal-2
            class="pull-left btn btn-warning btn-custom"
          >
            x
          </button>
          <button
            :class="
              !isCheckPayment
                ? 'btn btn-danger pull-right ml-2'
                : 'btn btn-primary pull-right ml-2'
            "
            :disabled="!isCheckPayment"
            @click="onSuccessOrderPayment(orderShipperDetail.id)"
          >
            Tiền mặt
          </button>
          <button
            @click="onSuccessOrderAccount(orderShipperDetail.id)"
            :disabled="!isCheckAccount"
            :class="
              !isCheckAccount
                ? 'btn btn-danger pull-right'
                : 'btn btn-primary pull-right'
            "
          >
            Tài khoản
          </button>
        </template>
        <template
          v-if="orderShipperDetail.status_order.code === 'PICKING_UP_GOODS'"
        >
          <button class="pull-left btn btn-danger" v-b-modal.modal-4>
            Bỏ Đơn
          </button>
          <button
            class="pull-right btn btn-primary"
            @click="onTookTheGood(orderShipperDetail.id)"
          >
            Đã lấy hàng
          </button>
        </template>
        <template
          v-if="orderShipperDetail.status_order.code === 'TOOK_THE_GOODS'"
        >
          <button class="pull-left btn btn-danger" v-b-modal.modal-3>
            Trả hàng
          </button>
          <button
            class="pull-right btn btn-primary"
            @click="onDeliveryGood(orderShipperDetail.id)"
          >
            Giao hàng
          </button>
        </template>
      </b-card>
      <template v-if="orderShipperDetail.status_order.code === 'DELIVERING'">
        <b-card
          style="margin-top: -56px;
    margin-bottom: 39px;"
        >
          <b-card-text class="text-primary"
            >NVGH chuyển điện thoại cho khách hàng xác nhận tiền
            mặt</b-card-text
          >
        </b-card>
      </template>
    </template>
    <template
      v-else-if="
        !idShipperOrder && orderShipperDetail.status_order.code === 'CONFIRM'
      "
    >
      <ProductList :productListData="productListData" />
      <b-card style="margin-bottom: 50px">
        <b-card-text>
          Mã đơn hàng:
          <span class="pull-right">{{ orderShipperDetail.code }}</span>
        </b-card-text>
        <b-card-text v-if="orderShipperDetail.desired_delivery_time">
          Thời gian muốn nhận hàng:
          <span class="pull-right">{{
            orderShipperDetail.desired_delivery_time | showDate
          }}</span>
        </b-card-text>
        <b-card-text>
          Thành tiền:
          <span class="pull-right">{{
            orderShipperDetail.total_invoice | formatCurrency
          }}</span>
        </b-card-text>
        <b-card-text>
          Phụ phí:
          <span class="pull-right">{{
            orderShipperDetail.additional_fee | formatCurrency
          }}</span>
        </b-card-text>
        <b-card-text
          >Tổng thu:
          <span class="pull-right">{{
            orderShipperDetail.total_payment | formatCurrency
          }}</span></b-card-text
        >
        <!-- button confirm order, no shipper id -->
        <button
          @click="onReceiptOrder(orderShipperDetail.id)"
          class="pull-right btn btn-primary mr-2"
        >
          Nhận
        </button>
      </b-card>
    </template>
    <template v-else>
      <div class="header-nav-tab text-center text-danger">
        Đã có người nhận đơn, vui lòng chọn đơn khác
      </div>
    </template>
    <VueBottomNavigation
      :options="options"
      v-model="selected"
      foregroundColor="#42b983"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";
import VueBottomNavigation from "bottom-navigation-vue";
import ProductList from "../../../src/components/shipper/product-list.vue";
import { addCommaPrice } from "../../config/common";
import { Money } from "v-money";

export default {
  components: { Loader, VueBottomNavigation, ProductList, Money },
  data() {
    return {
      selected: 1,
      options: [
        {
          id: 4,
          icon: "fas fa-bell",
          title: "Thông báo",
          path: { name: "notify-shipper" },
        },
        {
          id: 1,
          icon: "fas fa-home",
          title: "Đơn hàng",
          path: { name: "shipper-order" },
        },
        {
          id: 5,
          icon: "fas fa-user",
          title: "Tài khoản",
          path: { name: "shipper-info" },
        },
        {
          id: 6,
          icon: "fas fa-chart-bar",
          title: "Thống kê",
          path: { name: "shipper-statistic" },
        },
      ],
      payDirectly: 0,
      payReturn: 0,
      money: {
        thousands: ",",
        suffix: "đ",
        precision: 0,
      },
    };
  },
  async created() {
    await this.getOrderStatusList();
    if (!this.userProfile) {
      await this.getUserProfile();
    }
    const orderId = this.$route.query.id;
    await this.getDetailOrderShipper(orderId);
    await this.calculationRemainingPayment();
  },
  computed: {
    ...mapState("order", ["isLoading", "orderShipperDetail"]),
    ...mapState("reports", ["statusOrderList"]),
    ...mapState("profile", ["userProfile"]),
    ...mapState(["isMessage", "errorList"]),
    productListData() {
      return this.orderShipperDetail.products;
    },
    isCheckAccount() {
      return !this.payDirectly || this.payDirectly === 0;
    },
    isCheckPayment() {
      return this.payDirectly;
    },
    idShipperProfile() {
      return this.userProfile?.id;
    },
    idShipperOrder() {
      return this.orderShipperDetail?.shipper?.id;
    },
  },
  watch: {
    payDirectly() {
      this.calculationRemainingPayment();
    },
    isMessage() {
      if (this.isMessage.isShow) {
        this.$toasted.show(`${this.isMessage.content}`, {
          type: this.isMessage.type,
        });
        setTimeout(() => {
          this.setMessage({
            type: "",
            isShow: false,
            content: "",
          });
        }, 4000);
      }
    },
    errorList() {
      if (this?.errorList?.status === 400) {
        const dataError = this.errorList?.data?.message
          ? this?.errorList?.data?.message
          : this?.errorList?.data;
        switch (Object.keys(dataError)[0]) {
          case "old_password":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case "non_field_errors":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case typeof dataError === "string":
            this.$toasted.show(dataError, {
              duration: 4000,
              type: "error",
            });
            break;
          default:
            for (const [key, value] of Object.entries(dataError)) {
              this.$toasted.show(`${value[0]}`, {
                duration: 4000,
                type: "error",
              });
            }
        }
      }
      if (this?.errorList?.status === 403) {
        this.$toasted.show(this?.errorList?.data?.detail, {
          duration: 4000,
          type: "info",
        });
      }
      if (this?.errorList?.status === 500) {
        this.$toasted.show(this?.errorList?.data?.message, {
          duration: 4000,
          type: "error",
        });
      }
    },
  },
  methods: {
    ...mapActions("order", [
      "getDetailOrderShipper",
      "updateOrderShipper",
      "cancelOrderShipper",
    ]),
    ...mapActions("reports", ["getOrderStatusList"]),
    ...mapActions("profile", ["getUserProfile"]),
    ...mapActions(["setMessage"]),
    onBackOrder() {
      this.$router.go(-1);
    },
    getStatusOrderId(code) {
      if (this.statusOrderList) {
        return this.statusOrderList.find((item) => item.code === `${code}`)?.id;
      }
    },
    onReceiptOrder(idOrder) {
      const data = {
        id: idOrder,
        shipper: this.userProfile?.id,
        status_order: this.getStatusOrderId("PICKING_UP_GOODS"),
      };
      this.updateOrderShipper({ data: data });
    },
    onReturnOrder(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("PICKING_UP_GOODS"),
      };
      this.updateOrderShipper({ data: data });
    },
    onPreventOrder(idOrder) {
      const data = {
        shipper: null,
        id: idOrder,
        status_order: this.getStatusOrderId("CONFIRM"),
      };
      this.cancelOrderShipper({ data: data });
    },
    onTookTheGood(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("TOOK_THE_GOODS"),
      };
      this.updateOrderShipper({ data: data });
    },
    onSuccessOrderAccount(idOrder) {
      const data = {
        id: idOrder,
        pay_directly: 0,
        status_order: this.getStatusOrderId("DELIVERED"),
        status_payment: 8,
        payment_method: 2,
      };
      this.updateOrderShipper({ data: data });
    },
    onSuccessOrderPayment(idOrder) {
      let data = {
        id: idOrder,
        status_order: this.getStatusOrderId("DELIVERED"),
        status_payment: 8,
        payment_method: 1,
      };
      if (this.payReturn < 0) {
        data = {
          pay_directly: this.payDirectly,
          ...data,
        };
      } else {
        data = {
          pay_directly: this.payDirectly - this.payReturn,
          ...data,
        };
      }
      this.updateOrderShipper({ data: data });
    },
    onCustomerCancel(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("CUSTOMER_CANCEL"),
      };
      this.cancelOrderShipper({ data: data });
    },
    onDeliveryGood(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("DELIVERING"),
      };
      this.updateOrderShipper({ data: data });
    },
    calculationRemainingPayment() {
      this.payReturn =
        this.payDirectly - this.orderShipperDetail?.total_payment;
    },
  },
};
</script>
<style scoped>
.btn-custom {
  font-size: 11px;
}
input {
  border: 1px solid rgb(134, 132, 132);
  border-radius: 3px;
  color: #333;
  font-size: 14px;
}
.header-nav-tab {
  padding-top: 60px;
}
</style>
