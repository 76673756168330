import homepageImageService from "../../services/homepage-image";

const state = {
  homepageImageList: [],
  isLoading: false,
};
const getters = {};
const actions = {
  async getHomepageImageList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await homepageImageService.getAll(params);
      commit("SET_LIST_HOMEPAGE_IMAGE", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getHomepageImageDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await homepageImageService.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createHomepageImage({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await homepageImageService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm ảnh thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateHomepageImage({ commit, dispatch }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await homepageImageService.update(payload);
      await dispatch("getHomepageImageList");
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deleteHomepageImage({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      commit("SET_LOADING", true);
      const response = await homepageImageService.delete(id);
      await dispatch("getHomepageImageList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá ảnh thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_HOMEPAGE_IMAGE(state, payload) {
    state.homepageImageList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
