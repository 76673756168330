<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Quản lý thương hiệu" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật thương hiệu
              </h5>
              <h5 v-else>
                Thêm thương hiệu
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="brandInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <validation-provider
                        name="Tên thương hiệu"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên thương hiệu
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="brandInfo.name"
                            placeholder="Nhập Tên thương hiệu"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thông tin chi tiết"
                        :rules="{}"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label="Thông tin chi tiết"
                        >
                          <b-form-textarea
                            id="textarea-rows"
                            rows="8"
                            v-model="brandInfo.description"
                            placeholder="Thông tin chi tiết"
                            :state="getValidationState(validationContext)"
                          ></b-form-textarea>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      class="btn btn-danger mr-3"
                      type="button"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  name: "",
  description: "",
};

export default {
  data() {
    const brandInfo = this.$route.query.id ? this.brandInfo : initialData;
    return {
      brandInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.brandInfo = await this.getBrandDetail(this.$route.query.id);
    } else {
      this.brandInfo = {
        ...initialData,
      };
    }
  },
  computed: {
    ...mapState("brand", ["isLoading"]),
  },
  watch: {},
  methods: {
    ...mapActions("brand", ["createBrand", "updateBrand", "getBrandDetail"]),
    onSubmit() {
      if (this.brandInfo.id) {
        const brandData = { id: this.brandInfo.id, data: this.brandInfo };
        this.updateBrand(brandData);
      } else {
        this.createBrand(this.brandInfo);
      }
    },
    cancelUpdate() {
      this.brandInfo = {
        ...initialData,
      };
    },
    handleBackList() {
      this.$router.push({ name: "brand-list" });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
};
</script>
<style scoped></style>
