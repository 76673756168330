export const OrderStatus = {
  newOrder: "NEW_ORDER",
  conFirm: "CONFIRM",
  pickingUpGood: "PICKING_UP_GOODS",
  tookTheGood: "TOOK_THE_GOODS",
  delivering: "DELIVERING",
  delivered: "DELIVERED",
  success: "SUCCESS",
  customerCancel: "CUSTOMER_CANCEL",
  cancel: "CANCEL",
};
