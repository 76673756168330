import profileServices from "../../services/profile";

const state = {
  userProfile: null,
  isLoading: false,
};
const getters = {};
const actions = {
  async getUserProfile({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await profileServices.getProfile();
      commit("SET_USER_PROFILE", response.data);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async changeUserProfile({ commit, dispatch }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await profileServices.changeProfile(payload);
      await dispatch("getUserProfile");
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thông tin thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, {
        root: true,
      });
    }
  },

  async changePassword({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await profileServices.changePassword(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật mật khẩu thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, {
        root: true,
      });
    }
  },
};
const mutations = {
  SET_USER_PROFILE(state, payload) {
    state.userProfile = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
