<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách nhân viên" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật nhân viên
              </h5>
              <h5 v-else>
                Thêm nhân viên
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <form
                  class="needs-validation employee-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="employeeInfo"
                >
                  <div class="row">
                    <div class="col-sm-6">
                      <validation-provider v-if="checkDataInfo">
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Mã nhân viên
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="employeeInfo.code"
                            :disabled="checkDataInfo"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên đăng nhập"
                        :rules="{
                          required: true,
                          regexUserName: /^[a-z0-9@._-]{3,50}$/,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên đăng nhập
                          </template>
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="employeeInfo.username"
                            placeholder="Nhập tên đăng nhập"
                            :state="getValidationState(validationContext)"
                            :disabled="checkDataInfo"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Mật khẩu"
                        :rules="{
                          required: checkDataInfo ? false : true,
                          min: 8,
                          regexPassword: /[A-Z]/i,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          v-if="!checkDataInfo"
                          id="example-input-group-2"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            <span>*</span>Mật khẩu
                          </template>
                          <b-form-input
                            id="example-input-2"
                            name="example-input-2"
                            placeholder="******"
                            type="password"
                            v-model="employeeInfo.password"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-2-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-2-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Email"
                        :rules="{
                          required: checkDataInfo ? false : true,
                          email: true,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <template v-slot:label><span>*</span>Email </template>
                          <b-form-input
                            id="example-input-5"
                            name="example-input-5"
                            v-model="employeeInfo.email"
                            placeholder="Nhập email"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-5-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Tên nhân viên"
                        :rules="{ max: 255, required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <template v-slot:label>
                            <span>*</span>Tên nhân viên
                          </template>
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="employeeInfo.name"
                            placeholder="Nhập tên nhân viên"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Số điện thoại"
                        :rules="{ regexPhone: /^[0-9]+$/, min: 10, max: 11 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Số điện thoại"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="employeeInfo.phone_number"
                            placeholder="Nhập số điện thoại"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Số điện thoại"
                        :rules="{ regexPhone: /^[0-9]+$/, min: 10, max: 11 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Số điện thoại người thân"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="employeeInfo.relative_phone_number"
                            placeholder="Nhập số điện thoại người thân"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Ngày bắt đầu làm việc"
                        v-slot="validationContext"
                        :rules="{}"
                      >
                        <b-form-group
                          label="Ngày bắt đầu làm việc"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                        >
                          <b-form-datepicker
                            placeholder="Ngày bắt đầu làm việc"
                            locale="vi"
                            label-help=""
                            v-model="employeeInfo.date_joined"
                            :date-format-options="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            }"
                            :state="getValidationState(validationContext)"
                          ></b-form-datepicker>

                          <b-form-invalid-feedback>{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>
                    <div class="col-sm-6">
                      <validation-provider
                        name="Vai trò"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <template v-slot:label>
                            <span>*</span>Vai trò
                          </template>
                          <multiselect
                            placeholder="Chọn vai trò"
                            v-model="employeeInfo.groups"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :show-labels="false"
                            :options="roleActive"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Kho chỉ định"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="exampleInputGroup3"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="exampleInput3"
                        >
                          <template v-slot:label>
                            <span>*</span> Kho chỉ định
                          </template>
                          <multiselect
                            placeholder="Kho chỉ định"
                            v-model="employeeInfo.warehouse"
                            class="rbt-custom-input"
                            :custom-label="searchingSelect"
                            :show-labels="false"
                            :options="warehouseList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="inputLiveFeedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Địa chỉ"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-3"
                          label="Địa chỉ"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-3"
                        >
                          <b-form-input
                            id="example-input-3"
                            name="example-input-3"
                            v-model="employeeInfo.address"
                            placeholder="Nhập địa chỉ"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Thành phố"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-4"
                          label="Thành phố"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-4"
                        >
                          <multiselect
                            placeholder="Chọn thành phố"
                            v-model="employeeInfo.city"
                            :custom-label="searchingSelect"
                            class="rbt-custom-input"
                            @input="onChangeCity"
                            :show-labels="false"
                            :options="cityList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-4-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Quận huyện"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-5"
                          label="Quận huyện"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-5"
                        >
                          <multiselect
                            placeholder="Chọn quận huyện"
                            v-model="employeeInfo.district"
                            :show-labels="false"
                            :custom-label="searchingSelect"
                            :options="districtList"
                            class="rbt-custom-input"
                            :disabled="!employeeInfo.city"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>
                          <b-form-invalid-feedback id="input-5-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        :rules="{ max: 255 }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-6"
                          label="Số CMT"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-input
                            id="example-input-6"
                            name="example-input-6"
                            v-model="employeeInfo.indentity_card_number"
                            placeholder="Số chứng minh thư"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-6-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-6-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-6"
                          label="Trạng thái"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-6"
                        >
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="employeeInfo.is_active"
                            name="checkbox-1"
                          >
                            <label>
                              <p v-if="employeeInfo.is_active">
                                Đang làm việc
                              </p>
                              <p v-else>
                                Chưa kích hoạt
                              </p>
                            </label>
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

const initialData = {
  username: "",
  password: "",
  name: "",
  phone_number: null,
  groups: "",
  indentity_card_number: null,
  address: "",
  district: "",
  city: "",
  warehouse: null,
  relative_phone_number: null,
  date_joined: new Date().toISOString().slice(0, 10),
  is_active: true,
};

export default {
  data() {
    const employeeInfo = this.$route.query.id ? this.employeeInfo : initialData;
    return {
      employeeInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.employeeInfo = await this.getEmployeeDetail(this.$route.query.id);
    } else {
      this.employeeInfo = {
        ...initialData,
      };
    }
    this.getWarehouseList();
    this.getRoleList();
    this.getCityList();
    const { city } = this.employeeInfo;
    const idCity = city?.id;
    if (idCity) {
      this.getDistrictList({ id: idCity });
    }
  },
  computed: {
    ...mapState("employee", ["isLoading"]),
    ...mapState("role", ["roleList"]),
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("city", ["cityList", "districtList"]),
    checkDataInfo() {
      if (this.$route.query.id) return true;
      else return false;
    },
    roleActive() {
      return this.roleList?.filter((item) => {
        return item.is_employee;
      });
    },
  },
  watch: {},
  methods: {
    ...mapActions("employee", [
      "createEmployee",
      "updateEmployee",
      "getEmployeeDetail",
    ]),
    ...mapActions("role", ["getRoleList"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions("city", ["getCityList", "getDistrictList"]),
    async onSubmit() {
      const {
        groups,
        avatar_url,
        city,
        district,
        warehouse,
        ...data
      } = this.employeeInfo;
      const groupConvert = [];
      groupConvert.push(Array.isArray(groups) ? groups[0]?.id : groups?.id);
      const dataEmployeeUpdate = {
        groups: groupConvert,
        city: city?.id,
        district: district?.id,
        warehouse: warehouse?.id,
        ...data,
      };
      if (this.employeeInfo.id) {
        const idEmployee = this.employeeInfo.id;
        const employeeData = { id: idEmployee, data: dataEmployeeUpdate };
        await this.updateEmployee(employeeData);
        this.employeeInfo = await this.getEmployeeDetail(this.employeeInfo.id);
      } else {
        this.createEmployee(dataEmployeeUpdate);
      }
    },
    cancelUpdate() {
      this.employeeInfo = {
        ...initialData,
      };
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    searchingSelect({ name }) {
      return name;
    },
    onChangeCity() {
      const { city } = this.employeeInfo;
      this.getDistrictList({ id: city?.id });
      this.employeeInfo.district = "";
    },
    handleBackList() {
      this.$router.push({ name: "employee-list" });
    },
  },
};
</script>
<style scoped></style>
