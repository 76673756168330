import orderTypeServices from "../../services/order-type";

const state = {
  orderTypeList: [],
  isLoading: false,
};
const getters = {};
const actions = {
  async getOrderTypeList({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await orderTypeServices.getAll();
      commit("SET_LIST_ORDER_TYPE", response.data);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },
};
const mutations = {
  SET_LIST_ORDER_TYPE(state, payload) {
    state.orderTypeList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
