<template>
  <div>
    <loader :is-visible="isLoadingImport"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách nhập kho" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordImport }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-9 d-flex justify-content-center align-items-center"
                >
                  <date-picker
                    class="col-sm-5"
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Chọn ngày để xem"
                    @change="onSearch"
                  ></date-picker>
                  <div class="col-sm-7 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important"
                    >
                      <b-form-input
                        type="text"
                        v-model="search"
                        placeholder="Tìm kiếm"
                        @change="onSearch()"
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModalImport()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Nhập kho</b-button
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoadingImport && warehouseImportList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="warehouseImportList"
                  :fields="tablefields"
                >
                  <template #empty>
                    <span>
                      Không có dữ liệu
                    </span>
                  </template>
                  <template v-slot:cell(status)="{ item }">
                    <b-badge :variant="colorStatus[item.status]">
                      {{ statusCheckWarehouse[item.status] }}
                    </b-badge>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <span v-if="item.created_at">
                      {{ item.created_at | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordImport > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordImport"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <WarehouseImport
                :warehouseImportInfo="warehouseImportInfo"
                @onSubmit="onSubmitImport"
                @onUpdate="onUpdateImport"
                @onApproved="onApproved"
                @onCancelWarehouseImport="onCancelWarehouseImport"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import WarehouseImport from "../../components/modals/WarehouseImport.vue";
import { statusCheckWarehouseText } from "../../config/status-default";
import { convertDate } from "../../config/common";

const colorBadgeStatus = {
  0: "warning",
  1: "success",
  2: "primary",
  3: "danger",
};

export default {
  components: {
    WarehouseImport,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã phiếu nhập" },
        { key: "name", label: "Tên phiếu nhập" },
        { key: "created_at", label: "Thời gian nhập" },
        { key: "note", label: "Ghi chú" },
        { key: "status", label: "Trạng thái" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isName: "",
      warehouseImportInfo: {},
      date: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getWarehouseImportList(params);
    this.getOrderSupplier(params);
  },
  computed: {
    ...mapState("warehouse", [
      "warehouseList",
      "totalRecordImport",
      "isLoadingImport",
      "warehouseImportList",
      "totalRecordImport",
    ]),
    colorStatus() {
      return colorBadgeStatus;
    },
    statusCheckWarehouse() {
      return statusCheckWarehouseText;
    },
  },
  watch: {},
  methods: {
    ...mapActions("warehouse", [
      "getWarehouseImportList",
      "onWarehouseImport",
      "onUpdateWarehouseImport",
      "onApprovedWarehouseImport",
      "onWarehouseImportCancel",
      "warehouseImportDetail",
    ]),
    ...mapActions("inventory", ["getInventoryList"]),
    ...mapActions("supplier", ["getOrderSupplier"]),
    onCancelWarehouseImport(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onWarehouseImportCancel({ data: payload, params });
      (this.search = ""), (this.date = "");
    },
    onApproved(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onApprovedWarehouseImport({ data: payload, params });
      (this.search = ""), (this.date = "");
    },
    onUpdateImport(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onUpdateWarehouseImport({ data: payload, params });
      (this.search = ""), (this.date = "");
    },
    onSubmitImport(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onWarehouseImport({ data: payload, params });
      (this.search = ""), (this.date = "");
    },
    openModalImport() {
      this.warehouseImportInfo = {};
      this.$bvModal.show("modal-import-warehouse");
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.name;
    },
    async onEdit(item) {
      const { id, warehouse_export } = item;
      this.warehouseImportInfo = await this.warehouseImportDetail(id);
      if (warehouse_export?.id) {
        this.getInventoryList({ id_warehouse: warehouse_export?.id });
      } else {
        this.getInventoryList();
      }
      this.$bvModal.show("modal-import-warehouse");
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.search) {
        params = { query: this.search, ...params };
      }
      this.getWarehouseImportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/warehouse/import",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      this.getWarehouseImportList(params);
      this.$router.push({
        path: "/warehouse/import",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>
