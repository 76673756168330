<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="page-wrapper">
      <div>
        <b-navbar fixed="top" type="dark" style="background-color: #42b983">
          <b-nav-text class="justify-content-end header-nav-shipper"
            ><b>ĐƠN HÀNG</b></b-nav-text
          >
          <b-form-select v-model="idWarehouse" @change="onSelectWarehouse">
            <option value="">Tất cả kho hàng</option>
            <option
              v-for="warehouse in warehouseList"
              :key="warehouse.index"
              :value="warehouse.id"
              >{{ warehouse.name }}</option
            >
          </b-form-select>
        </b-navbar>
      </div>
      <b-tabs class="rbt-tabs-box-shipper" fill>
        <b-tab
          title="Đơn mới"
          @click="onTabNew()"
          :active="$route.query.id == getStatusOrderId('CONFIRM')"
        >
          <OrderComponent
            :orderShipperList="orderShipperList"
            @onReceiptOrder="onReceiptOrder"
        /></b-tab>
        <b-tab
          title="Đơn nhận"
          @click="onTabPicked()"
          :active="$route.query.id == getStatusOrderId('PICKING_UP_GOODS')"
          ><OrderComponent
            :orderShipperList="orderShipperList"
            @onTookTheGood="onTookTheGood"
            @onCancelOrder="onCancelOrder"
        /></b-tab>
        <b-tab
          title="Chờ giao"
          @click="onTabNeedDelivery()"
          :active="$route.query.id == getStatusOrderId('TOOK_THE_GOODS')"
          ><OrderComponent
            :orderShipperList="orderShipperList"
            @onDelivering="onDelivering"
            @onCancelOrder="onCancelOrder"
        /></b-tab>
        <b-tab
          title="Đơn giao"
          @click="onTabDelivering()"
          :active="$route.query.id == getStatusOrderId('DELIVERING')"
          ><OrderComponent :orderShipperList="orderShipperList"
        /></b-tab>
        <b-tab
          title="Đã giao"
          @click="onTabSuccess()"
          :active="$route.query.id == getStatusOrderId('DELIVERED')"
          ><OrderComponent :orderShipperList="orderShipperList"
        /></b-tab>
      </b-tabs>
    </div>
    <VueBottomNavigation
      :options="options"
      v-model="selected"
      foregroundColor="#42b983"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import VueBottomNavigation from "bottom-navigation-vue";
import OrderComponent from "../../../src/components/shipper/order.vue";
import Loader from "../../components/loading/loader.vue";
import LocalStorageService from "../../utils/index";

export default {
  components: { VueBottomNavigation, OrderComponent, Loader },

  data() {
    return {
      selected: 1,
      options: [
        {
          id: 4,
          icon: "fas fa-bell",
          title: "Thông báo",
          path: { name: "notify-shipper" },
        },
        {
          id: 1,
          icon: "fas fa-home",
          title: "Đơn hàng",
          path: { name: "shipper-order" },
          badge: "",
        },
        {
          id: 5,
          icon: "fas fa-user",
          title: "Tài khoản",
          path: { name: "shipper-info" },
        },
        {
          id: 6,
          icon: "fas fa-chart-bar",
          title: "Thống kê",
          path: { name: "shipper-statistic" },
        },
      ],
      idWarehouse: "",
    };
  },
  async created() {
    await this.getOrderStatusList();
    await this.getUserProfile();
    const idStatus = this.$route.query.id || this.getStatusOrderId("CONFIRM");
    this.idWarehouse = this.$route.query.warehouse_id || "";
    let params = {
      status_order: idStatus,
    };
    if (Number(idStatus) === this.getStatusOrderId("CONFIRM")) {
      LocalStorageService.saveLocalStatusOrder(idStatus);
    }
    if (Number(idStatus) !== this.getStatusOrderId("CONFIRM")) {
      params = {
        shipper: this.userProfile?.id,
        ...params,
      };
    }
    if (this.$route.query.warehouse_id) {
      params = {
        store: this.idWarehouse,
        ...params,
      };
    }
    await this.getOrderShipperList(params);
    if (this.$route.query.id !== this.getStatusOrderId("CONFIRM")) {
      this.$router.push({
        name: "shipper-order",
        query: {
          id: this.getStatusOrderId("CONFIRM"),
        },
      });
    }
    this.options[1].badge = this.totalRecord;
    this.getWarehouseList();
  },
  watch: {
    isMessage() {
      if (this.isMessage.isShow) {
        this.$toasted.show(`${this.isMessage.content}`, {
          type: this.isMessage.type,
        });
        setTimeout(() => {
          this.setMessage({
            type: "",
            isShow: false,
            content: "",
          });
        }, 4000);
      }
    },
    errorList() {
      if (this?.errorList?.status === 400) {
        const dataError = this.errorList?.data?.message
          ? this?.errorList?.data?.message
          : this?.errorList?.data;
        switch (Object.keys(dataError)[0]) {
          case "old_password":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case "non_field_errors":
            this.$toasted.show(dataError[Object.keys(dataError)], {
              duration: 4000,
              type: "error",
            });
            break;
          case typeof dataError === "string":
            this.$toasted.show(dataError, {
              duration: 4000,
              type: "error",
            });
            break;
          default:
            for (const [key, value] of Object.entries(dataError)) {
              this.$toasted.show(`${value[0]}`, {
                duration: 4000,
                type: "error",
              });
            }
        }
      }
      if (this?.errorList?.status === 403) {
        this.$toasted.show(this?.errorList?.data?.detail, {
          duration: 4000,
          type: "info",
        });
      }
      if (this?.errorList?.status === 500) {
        this.$toasted.show(this?.errorList?.data?.message, {
          duration: 4000,
          type: "error",
        });
      }
    },
    totalRecord() {
      this.options[1].badge = this.totalRecord;
    },
  },
  computed: {
    ...mapState("order", ["orderShipperList", "isLoading", "totalRecord"]),
    ...mapState("reports", ["statusOrderList"]),
    ...mapState("profile", ["userProfile"]),
    ...mapState(["isMessage", "errorList"]),
    ...mapState("warehouse", ["warehouseList"]),
  },
  methods: {
    ...mapActions("order", [
      "getOrderShipperList",
      "updateOrderShipper",
      "cancelOrderShipper",
    ]),
    ...mapActions("reports", ["getOrderStatusList"]),
    ...mapActions("profile", ["getUserProfile"]),
    ...mapActions("warehouse", ["getWarehouseList"]),
    ...mapActions(["setMessage"]),
    getStatusOrderId(code) {
      if (this.statusOrderList) {
        return this.statusOrderList.find((item) => item.code === `${code}`)?.id;
      }
    },
    onTabPicked() {
      let params = {
        status_order: this.getStatusOrderId("PICKING_UP_GOODS"),
        shipper: this.userProfile?.id,
      };
      if (this.idWarehouse) {
        params = {
          store: this.idWarehouse,
          ...params,
        };
      }
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.getStatusOrderId("PICKING_UP_GOODS")) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.getStatusOrderId("PICKING_UP_GOODS"),
            warehouse_id: this.idWarehouse,
          },
        });
      }
    },
    onTabNew() {
      let params = {
        status_order: this.getStatusOrderId("CONFIRM"),
      };
      if (this.idWarehouse) {
        params = {
          store: this.idWarehouse,
          ...params,
        };
      }
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.getStatusOrderId("CONFIRM")) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.getStatusOrderId("CONFIRM"),
            warehouse_id: this.idWarehouse,
          },
        });
      }
    },
    onTabNeedDelivery() {
      let params = {
        status_order: this.getStatusOrderId("TOOK_THE_GOODS"),
        shipper: this.userProfile?.id,
      };
      if (this.idWarehouse) {
        params = {
          store: this.idWarehouse,
          ...params,
        };
      }
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.getStatusOrderId("TOOK_THE_GOODS")) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.getStatusOrderId("TOOK_THE_GOODS"),
            warehouse_id: this.idWarehouse,
          },
        });
      }
    },
    onTabDelivering() {
      let params = {
        status_order: this.getStatusOrderId("DELIVERING"),
        shipper: this.userProfile?.id,
      };
      if (this.idWarehouse) {
        params = {
          store: this.idWarehouse,
          ...params,
        };
      }
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.getStatusOrderId("DELIVERING")) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.getStatusOrderId("DELIVERING"),
            warehouse_id: this.idWarehouse,
          },
        });
      }
    },
    onTabSuccess() {
      let params = {
        status_order: this.getStatusOrderId("DELIVERED"),
        shipper: this.userProfile?.id,
      };
      if (this.idWarehouse) {
        params = {
          store: this.idWarehouse,
          ...params,
        };
      }
      this.getOrderShipperList(params);
      if (this.$route.query.id !== this.getStatusOrderId("DELIVERED")) {
        this.$router.push({
          name: "shipper-order",
          query: {
            id: this.getStatusOrderId("DELIVERED"),
            warehouse_id: this.idWarehouse,
          },
        });
      }
    },
    onReceiptOrder(idOrder) {
      const data = {
        id: idOrder,
        shipper: this.userProfile?.id,
        status_order: this.getStatusOrderId("PICKING_UP_GOODS"),
      };
      const params = {
        status_order: this.getStatusOrderId("CONFIRM"),
      };
      this.updateOrderShipper({ data: data, params });
    },
    onTookTheGood(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("TOOK_THE_GOODS"),
      };
      const params = {
        shipper: this.userProfile?.id,
        status_order: this.getStatusOrderId("PICKING_UP_GOODS"),
      };
      this.updateOrderShipper({ data: data, params });
    },
    onDelivering(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("DELIVERING"),
      };
      const params = {
        shipper: this.userProfile?.id,
        status_order: this.getStatusOrderId("TOOK_THE_GOODS"),
      };
      this.updateOrderShipper({ data: data, params });
    },
    onCancelOrder(idOrder) {
      const data = {
        id: idOrder,
        status_order: this.getStatusOrderId("CANCEL"),
      };
      const params = {
        shipper: this.userProfile?.id,
        status_order: this.getStatusOrderId("PICKING_UP_GOODS"),
      };
      this.cancelOrderShipper({ data: data, params });
    },
    onSelectWarehouse() {
      const idStatus = this.$route.query.id || this.getStatusOrderId("CONFIRM");
      let params = {
        status_order: idStatus,
        store: this.idWarehouse,
      };
      if (idStatus == this.getStatusOrderId("CONFIRM")) {
        this.getOrderShipperList(params);
        this.$router.push({
          name: "shipper-order",
          query: { id: idStatus, warehouse_id: this.idWarehouse },
        });
      } else {
        params = {
          shipper: this.userProfile?.id,
          ...params,
        };
        this.getOrderShipperList(params);
        this.$router.push({
          name: "shipper-order",
          query: { id: idStatus, warehouse_id: this.idWarehouse },
        });
      }
    },
  },
};
</script>
<style>
.rbt-tabs-box-shipper > div:first-of-type {
  position: fixed;
  z-index: 1030;
  top: 53px;
  background-color: #fff;
  overflow: hidden;
  width: 100%;
  flex-direction: row;
  font-size: 17px;
}
.active-label .svg-inline--fa {
  font-size: 26px !important;
}
.nav {
  flex-wrap: nowrap !important;
}
.nav-tabs {
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: auto;
}
::-webkit-scrollbar {
  display: none;
}
p {
  font-size: 16px !important;
}
.active-label .btn-badge {
  background-color: red !important;
}
.navbar .custom-select {
  width: 50% !important;
}
.navbar-expand {
  justify-content: space-between !important;
}
</style>
