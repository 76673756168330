import axiosInstance from "./axios";
import { apiRoutesEnum } from "../enums/routes";

class WarehouseService {
  getAll(params) {
    return axiosInstance.get(`${apiRoutesEnum.warehouse}`, { params: params });
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.warehouse}/${id}`);
  }

  addCheckWarehouse(payload) {
    return axiosInstance.post(`${apiRoutesEnum.checkWarehouse}`, payload);
  }

  detailCheckWarehouse(id) {
    return axiosInstance.get(`${apiRoutesEnum.checkWarehouse}/${id}`);
  }

  updateCheckWarehouse(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.checkWarehouse}/${id}`, {
      ...data,
    });
  }

  inventorySheetList(params) {
    return axiosInstance.get(`${apiRoutesEnum.checkWarehouse}`, {
      params: params,
    });
  }

  create(payload) {
    return axiosInstance.post(`${apiRoutesEnum.warehouse}`, payload);
  }

  warehouseImportList(params) {
    return axiosInstance.get(`${apiRoutesEnum.warehousesReceipt}`, {
      params: params,
    });
  }

  warehouseImport(payload) {
    return axiosInstance.post(`${apiRoutesEnum.warehousesReceipt}`, payload);
  }

  warehouseImportDetail(id) {
    return axiosInstance.get(`${apiRoutesEnum.warehousesReceipt}/${id}`);
  }

  warehouseImportUpdate(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.warehousesReceipt}/${id}`, {
      ...data,
    });
  }

  warehouseImportCancel(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.warehousesReceipt}/${id}`, {
      ...data,
    });
  }

  warehouseImportApproved(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.warehousesReceipt}/${id}`, {
      ...data,
    });
  }

  update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.warehouse}/${id}`, data);
  }

  delete(id) {
    return axiosInstance.delete(`${apiRoutesEnum.warehouse}/${id}`);
  }
}

export default new WarehouseService();
