import brandServices from "../../services/brand";

const state = {
  brandList: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getBrandList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await brandServices.getAll(params);
      const { results, count } = response.data.data;
      commit("SET_LIST_BRAND", results);
      commit("SET_TOTAL_RECORD", count);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getBrandDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await brandServices.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createBrand({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await brandServices.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm thương hiệu thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateBrand({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await brandServices.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thương hiệu thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deleteBrand({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await brandServices.delete(id);
      await dispatch("getBrandList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thương hiệu thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_BRAND(state, payload) {
    state.brandList = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
