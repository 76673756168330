<template>
  <div>
    <div class="row">
      <div class="col-xl-6 col-md-6 xl-50">
        <div class="card o-hidden widget-cards">
          <div class="bg-warning card-body">
            <div class="media static-top-widget row">
              <div class="icons-widgets col-3">
                <div class="align-self-center text-center">
                  <feather type="navigation" class="font-warning"></feather>
                </div>
              </div>
              <div class="media-body col-9">
                <span class="m-0">Doanh thu</span>
                <h3 class="mb-0">
                  <span class="counter">{{
                    revenueTotal | formatCurrency
                  }}</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-6 xl-50">
        <div class="card o-hidden  widget-cards">
          <div class="bg-secondary card-body">
            <div class="media static-top-widget row">
              <div class="icons-widgets col-3">
                <div class="align-self-center text-center">
                  <feather type="box" class="font-secondary"></feather>
                </div>
              </div>
              <div class="media-body col-9">
                <span class="m-0">Hoá đơn</span>
                <h3 class="mb-0">
                  <span class="counter">{{ numInvoice }}</span> Hoá đơn
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 col-md-6 xl-50">
        <div class="card o-hidden widget-cards">
          <div class="bg-primary card-body">
            <div class="media static-top-widget row">
              <div class="icons-widgets col-3">
                <div class="align-self-center text-center">
                  <feather type="message-square" class="font-primary"></feather>
                </div>
              </div>
              <div class="media-body col-9">
                <span class="m-0">Đơn hàng chờ duyệt</span>
                <h3 class="mb-0">
                  <span class="counter">{{ numIncompleteOrder }}</span> Đơn hàng
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-md-6 xl-50">
        <div class="card o-hidden widget-cards">
          <div class="bg-danger card-body">
            <div class="media static-top-widget row">
              <div class="icons-widgets col-3">
                <div class="align-self-center text-center">
                  <feather type="users" class="font-danger"></feather>
                </div>
              </div>
              <div class="media-body col-9">
                <span class="m-0">Đơn hàng online</span>
                <h3 class="mb-0">
                  <span class="counter">{{ numShipOrder }}</span> Đơn hàng
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "widget",

  created() {
    this.getDashboardList();
  },
  computed: {
    ...mapState("dashboard", [
      "numShipOrder",
      "revenueTotal",
      "numIncompleteOrder",
      "numInvoice",
    ]),
  },
  methods: {
    ...mapActions("dashboard", ["getDashboardList"]),
  },
};
</script>

<style></style>
