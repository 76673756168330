<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Đơn hàng" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-3">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <div class="col-md-3">
                  <b-form-select
                    v-model="statusOrderParam"
                    @change="onSearch()"
                  >
                    <option value="">Trạng thái đơn hàng</option>
                    <option
                      v-for="status in statusOrderList"
                      :key="status.index"
                      :value="status.id"
                      >{{ status.name }}</option
                    >
                  </b-form-select>
                </div>
                <div class="col-md-3">
                  <b-form-select v-model="type" @change="onSearch()">
                    <option value="">Loại đơn hàng</option>
                    <option
                      v-for="orderType in orderTypeList"
                      :key="orderType.index"
                      :value="orderType.id"
                      >{{ orderType.name }}</option
                    >
                  </b-form-select>
                </div>
                <div class="col-md-3">
                  <b-button
                    class="btn-popup"
                    variant="primary"
                    @click="onSearch()"
                    >Tìm</b-button
                  >
                  <router-link to="/sales/order">
                    <b-button class="btn-popup pull-right"
                      >Đặt hàng</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoading && orderList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="orderList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="item.name">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(type)="{ item }">
                    <span v-if="item.type && item.type.name == 'online'">
                      <div class="text-info">
                        {{ item.type.name }}
                      </div>
                    </span>
                    <span v-if="item.type && item.type.name == 'offline'">
                      {{ item.type.name }}
                    </span>
                  </template>
                  <template v-slot:cell(customer)="{ item }">
                    <span v-if="item.customer">
                      {{
                        item.customer.name ||
                          item.customer.phone_number ||
                          item.customer.email
                      }}
                    </span>
                    <span v-else>
                      Khách lẻ
                    </span>
                  </template>
                  <template v-slot:cell(total_invoice)="{ item }">
                    <span>
                      {{ item.total_invoice | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(total_payment)="{ item }">
                    <span>
                      {{ item.total_payment | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(additional_fee)="{ item }">
                    <span>
                      {{ item.additional_fee | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(status_order)="{ item }">
                    <b-badge :variant="colorStatus[item.status_order.code]">
                      {{ statusOrder[item.status_order.code] }}
                    </b-badge>
                  </template>
                  <template v-slot:cell(order_date)="{ item }">
                    <span>
                      {{ item.order_date | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && orderList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { OrderStatus } from "../../types/orderStatus";
import { statusOrderText } from "../../config/status-default";
import { Roles } from "../../types/roles";

const colorBadgeStatus = {
  NEW_ORDER: "primary",
  CONFIRM: "secondary",
  PICKING_UP_GOODS: "warning",
  TOOK_THE_GOODS: "info",
  DELIVERING: "DELIVERING",
  DELIVERED: "warning",
  SUCCESS: "success",
  CUSTOMER_CANCEL: "danger",
  CANCEL: "danger",
};

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã đơn hàng" },
        { key: "name", label: "Tên đơn hàng" },
        { key: "order_date", label: "Thời gian đặt" },
        { key: "total_invoice", label: "Tổng tiền hàng" },
        { key: "additional_fee", label: "Phụ phí" },
        { key: "total_payment", label: "Tổng tiền thanh toán" },
        { key: "status_order", label: "Trạng thái đơn hàng" },
        { key: "customer", label: "Khách hàng" },
        { key: "type", label: "Đơn hàng" },
        { key: "actions", label: "Hành động" },
      ],
      isId: "",
      isName: "",
      search: "",
      pageNumber: 1,
      type: "",
      statusOrderParam: "",
      pageSize: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    if (this.checkRoles("cskh")) {
      params["warehouse_isnull"] = true;
    } else if (this.checkRoles("employee")) {
      params["store"] = this.userProfile?.warehouse
        ? this.userProfile.warehouse.id
        : null;
    }
    this.getOrderList(params);
    this.getOrderTypeList();
    this.getOrderStatusList();
  },
  computed: {
    ...mapState("order", ["orderList", "isLoading", "totalRecord"]),
    ...mapState("orderType", ["orderTypeList"]),
    ...mapState("reports", ["statusOrderList"]),
    ...mapState("profile", ["userProfile"]),
    roleList() {
      return Roles;
    },
    statusOrder() {
      return statusOrderText;
    },
    colorStatus() {
      return colorBadgeStatus;
    },
  },
  watch: {
    userProfile() {
      if (this.userProfile) {
        console.log("okok");
        if (this.checkRoles("cskh")) {
          const params = {
            page: this.pageNumber,
            page_size: this.pageSize,
            warehouse_isnull: true,
          };
          this.getOrderList(params);
        } else if (this.checkRoles("employee")) {
          const params = {
            page: this.pageNumber,
            page_size: this.pageSize,
            store: this.userProfile?.warehouse
              ? this.userProfile.warehouse.id
              : null,
          };
          this.getOrderList(params);
        }
      }
    },
  },
  methods: {
    ...mapActions("order", ["getOrderList"]),
    ...mapActions("orderType", ["getOrderTypeList"]),
    ...mapActions("reports", ["getOrderStatusList"]),
    checkRoles(role) {
      return this.userProfile?.groups[0].name === this.roleList[role];
    },
    onEdit(item) {
      const { code } = item?.status_order;
      if (code === OrderStatus.newOrder) {
        if (this.checkRoles("cskh") && item.store) {
          this.$router.push({
            name: "update-order-online",
            query: { id: item.id },
          });
          return;
        } else if (this.checkRoles("employee") && !item.store) {
          this.$router.push({
            name: "update-order-online",
            query: { id: item.id },
          });
          return;
        }
        this.$router.push({
          name: "order",
          query: { id: item.id },
        });
      } else if (code && code !== OrderStatus.newOrder) {
        this.$router.push({
          name: "update-order-online",
          query: { id: item.id },
        });
      } else {
        this.$router.push({
          name: "update-order-online",
          query: { id: item.id },
        });
      }
    },
    onSearch() {
      let params = {
        search: this.search.trim(),
        page: 1,
        page_size: this.pageSize,
      };
      if (this.checkRoles("cskh")) {
        params["warehouse_isnull"] = true;
      } else if (this.checkRoles("employee")) {
        params["store"] = this.userProfile?.warehouse
          ? this.userProfile.warehouse.id
          : null;
      }
      if (this.type) {
        params = {
          type: this.type,
          ...params,
        };
      }
      if (this.statusOrderParam) {
        params = {
          status_order: this.statusOrderParam,
          ...params,
        };
      }
      this.getOrderList(params);
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.checkRoles("cskh")) {
        params["warehouse_isnull"] = true;
      } else if (this.checkRoles("employee")) {
        params["store"] = this.userProfile?.warehouse
          ? this.userProfile.warehouse.id
          : null;
      }
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.type) {
        params = {
          type: this.type,
          ...params,
        };
      }
      if (this.statusOrderParam) {
        params = {
          status_order: this.statusOrderParam,
          ...params,
        };
      }
      this.getOrderList(params);
      this.$router.push({
        path: "/sales/order-online",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>
