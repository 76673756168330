import roleService from "../../services/role";

const state = {
  roleList: [],
  roleDetail: {},
  isLoading: false,
};
const getters = {};
const actions = {
  async getRoleList({ commit }) {
    try {
      commit("SET_LOADING", true);
      const response = await roleService.getRole();
      commit("SET_LIST_ROLE", response.data);
      commit("SET_LOADING", false);
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getRoleDetail({ commit }, payload) {
    try {
      commit("SET_LOADING", true);
      const response = await roleService.getRoleDetail(payload);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async createRole({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await roleService.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm vai trò thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateRoleDetail({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await roleService.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_ROLE(state, payload) {
    state.roleList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_ROLE_DETAIL(state, payload) {
    state.roleDetail = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
