import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class RoleService {
  async getRole() {
    return axiosInstance.get(apiRoutesEnum.role);
  }

  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.role, payload);
  }

  async getRoleDetail(id) {
    return axiosInstance.get(`${apiRoutesEnum.role}/${id}`);
  }

  async update(payload) {
    const id = payload.id;
    const group_data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.role}/${id}`, group_data);
  }
}

export default new RoleService();
