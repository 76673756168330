export const convertDate = (value) => {
  return value
    .split("-")
    .reverse()
    .join("-");
};

export const addCommaPrice = (value) => {
  let result = typeof value === "string" ? value.trim() : value;
  if (result[0] !== "-") {
    result = value
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return value < 0 ? `-${result}` : result;
};
