<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo cáo nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <date-picker
                  v-model="date"
                  value-type="format"
                  class="ml-3"
                  range
                  format="DD-MM-YYYY"
                  placeholder="Chọn ngày để xem"
                  @change="onChangeParam"
                ></date-picker>
                <div class="col-md-5">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onChangeParam"
                  ></b-form-input>
                </div>
                <div class="col-md-1">
                  <b-button
                    class="btn-popup mb-0"
                    variant="primary"
                    @click="onChangeParam"
                    >Tìm</b-button
                  >
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  v-if="!isLoading && supplierReportList.length > 0"
                  class="text-center"
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="supplierReportData"
                  :fields="tablefields"
                  :tbody-tr-class="rowClass"
                >
                  <template v-slot:cell(code)="{ item }">
                    <span>
                      {{ item.code }}
                    </span>
                  </template>
                  <template v-slot:cell(value_order)="{ item }">
                    <span>
                      {{ item.value_order | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(return_order)="{ item }">
                    <span> {{ item.return_order }} </span>
                  </template>
                  <template v-slot:cell(value_return)="{ item }">
                    <span>
                      {{ item.value_return | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(net_total)="{ item }">
                    <span>
                      {{ item.net_total | formatCurrency }}
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && supplierReportList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalSupplierRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalSupplierRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { convertDate } from "../../config/common";

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã nhà cung cấp" },
        { key: "name", label: "Tên nhà cung cấp" },
        { key: "order", label: "Số đơn hàng" },
        { key: "value_order", label: "Giá trị nhập" },
        { key: "return_order", label: "Số đơn trả hàng" },
        { key: "value_return", label: "Giá trị trả hàng" },
        { key: "net_total", label: "Giá trị (thuần)" },
      ],
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      date: "",
      search: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getSupplierReportList(params);
    this.getSupplierList();
  },
  computed: {
    ...mapState("reports", [
      "supplierReportList",
      "totalSupplierRecord",
      "totalOrderSupplier",
      "totalValueSupplier",
      "totalReturnOrdersSupplier",
      "totalValueReturnsSupplier",
      "totalValueNetSupplier",
      "isLoading",
    ]),
    ...mapState("supplier", ["supplierList"]),
    supplierReportData() {
      const data = {
        code: `SL NCC: ${this.supplierList.length}`,
        order: `Số đơn hàng: ${this.totalOrderSupplier}`,
        value_order: this.totalValueSupplier,
        return_order: `Số đơn trả hàng: ${this.totalReturnOrdersSupplier}`,
        value_return: this.totalValueReturnsSupplier,
        net_total: this.totalValueNetSupplier,
        status: "awesome",
      };
      const newData = [data].concat(this.supplierReportList);
      return newData;
    },
  },
  methods: {
    ...mapActions("reports", ["getSupplierReportList"]),
    ...mapActions("supplier", ["getSupplierList"]),
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.search) {
        params = {
          search: this.search,
          ...params,
        };
      }
      this.getSupplierReportList(params);
      this.$router.push({
        path: "/reports/supplier",
        query: { page: this.pageNumber },
      });
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "awesome") return "table-warning";
    },
    onChangeParam() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.search) {
        params = {
          search: this.search,
          ...params,
        };
      }
      this.getSupplierReportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/reports/supplier",
          query: { page: this.pageNumber },
        });
      }
    },
  },
};
</script>
