<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo cáo sản phẩm" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="row">
                <date-picker
                  v-model="date"
                  value-type="format"
                  range
                  class="ml-3"
                  format="DD-MM-YYYY"
                  placeholder="Chọn ngày để xem"
                  @change="onChangeParam"
                ></date-picker>
                <div class="col-md-6">
                  <b-form-select
                    v-model="idCategory"
                    @change="onChangeParam"
                    class="col-md-5 pull-left"
                    required=""
                  >
                    <option value="">Chọn danh mục</option>
                    <option
                      v-for="category in categoryList"
                      :key="category.index"
                      :value="category.id"
                      >{{ category.name }}</option
                    >
                  </b-form-select>
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="table-responsive datatable-vue product-list">
                <b-table
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="productReportData"
                  :fields="tablefields"
                  :tbody-tr-class="rowClass"
                >
                  <template v-slot:cell(revenue)="{ item }">
                    <span> {{ item.revenue | formatCurrency }} </span>
                  </template>
                  <template v-slot:cell(name)="{ item }">
                    <span> {{ item.name }} </span>
                    <span v-if="item.unit">({{ item.unit }})</span>
                  </template>
                  <template v-slot:cell(value_return)="{ item }">
                    <span> {{ item.value_return | formatCurrency }} </span>
                  </template>
                  <template v-slot:cell(net_total)="{ item }">
                    <span>
                      {{ item.net_total | formatCurrency }}
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && productReportList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalProductRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalProductRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { convertDate } from "../../config/common";

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã hàng" },
        { key: "name", label: "Tên hàng" },
        { key: "amount_of_sale", label: "Số lượng bán" },
        { key: "revenue", label: "Doanh thu" },
        { key: "amount_of_return", label: "Số lượng trả" },
        { key: "value_return", label: "Giá trị trả" },
        { key: "net_total", label: "Doanh thu thuần" },
      ],
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      date: "",
      idCategory: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getProductReportList(params);
    this.getCategoryList();
  },
  computed: {
    ...mapState("reports", [
      "productReportList",
      "totalProductRecord",
      "totalRevenueProduct",
      "numOrdersProduct",
      "numProducts",
      "numReturnProduct",
      "totalReturnProduct",
      "totalNetProduct",
      "isLoading",
    ]),
    ...mapState("category", ["categoryList"]),
    productReportData() {
      const data = {
        code: `SL mặt hàng ${this.numProducts}`,
        amount_of_sale: `${this.numOrdersProduct} (Đơn hàng)`,
        revenue: this.totalRevenueProduct,
        amount_of_return: `${this.numReturnProduct} (Đơn hàng)`,
        value_return: this.totalReturnProduct,
        net_total: this.totalNetProduct,
        status: "awesome",
      };
      const newData = [data].concat(this.productReportList);
      return newData;
    },
  },
  methods: {
    ...mapActions("reports", ["getProductReportList"]),
    ...mapActions("category", ["getCategoryList"]),
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.idCategory) {
        params = {
          category_id: this.idCategory,
          ...params,
        };
      }
      this.getProductReportList(params);
      this.$router.push({
        path: "/reports/product",
        query: { page: this.pageNumber },
      });
    },
    onChangeParam() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          start: convertDate(this.date[0]),
          end: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.idCategory) {
        params = {
          category_id: this.idCategory,
          ...params,
        };
      }
      this.getProductReportList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/reports/product",
          query: { page: this.pageNumber },
        });
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.status === "awesome") return "table-warning";
    },
  },
};
</script>
