<template>
  <div class="container-fluid">
    <loader :is-visible="isLoading"></loader>
    <div class="page-header">
      <Breadcrumbs title="Thay đổi thông tin" />
    </div>
    <div class="row">
      <div class="col">
        <div class="card tab2-card">
          <div class="card-header">
            <h5>
              Cập nhật mật khẩu
            </h5>
          </div>
          <div class="card-body">
            <div class="tab-content" id="top-tabContent">
              <ValidationObserver ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <ValidationProvider name="Mật khẩu cũ" rules="required">
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-cols-sm="3"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Mật khẩu cũ
                          </template>
                          <b-form-input
                            v-model="oldPassword"
                            type="password"
                            placeholder="********"
                            :state="errors[0] ? false : valid ? true : null"
                          ></b-form-input>

                          <b-form-invalid-feedback>{{
                            errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                      <ValidationProvider
                        :rules="{ required: true, min: 8, regex: /[A-Z]/i }"
                        name="Mật khẩu mới"
                        vid="password"
                      >
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-cols-sm="3"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-2"
                        >
                          <template v-slot:label>
                            <span>*</span>Mật khẩu mới
                          </template>
                          <b-form-input
                            type="password"
                            v-model="newPassword"
                            :state="errors[0] ? false : valid ? true : null"
                            validateOnInput
                            placeholder="********"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback>
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>

                      <ValidationProvider
                        rules="required|confirmed:password"
                        name="Mật khẩu xác nhận"
                      >
                        <b-form-group
                          slot-scope="{ valid, errors }"
                          label-cols-sm="3"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-3"
                        >
                          <template v-slot:label>
                            <span>*</span>Nhập lại mật khẩu
                          </template>
                          <b-form-input
                            type="password"
                            v-model="newPasswordConfirm"
                            placeholder="********"
                            :state="errors[0] ? false : valid ? true : null"
                          >
                          </b-form-input>
                          <b-form-invalid-feedback>
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Cập nhật
                    </button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";
import { regex } from "vee-validate/dist/rules";
import Loader from "../../components/loading/loader.vue";

extend("regex", {
  ...regex,
  message: "Mật khẩu chỉ toàn số, hãy nhập mật khẩu mạnh hơn nhé",
});

export default {
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    };
  },
  components: {
    Loader,
    ValidationObserver,
    ValidationProvider,
  },
  watch: {},
  computed: {
    ...mapState("profile", ["isLoading"]),
  },
  methods: {
    ...mapActions("profile", ["changePassword"]),
    onSubmit() {
      const data = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirm: this.newPasswordConfirm,
      };
      this.changePassword(data);
    },
    handleBackList() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped></style>
