import axiosInstance from "./axios";
import { apiRoutesEnum } from "../enums/routes";

class FoodServices {
  async createFood(payload) {
    return axiosInstance.post(`${apiRoutesEnum.food}`, payload);
  }
  getFoods(params) {
    return axiosInstance.get(`${apiRoutesEnum.food}`, { params: params });
  }
  updateFood(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.food}/${id}`, { ...data });
  }
  deleteFood(id) {
    return axiosInstance.delete(`${apiRoutesEnum.food}/${id}`);
  }
  sendNotifyFood() {
    return axiosInstance.post(`${apiRoutesEnum.sendNotifyFood}`);
  }
}

export default new FoodServices();
