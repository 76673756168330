<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo giá nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>Xuất báo giá</h5>
            </div>
            <div class="card-body">
              <div class="d-flex align-items-center">
                <!-- <input
                  v-model="excelName"
                  placeholder="Tên file excel"
                  class="form-control rbt-custom-input mr-3"
                  type="text"
                /> -->
                <p class="mr-3 mb-0">Chọn nhà cung cấp</p>
                <div class="rbt-select-supplier">
                  <multiselect
                    class="rbt-custom-input px-0"
                    placeholder=""
                    v-model="dataSubmit.supplier"
                    :show-labels="false"
                    :custom-label="searchingSelect"
                    :options="supplierList"
                  >
                    <span slot="noResult">Không tìm thấy kết quả!!!</span>
                    <template slot="singleLabel" slot-scope="props"
                      ><span class="option__desc"
                        ><span class="option__title">{{
                          props.option.name
                        }}</span></span
                      ></template
                    >
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <p class="option__title">
                          {{ props.option.name }}
                        </p>
                      </div>
                    </template>
                  </multiselect>
                </div>
              </div>
              <div class="table-responsive datatable-vue text-center mt-4">
                <b-modal
                  id="model-remove"
                  title="Xác nhận"
                  @ok="removeProductInTab()"
                >
                  <p class="my-4">Are you sure!</p>
                </b-modal>
                <b-table
                  show-empty
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="listProductTable"
                  :fields="tablefields"
                  :per-page="perPage"
                  :current-page="currentPage"
                >
                  <template #empty>
                    <span class="text-danger">
                      Chưa có sản phẩm nào
                    </span>
                  </template>

                  <template v-slot:cell(unit_price)="{ item }">
                    <span>
                      {{ item.unit_price | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(price_recommended)="{ item }">
                    <span>
                      {{ item.price_recommended | formatCurrency }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="removeProductInTable(item.sno)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div>
                  <b-pagination
                    class="mt-2 d-flex justify-content-end"
                    v-model="currentPage"
                    :total-rows="listProductTable.length"
                    :per-page="perPage"
                    aria-controls="my-table"
                  ></b-pagination>
                </div>
              </div>
              <ListProductBottom
                v-if="isListProduct.length > 0 && dataSubmit.supplier"
                :productList="productsActive"
                @onAddProductInTab="onAddProductInTab"
              />
            </div>
            <div class="pr-4 d-flex justify-content-end">
              <b-button class="btn-popup btn-danger mr-3" @click="clearData()">
                Làm mới</b-button
              >
              <b-button
                :disabled="downloadLoading"
                class="btn-popup pull-right"
                @click="handleExportExcel()"
              >
                <b-spinner
                  v-if="downloadLoading"
                  class="mr-2"
                  small
                  variant="primary"
                  label="Spinning"
                ></b-spinner>
                Xuất báo giá</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import ListProductBottom from "../../components/UI/listProductBottom.vue";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";

const initialDataSubmit = {
  product_quotation: [],
  name: "",
  code: "",
  note: "",
  supplier: null,
};

export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "code", label: "Mã sản phẩm", sortable: true },
        { key: "name", label: "Sản phẩm" },
        { key: "unit_price", label: "Đơn giá", sortable: true },
        { key: "price_recommended", label: "Giá khuyến nghị", sortable: true },
        { key: "actions", label: "Xóa" },
      ],
      productQuotes: [],
      downloadLoading: false,
      excelName: "",
      dataSubmit: { ...initialDataSubmit },
    };
  },
  components: {
    Loader,
    ListProductBottom,
    IncreaseAndDecrease,
  },
  created() {
    this.getSupplierList();
  },
  computed: {
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", ["isListProduct", "isLoading"]),
    listProductTable() {
      return this.productQuotes.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    productsActive() {
      let data = [];
      const idPurchasedProduct = this.productQuotes?.map((item) => {
        return item.id;
      });
      data = this.isListProduct?.filter((item) => {
        return !idPurchasedProduct?.includes(item.id);
      });
      return data;
    },
  },
  watch: {
    isListProduct() {
      this.productQuotes =
        this.isListProduct?.map((item) => {
          const { id, code, name, price } = item;
          return {
            id,
            code,
            name,
            unit_price: price,
            quantity: 0,
            price_recommended: item?.price || 0,
          };
        }) || [];
    },
    "dataSubmit.supplier"() {
      this.productQuotes = [];
      if (this.dataSubmit.supplier?.id) {
        const params = {
          supplier_id: this.dataSubmit.supplier?.id,
        };
        this.onListProduct(params);
      }
    },
  },
  methods: {
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("product", ["onListProduct"]),
    clearData() {
      this.dataSubmit.supplier = null;
      this.excelName = "";
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          if (j === "timestamp") {
            return parseTime(v[j]);
          } else {
            return v[j];
          }
        })
      );
    },
    handleExportExcel() {
      if (this.productQuotes.length > 0) {
        this.downloadLoading = true;
        import("@/vendor/Export2Excel").then((excel) => {
          const tHeader = [
            "Mã sản phẩm",
            "Tên",
            "Số lượng",
            "Giá",
            "Giá khuyến nghị",
          ];
          const filterVal = [
            "id",
            "name",
            "quantity",
            "unit_price",
            "price_recommended",
          ];
          const list = this.productQuotes;
          const data = this.formatJson(filterVal, list);
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: this.dataSubmit.supplier.name || "Báo Giá",
            //   autoWidth: this.autoWidth,
            //   bookType: this.bookType,
          });
          this.downloadLoading = false;
        });
      } else {
        alert("Bạn chưa có sản phẩm nào");
      }
    },
    removeProductInTable(index) {
      this.productQuotes.splice(index - 1, 1);
    },
    onIncreAndDecre({ quantity, id }) {
      this.productQuotes[id - 1].quantity = quantity;
    },
    searchingSelect({ name }) {
      return name;
    },
    onAddProductInTab(item) {
      const { id, name, unit, price } = item;
      this.productQuotes.push({
        id,
        name,
        unit,
        unit_price: price,
        quantity: 1,
        price_recommended: price,
      });
    },
  },
};
</script>

<style scoped>
input::placeholder,
textarea::placeholder {
  font-size: 15px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.rbt-select-supplier {
  width: 200px;
}
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}
</style>
