import FoodServices from "../../services/food";

const state = {
  isLoading: false,
  foodList: [],
  totalRecord: 0,
};
const getters = {};
const actions = {
  async onCreateFood({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      commit("SET_LOADING", true);
      const response = await FoodServices.createFood(data);
      commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm thành công",
          isShow: true,
        },
        { root: true }
      );
      dispatch("onFoodList", params);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async sendNotifyFood({ commit, dispatch }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await FoodServices.sendNotifyFood();
      commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Gửi thông báo thành công",
          isShow: true,
        },
        { root: true }
      );
      dispatch("onFoodList", params);
    } catch (error) {
      commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async onFoodList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await FoodServices.getFoods(params);
      const { count, results } = response.data.data;
      commit("SET_TOTAL_RECORD", count);
      commit("SET_FOOD_LIST", results);
      commit("SET_LOADING", false);
    } catch (error) {
      await commit("SET_ERROR_LIST", error.response, { root: true });
      commit("SET_LOADING", false);
    }
  },
  async onUpdateFood({ commit, dispatch }, payload) {
    const { data, params } = payload;
    try {
      commit("SET_LOADING", true);
      const response = await FoodServices.updateFood(data);
      commit("SET_LOADING", true);
      commit("SET_UPDATE", true, { root: true });
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật thành công",
          isShow: true,
        },
        { root: true }
      );
      dispatch("onFoodList", params);
    } catch (error) {
      commit("SET_LOADING", false);
      commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
  async deleteFood({ commit, dispatch }, payload) {
    try {
      const { id, params } = payload;
      await commit("SET_LOADING", true);
      const response = await FoodServices.deleteFood(id);
      await dispatch("onFoodList", params);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
  SET_TOTAL_RECORD(state, payload) {
    state.totalRecord = payload;
  },
  SET_FOOD_LIST(state, payload) {
    state.foodList = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
