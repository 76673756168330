var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"size":"md","id":"modal-inventory-warehouse","centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h3',{staticClass:"mx-auto",staticStyle:{"color":"#ff8084"}},[_vm._v(" Thêm khách hàng ")])]},proxy:true},{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('div',{staticClass:"d-flex justify-content-end",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"mr-3",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return cancel()}}},[_vm._v(" Đóng ")])],1)])]}}])},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.submit()}}},[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-1",attrs:{"for":"validationCustom01"}},[_c('span',[_vm._v("*")]),_vm._v("Tên khách hàng")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSubmit.name),expression:"dataSubmit.name"}],staticClass:"form-control",attrs:{"id":"validationCustom01","type":"text","placeholder":"Nhập tên khách hàng"},domProps:{"value":(_vm.dataSubmit.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataSubmit, "name", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-1",attrs:{"for":"validationCustom01"}},[_c('span',[_vm._v("*")]),_vm._v("Số điện thoại")]),_c('validation-provider',{attrs:{"name":"Số điện thoại","rules":{
                regexPhone: /^[0-9]+$/,
                min: 10,
                max: 11,
              }},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',[_c('b-form-input',{attrs:{"id":"example-input-6","name":"example-input-6","placeholder":"Nhập số điện thoại","state":_vm.getValidationState(validationContext),"aria-describedby":"input-6-live-feedback"},model:{value:(_vm.dataSubmit.phone_number),callback:function ($$v) {_vm.$set(_vm.dataSubmit, "phone_number", $$v)},expression:"dataSubmit.phone_number"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-6-live-feedback"}},[_vm._v(_vm._s(validationContext.errors[0]))])],1)]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"mb-1",attrs:{"for":"validationCustom01"}},[_vm._v("Địa chỉ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataSubmit.address),expression:"dataSubmit.address"}],staticClass:"form-control",attrs:{"placeholder":"Nhập địa chỉ","id":"validationCustom01","type":"text"},domProps:{"value":(_vm.dataSubmit.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.dataSubmit, "address", $event.target.value)}}})])]),_c('b-button',{staticClass:"btn btn-primary pull-right",attrs:{"type":"submit","disabled":invalid || !_vm.isComplete}},[_c('span',[_vm._v(" Thêm khách hàng ")])])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }