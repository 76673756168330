import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";
class SupplierService {
  async deleteOrder(id) {
    return axiosInstance.delete(`${apiRoutesEnum.orderSupplier}/${id}`);
  }

  async getAllOrderSupplier(params) {
    return axiosInstance.get(apiRoutesEnum.orderSupplier, { params: params });
  }

  async getAllRefundList(params) {
    return axiosInstance.get(apiRoutesEnum.orderReturnSupplier, {
      params: params,
    });
  }

  async getRefundDetail(id) {
    return axiosInstance.get(`${apiRoutesEnum.orderReturnSupplier}/${id}`);
  }

  async deleteRefund(id) {
    return axiosInstance.delete(`${apiRoutesEnum.orderReturnSupplier}/${id}`);
  }

  async onCreateRefund(payload) {
    return axiosInstance.post(apiRoutesEnum.orderReturnSupplier, payload);
  }

  async onUpdateRefund(payload) {
    const { id, ...data } = payload;
    return axiosInstance.patch(`${apiRoutesEnum.orderReturnSupplier}/${id}`, {
      ...data,
    });
  }

  async onCreateOrderSupplier(payload) {
    return axiosInstance.post(apiRoutesEnum.orderSupplier, payload);
  }

  async onUpdateOrderSupplier(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.orderSupplier}/${id}`, {
      ...data,
    });
  }

  async orderSupplierDetail(id) {
    return axiosInstance.get(`${apiRoutesEnum.orderSupplier}/${id}`);
  }

  async getQuotationList(params) {
    return axiosInstance.get(apiRoutesEnum.quotation, { params: params });
  }

  async getQuotationDetail(id) {
    return axiosInstance.get(`${apiRoutesEnum.quotation}/${id}`);
  }

  async quotationsSubmit(payload) {
    return axiosInstance.post(apiRoutesEnum.quotation, payload);
  }

  async quotationsUpdate(payload) {
    const { id, ...data } = payload;
    return axiosInstance.put(`${apiRoutesEnum.quotation}/${id}`, { ...data });
  }

  async deleteQuotations(id) {
    return axiosInstance.delete(`${apiRoutesEnum.quotation}/${id}`);
  }

  async getAll(params) {
    return axiosInstance.get(apiRoutesEnum.supplier, { params: params });
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.supplier}/${id}`);
  }

  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.user, payload);
  }

  async update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.user}/${id}`, data);
  }

  async delete(id) {
    return axiosInstance.delete(`${apiRoutesEnum.supplier}/${id}`);
  }
}

export default new SupplierService();
