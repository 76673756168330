<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Thiết lập chiết khấu khách hàng" />
      </div>
      <div class="row">
        <div class="col">
          <div class="card tab2-card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật chiết khấu khách hàng
              </h5>
              <h5 v-else>
                Thêm chiết khấu khách hàng
              </h5>
            </div>
            <div class="card-body">
              <validation-observer ref="observer" v-slot="{ invalid }">
                <b-form
                  class="needs-validation user-add"
                  @submit.stop.prevent="onSubmit()"
                  v-if="personDiscountInfo"
                >
                  <div class="row">
                    <div class="col-sm-6 col-md-12">
                      <validation-provider
                        name="Khách hàng"
                        :rules="{ required: true }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            <span>*</span>Khách hàng</template
                          >
                          <multiselect
                            placeholder="Chọn khách hàng"
                            :show-labels="false"
                            class="rbt-custom-input"
                            :disabled="!!$route.query.id"
                            v-model="personDiscountInfo.user"
                            @search-change="search"
                            :internal-search="false"
                            :options="customerList"
                          >
                            <span slot="noResult"
                              >Không tìm thấy kết quả!!!</span
                            >
                            <template slot="singleLabel" slot-scope="props"
                              ><span class="option__desc"
                                ><span class="option__title">{{
                                  props.option.name
                                }}</span></span
                              ></template
                            >
                            <template slot="option" slot-scope="props">
                              <div class="option__desc">
                                <p class="option__title">
                                  {{ props.option.name }}
                                </p>
                              </div>
                            </template>
                          </multiselect>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider
                        name="Chiết khấu riêng"
                        :rules="{
                          required: true,
                          percentGreaterZero: true,
                          regexDiscount: /^[0-9]+$/,
                        }"
                        v-slot="validationContext"
                      >
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label
                            ><span>*</span>Chiết khấu riêng</template
                          >
                          <b-form-input
                            id="example-input-1"
                            name="example-input-1"
                            v-model="personDiscountInfo.discount"
                            type="number"
                            placeholder="Nhập chiết khấu riêng cho khách"
                            :state="getValidationState(validationContext)"
                            aria-describedby="input-1-live-feedback"
                          ></b-form-input>

                          <b-form-invalid-feedback id="input-1-live-feedback">{{
                            validationContext.errors[0]
                          }}</b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>Chiết khấu tự động</template>
                          <span
                            v-if="
                              personDiscountInfo.user &&
                                personDiscountInfo.user.discount &&
                                !$route.query.id
                            "
                            >{{
                              personDiscountInfo.user.discount.discount_auto
                            }}%</span
                          >
                          <span v-else-if="$route.query.id"
                            >{{ personDiscountInfo.discount_auto }}%</span
                          >
                          <span v-else>__</span>
                        </b-form-group>
                      </validation-provider>

                      <validation-provider>
                        <b-form-group
                          id="example-input-group-1"
                          label-cols-sm="4"
                          label-cols-lg="3"
                          content-cols-sm
                          content-cols-lg="8"
                          label-for="example-input-1"
                        >
                          <template v-slot:label>
                            Sử dụng chiết khấu tự động
                          </template>
                          <b-form-checkbox
                            id="checkbox-1"
                            v-model="personDiscountInfo.auto"
                            name="checkbox-1"
                          >
                          </b-form-checkbox>
                        </b-form-group>
                      </validation-provider>
                    </div>
                  </div>
                  <div class="pull-right">
                    <button
                      type="button"
                      class="btn btn-danger mr-3"
                      @click="handleBackList()"
                    >
                      Trở lại
                    </button>
                    <button
                      v-if="!$route.query.id"
                      class="btn btn-danger mr-3"
                      type="button"
                      @click="cancelUpdate()"
                    >
                      Xóa
                    </button>
                    <button
                      type="submit"
                      class="btn btn-primary"
                      :disabled="invalid"
                    >
                      Lưu
                    </button>
                  </div>
                </b-form>
              </validation-observer>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";
import { extend } from "vee-validate";

extend("percentGreaterZero", (value) => {
  if (value >= 0 && value <= 100) return true;
  return "Chiết khấu phải là số dương và không vượt quá 100";
});

const initialData = {
  discount: "",
  auto: true,
  user: "",
};

export default {
  data() {
    const personDiscountInfo = this.$route.query.id
      ? this.personDiscountInfo
      : initialData;
    return {
      personDiscountInfo,
    };
  },
  components: {
    Loader,
  },
  async created() {
    if (this.$route.query.id) {
      this.personDiscountInfo = await this.getPersonDiscountDetail(
        this.$route.query.id
      );
    } else {
      this.personDiscountInfo = {
        ...initialData,
      };
    }
    this.getCustomerList();
  },
  computed: {
    ...mapState("personDiscount", ["isLoading"]),
    ...mapState("customer", ["customerList"]),
  },
  watch: {},
  methods: {
    ...mapActions("personDiscount", [
      "createPersonDiscount",
      "updatePersonDiscount",
      "getPersonDiscountDetail",
    ]),
    ...mapActions("customer", ["getCustomerList"]),
    onSubmit() {
      const { user, ...data } = this.personDiscountInfo;
      const dataSubmit = { user: user?.id, ...data };
      if (this.personDiscountInfo.id) {
        const personDiscountData = {
          id: this.personDiscountInfo.id,
          data: dataSubmit,
        };
        this.updatePersonDiscount(personDiscountData);
      } else {
        this.createPersonDiscount(dataSubmit);
      }
    },
    cancelUpdate() {
      this.personDiscountInfo = {
        ...initialData,
      };
    },
    handleBackList() {
      this.$router.push({ name: "person-discount-list" });
    },
    getValidationState({ validated, valid = null }) {
      valid = valid ? null : valid;
      return validated ? valid : null;
    },
    search: _.debounce(function(query) {
      this.getCustomerList({ query: query.trim() });
    }, 350),
  },
};
</script>
<style scoped></style>
