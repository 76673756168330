import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class ArticleService {
  async getAll(params) {
    return axiosInstance.get(apiRoutesEnum.article, { params: params });
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.article}/${id}`);
  }

  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.article, payload);
  }

  async update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.article}/${id}`, data);
  }

  async delete(id) {
    return axiosInstance.delete(`${apiRoutesEnum.article}/${id}`);
  }
}

export default new ArticleService();
