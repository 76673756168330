import unitServices from "../../services/unit";

const state = {
  unitList: [],
  totalRecord: 0,
  isLoading: false,
};
const getters = {};
const actions = {
  async getUnitList({ commit }, params) {
    try {
      commit("SET_LOADING", true);
      const response = await unitServices.getAll(params);
      commit("SET_LIST_UNIT", response.data);
      commit("SET_LOADING", false);
      return response;
    } catch (error) {
      commit("SET_LOADING", false);
    }
  },

  async getUnitDetail({ commit }, id) {
    try {
      commit("SET_LOADING", true);
      const response = await unitServices.getItem(id);
      commit("SET_LOADING", false);
      return response.data;
    } catch (error) {
      commit("SET_LOADING", false);
      return {};
    }
  },

  async createUnit({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await unitServices.create(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Thêm đơn vị thành công",
          isShow: true,
        },
        { root: true }
      );
      return response;
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async updateUnit({ commit }, payload) {
    try {
      await commit("SET_LOADING", true);
      const response = await unitServices.update(payload);
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Cập nhật đơn vị thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },

  async deleteUnit({ commit, dispatch }, payload) {
    try {
      const { id } = payload;
      await commit("SET_LOADING", true);
      const response = await unitServices.delete(id);
      await dispatch("getUnitList");
      await commit("SET_LOADING", false);
      await commit(
        "SET_MESSAGE",
        {
          type: "success",
          content: "Xoá đơn vị thành công",
          isShow: true,
        },
        { root: true }
      );
    } catch (error) {
      await commit("SET_LOADING", false);
      await commit("SET_ERROR_LIST", error.response, { root: true });
    }
  },
};
const mutations = {
  SET_LIST_UNIT(state, payload) {
    state.unitList = payload;
  },
  SET_LOADING(state, payload) {
    state.isLoading = payload;
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
