<template>
  <div>
    <!-- Container-fluid starts-->
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh mục sản phẩm" />
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h5 v-if="this.$route.query.id">
                Cập nhật sản phẩm
              </h5>
              <h5 v-else>
                Thêm sản phẩm
              </h5>
            </div>
            <div class="card-body">
              <div class="row product-adding">
                <div class="col-xl-5">
                  <div class="add-product">
                    <div class="row">
                      <div class="col-xl-9 xl-50 col-sm-6 col-9">
                        <div class="text-center">
                          <img
                            v-if="productUpdateInfo.image"
                            :src="productUpdateInfo.image"
                            class="img-fluid image_zoom_1 blur-up lazyloaded"
                          />
                          <img
                            v-else
                            src="../../assets/images/image_default.png"
                            class="img-fluid image_zoom_1 blur-up lazyloaded test"
                          />
                          <a
                            style="position: absolute; bottom: -6px; left: 0;right: 0; cursor: pointer;"
                            class="text-dark"
                            @click="onChangeAvatar()"
                            ><i data-feather="camera"
                              ><feather type="camera"></feather></i
                          ></a>
                        </div>
                        <input
                          @change="onFileChanged"
                          ref="uploader"
                          class="form-control d-none"
                          id="validationCustom0"
                          type="file"
                          accept="image/*"
                        />
                      </div>
                      <div class="col-xl-3 xl-50 col-sm-6 col-3 px-0">
                        <ul class="file-upload-product">
                          <li
                            v-for="(img,
                            index) in productUpdateInfo.list_images"
                            :key="index"
                          >
                            <img :src="img.image" class="box-input-file" />
                            <feather
                              style="cursor: pointer;"
                              type="x"
                              stroke-width="1"
                              size="20px"
                              class="icon"
                              @click="removeImage(index)"
                            ></feather>
                          </li>
                          <li>
                            <div class="box-input-file">
                              <input
                                class="upload"
                                type="file"
                                @change="onFileChange"
                              />
                              <feather type="plus"></feather>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <h5 class="mt-5">Định mức sản xuất</h5>
                  <div class="row d-flex">
                    <div class="col-xl-6">
                      <multiselect
                        class="rbt-custom-input"
                        v-model="quantitations.product"
                        placeholder="Nguyên liệu"
                        :show-labels="false"
                        @search-change="search"
                        :options="productsActive"
                        :internal-search="false"
                      >
                        <span slot="noResult">Không tìm thấy kết quả!!!</span>
                        <template slot="singleLabel" slot-scope="props"
                          ><span class="option__desc"
                            ><span class="option__title">{{
                              props.option.name
                            }}</span></span
                          ></template
                        >
                        <template slot="option" slot-scope="props">
                          <div class="option__desc">
                            <p class="option__title">
                              {{ props.option.name }}
                            </p>
                          </div>
                        </template>
                      </multiselect>
                    </div>
                    <div class="col-xl-4 ml-3">
                      <input
                        v-model="quantitations.quantity"
                        placeholder="Số lượng"
                        class="form-control"
                        id="validationCustom1"
                        type="number"
                      />
                    </div>

                    <div class="d-flex align-items-center">
                      <div
                        class="d-flex"
                        style="border-radius: 50%; background-color: #28a745"
                        @click="addProduct()"
                      >
                        <feather
                          class="rbt-icon-plus"
                          style="cursor: pointer"
                          type="plus"
                          stroke="#f5fbf6"
                          size="28px"
                          fill="#f5fbf6"
                        ></feather>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive datatable-vue text-center mt-4">
                    <b-table
                      show-empty
                      striped
                      hover
                      head-variant="light"
                      bordered
                      stacked="md"
                      :items="itemsImport"
                      :fields="tablefields"
                    >
                      <template #empty>
                        <span class="text-danger">
                          Chưa có nguyên liệu nào
                        </span>
                      </template>
                      <template v-slot:cell(name)="{ item }">
                        <span> {{ item.product.name }} </span>
                      </template>
                      <template v-slot:cell(quantity)="{ item }">
                        <span>
                          {{ item.quantity }} {{ item.product.unit }}
                        </span>
                      </template>
                      <template v-slot:cell(actions)="{ item }">
                        <span
                          style="font-size: 20px; color: #FA399F;"
                          @click="removeItemInTable(item.sno)"
                        >
                          <feather
                            style="cursor: pointer"
                            type="trash"
                            stroke="#F72E9F"
                            size="18px"
                            fill="#F72E9F"
                          ></feather>
                        </span>
                      </template>
                    </b-table>
                  </div>

                  <!-- promotion according to quantity -->
                  <h5 class="mt-5">Giảm giá theo số lượng sản phẩm</h5>
                  <div class="row d-flex">
                    <div class="col-xl-3">
                      <input
                        v-model="promotion_according_to_quantity.min"
                        class="form-control"
                        disabled
                        id="validationCustom1"
                        type="number"
                      />
                    </div>
                    <div class="col-xl-3">
                      <input
                        v-model="promotion_according_to_quantity.max"
                        class="form-control"
                        id="validationCustom1"
                        type="number"
                      />
                    </div>
                    <div class="col-xl-5">
                      <input
                        v-model="promotion_according_to_quantity.price"
                        placeholder="Giá khuyến mãi"
                        class="form-control"
                        id="validationCustom1"
                      />
                    </div>

                    <div class="d-flex align-items-center">
                      <div
                        class="d-flex"
                        style="border-radius: 50%; background-color: #28a745"
                        @click="addPromotion()"
                      >
                        <feather
                          class="rbt-icon-plus"
                          style="cursor: pointer"
                          type="plus"
                          stroke="#f5fbf6"
                          size="28px"
                          fill="#f5fbf6"
                        ></feather>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive datatable-vue text-center mt-4">
                    <b-table
                      show-empty
                      striped
                      hover
                      head-variant="light"
                      bordered
                      stacked="md"
                      :items="itemsPromotion"
                      :fields="tableFieldsPromotion"
                    >
                      <template #empty>
                        <span class="text-danger">
                          Chưa có giảm giá
                        </span>
                      </template>
                      <template v-slot:cell(price)="{ item }">
                        <span> {{ item.price | formatCurrency }} </span>
                      </template>
                      <template v-slot:cell(min)="{ item }">
                        <span
                          >Lớn hơn {{ item.min }}
                          <span v-if="productUpdateInfo.unit">{{
                            productUpdateInfo.unit
                          }}</span>
                        </span>
                      </template>
                      <template v-slot:cell(max)="{ item }">
                        <span>
                          {{ item.max }}
                          <span v-if="productUpdateInfo.unit">{{
                            productUpdateInfo.unit
                          }}</span>
                        </span>
                      </template>
                      <template v-slot:cell(actions)="{ item }">
                        <span
                          style="font-size: 20px; color: #FA399F;"
                          @click="removeItemInTablePromotion(item.sno)"
                          v-if="
                            item.sno ===
                              productUpdateInfo.promotion_according_to_quantity
                                .length
                          "
                        >
                          <feather
                            style="cursor: pointer"
                            type="trash"
                            stroke="#F72E9F"
                            size="18px"
                            fill="#F72E9F"
                          ></feather>
                        </span>
                      </template>
                    </b-table>
                  </div>
                </div>
                <div class="col-xl-7">
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <form
                      class="needs-validation add-product-form"
                      @submit.stop.prevent="onSubmit()"
                    >
                      <div class="form">
                        <div class="form-group mb-3">
                          <validation-provider
                            :rules="{ required: true }"
                            v-slot="validationContext"
                            name="Tên sản phẩm"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Tên sản phẩm<span> *</span>
                              </template>
                              <b-form-input
                                :state="getValidationState(validationContext)"
                                placeholder="Nhập tên sản phẩm"
                                id="example-input-1"
                                name="example-input-1"
                                v-model="productUpdateInfo.name"
                                aria-describedby="input-1-live-feedback"
                              ></b-form-input>
                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            name="Mã sản phẩm"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Mã sản phẩm<span> *</span>
                              </template>
                              <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                v-model="productUpdateInfo.code"
                                placeholder="Nhập mã sản phẩm"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-1-live-feedback"
                              ></b-form-input>

                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider name="Nhà cung cấp">
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Nhà cung cấp
                              </template>
                              <multiselect
                                v-model="productUpdateInfo.suppliers"
                                track-by="name"
                                :custom-label="searchingSelect"
                                class="rbt-custom-multiselect"
                                placeholder="Chọn nhà cung cấp"
                                :show-labels="false"
                                :options="supplierList"
                                :multiple="true"
                              >
                                <span slot="noResult"
                                  >Không tìm thấy kết quả!!!</span
                                ></multiselect
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider name="Danh mục">
                            <b-form-group
                              id="example-input-group-1 d-flex align-items-center"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Danh mục
                              </template>
                              <multiselect
                                class="rbt-custom-input px-0"
                                placeholder="Chọn danh mục sản phẩm"
                                v-model="productUpdateInfo.category"
                                :show-labels="false"
                                :custom-label="searchingSelect"
                                :options="categoryList"
                              >
                                <span slot="noResult"
                                  >Không tìm thấy kết quả!!!</span
                                >
                                <template slot="singleLabel" slot-scope="props"
                                  ><span class="option__desc"
                                    ><span class="option__title">{{
                                      props.option.name
                                    }}</span></span
                                  ></template
                                >
                                <template slot="option" slot-scope="props">
                                  <div class="option__desc">
                                    <p class="option__title">
                                      {{ props.option.name }}
                                    </p>
                                  </div>
                                </template>
                              </multiselect>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            :rules="{}"
                            v-slot="validationContext"
                            name="Thương hiệu"
                          >
                            <b-form-group
                              id="example-input-group-1 d-flex align-items-center"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Thương hiệu
                              </template>
                              <multiselect
                                class="rbt-custom-input px-0"
                                placeholder="Chọn thương hiệu"
                                v-model="productUpdateInfo.brand"
                                :show-labels="false"
                                :custom-label="searchingSelect"
                                :state="getValidationState(validationContext)"
                                :options="brandList"
                              >
                                <span slot="noResult"
                                  >Không tìm thấy kết quả!!!</span
                                >
                                <template slot="singleLabel" slot-scope="props"
                                  ><span class="option__desc"
                                    ><span class="option__title">{{
                                      props.option.name
                                    }}</span></span
                                  ></template
                                >
                                <template slot="option" slot-scope="props">
                                  <div class="option__desc">
                                    <p class="option__title">
                                      {{ props.option.name }}
                                    </p>
                                  </div>
                                </template>
                              </multiselect>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            :rules="{
                              required: true,
                            }"
                            v-slot="validationContext"
                            name="Đơn giá bán"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Đơn giá bán<span> *</span>
                              </template>
                              <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                :state="getValidationState(validationContext)"
                                v-model="productUpdateInfo.price"
                                @change="onChangePrice"
                                aria-describedby="input-1-live-feedback"
                              ></b-form-input>
                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider>
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Giá khuyến mãi
                              </template>
                              <span v-if="productUpdateInfo.sale_price != 0">{{
                                productUpdateInfo.sale_price | formatCurrency
                              }}</span>
                              <span v-else>Chưa có</span>

                              <b-badge
                                href="/product/price-list"
                                variant="primary"
                                class="ml-4"
                                >Thiết lập giá</b-badge
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            :rules="{ required: true }"
                            v-slot="validationContext"
                            name="Đơn vị bán"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Đơn vị bán<span> *</span>
                              </template>
                              <select
                                class="form-control"
                                v-model="productUpdateInfo.unit"
                                @change="onChangeUnit"
                                aria-describedby="input-1-live-feedback"
                              >
                                <option value="">Chọn đơn vị bán</option>
                                <option
                                  v-for="unit in unitList"
                                  :key="unit.index"
                                  :value="unit.name"
                                  >{{ unit.name }}</option
                                >
                              </select>
                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            v-slot="validationContext"
                            name="Đơn vị nhập"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Đơn vị nhập
                              </template>
                              <select
                                class="form-control"
                                v-model="productUpdateInfo.import_unit"
                                aria-describedby="input-1-live-feedback"
                              >
                                <option value="null">Chọn đơn vị nhập</option>
                                <option
                                  v-for="unit in unitList"
                                  :key="unit.index"
                                  :value="unit.name"
                                  >{{ unit.name }}</option
                                >
                              </select>
                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            name="Ngày hết hạn"
                            v-slot="validationContext"
                            :rules="{}"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Ngày hết hạn
                              </template>
                              <div class="d-flex">
                                <b-form-datepicker
                                  placeholder="Chọn ngày"
                                  locale="vi"
                                  label-help=""
                                  v-model="productUpdateInfo.expiry"
                                  :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                  }"
                                  :state="getValidationState(validationContext)"
                                ></b-form-datepicker>
                                <feather
                                  style="cursor: pointer;"
                                  type="x-circle"
                                  stroke-width="1"
                                  size="20px"
                                  class="icon mt-3 ml-2"
                                  @click="clearDateExpiry()"
                                ></feather>
                              </div>
                              <b-form-invalid-feedback>{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            :rules="{ validateMinInventory: true }"
                            v-slot="validationContext"
                            name="Tồn kho tối thiểu"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Tồn kho tối thiểu
                              </template>
                              <b-form-input
                                id="example-input-1"
                                name="example-input-1"
                                type="number"
                                step="any"
                                v-model="productUpdateInfo.min_inventory"
                                :state="getValidationState(validationContext)"
                                aria-describedby="input-1-live-feedback"
                              ></b-form-input>
                              <b-form-invalid-feedback
                                id="input-1-live-feedback"
                                >{{
                                  validationContext.errors[0]
                                }}</b-form-invalid-feedback
                              >
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider name="Mô tả">
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Mô tả
                              </template>
                              <div class="editor-vue">
                                <vue-editor
                                  v-model="productUpdateInfo.description"
                                ></vue-editor>
                              </div>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider
                            name="Trạng thái"
                            :rules="{}"
                            v-slot="validationContext"
                          >
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label>
                                Trạng thái
                              </template>
                              <b-form-checkbox
                                id="checkbox-1"
                                v-model="productUpdateInfo.active"
                                name="checkbox-1"
                              >
                                Đang kinh doanh
                              </b-form-checkbox>

                              <b-form-invalid-feedback>{{
                                validationContext.errors[0]
                              }}</b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider>
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label> </template>
                              <b-form-checkbox
                                id="checkbox-3"
                                v-model="productUpdateInfo.is_best_buy"
                                name="checkbox-3"
                              >
                                Sản phẩm bán chạy
                              </b-form-checkbox>
                            </b-form-group>
                          </validation-provider>
                        </div>
                        <div class="form-group mb-3">
                          <validation-provider>
                            <b-form-group
                              id="example-input-group-1"
                              label-cols-xl="3"
                              label-cols-sm="4"
                              content-cols-sm="7"
                              content-cols-xl="8"
                              label-for="example-input-1"
                            >
                              <template v-slot:label> </template>
                              <b-form-checkbox
                                id="checkbox-2"
                                v-model="productUpdateInfo.is_new"
                                name="checkbox-2"
                              >
                                Sản phẩm mới
                              </b-form-checkbox>
                            </b-form-group>
                          </validation-provider>
                        </div>
                      </div>
                      <!-- <div class="row"> -->
                      <!-- </div> -->
                      <div class="pull-right">
                        <button
                          type="button"
                          class="btn btn-danger mr-3"
                          @click="handleBackList()"
                        >
                          Trở lại
                        </button>
                        <button
                          v-if="!$route.query.id"
                          type="button"
                          class="btn btn-danger mr-3"
                          @click="cancelUpdate()"
                        >
                          Xóa
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          :disabled="invalid"
                        >
                          Lưu
                        </button>
                      </div>
                    </form>
                  </validation-observer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
</template>

<script>
import { mapState, mapActions } from "vuex";
import { debounce } from "lodash";
import Loader from "../../components/loading/loader.vue";
import { extend } from "vee-validate";
import { addCommaPrice } from "../../config/common";

extend("validateMinInventory", (value) => {
  if (value >= 0) return true;
  return "Tồn kho tối thiểu không hợp lệ";
});

const initialData = {
  list_images: [],
  suppliers: [],
  image: "",
  name: "",
  price: null,
  sale_price: "0",
  unit: "",
  import_unit: null,
  description: "",
  code: "",
  category: null,
  brand: null,
  quantitations: [],
  promotion_according_to_quantity: [],
  active: true,
  min_inventory: 0,
  is_new: false,
  is_best_buy: false,
  expiry: new Date().toISOString().slice(0, 10),
};

const initalDataImport = {
  product: null,
  quantity: null,
};

const initialDataPromotion = {
  min: 0,
  max: null,
  price: "",
};

export default {
  data() {
    return {
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Nguyên liệu" },
        { key: "quantity", label: "Số lượng" },
        { key: "actions", label: "Xóa" },
      ],
      tableFieldsPromotion: [
        { key: "sno", label: "STT" },
        { key: "min", label: "Từ" },
        { key: "max", label: "Đến" },
        { key: "price", label: "Giá" },
        { key: "actions", label: "Xóa" },
      ],
      productUpdateInfo: { ...initialData },
      isChangeImage: false,
      quantitations: initalDataImport,
      checkQuantityIngredient: false,
      promotion_according_to_quantity: initialDataPromotion,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      pagenumber: 1,
      priceCompareTemp: "",
    };
  },
  components: {
    Loader,
  },
  async created() {
    this.isChangeImage = false;
    this.getCategoryList();
    this.getBrandList();
    this.getSupplierList();
    this.getUnitList();
    const id = this.$route.query?.id;
    if (id) {
      const response = await this.onDetailProduct(this.$route.query?.id);
      this.productUpdateInfo = response.data || { ...initialData };
      this.productUpdateInfo.price = addCommaPrice(
        this.productUpdateInfo.price
      );
      this.productUpdateInfo.sale_price = addCommaPrice(
        this.productUpdateInfo.sale_price
      );
      const itemPromotionList = this.productUpdateInfo
        ?.promotion_according_to_quantity;
      if (itemPromotionList.length > 0) {
        const lastPromotion = itemPromotionList[itemPromotionList.length - 1];
        const { price } = lastPromotion;
        this.promotion_according_to_quantity.price = addCommaPrice(price);
      } else {
        this.promotion_according_to_quantity.price = this.productUpdateInfo.price;
      }
    } else {
      this.productUpdateInfo.quantitations = [];
      this.productUpdateInfo.promotion_according_to_quantity = [];
      this.productUpdateInfo.list_images = [];
      this.promotion_according_to_quantity.price = "";
    }
    this.quantitations = {
      ...initalDataImport,
    };
    this.promotion_according_to_quantity = {
      ...initialDataPromotion,
    };
    this.onListProduct();
  },
  watch: {
    checkQuantityIngredient() {
      if (this.checkQuantityIngredient) {
        this.$toasted.show("Nguyên liệu và số lượng không được để trống !", {
          type: "info",
        });
        this.checkQuantityIngredient = false;
      }
    },
    "quantitations.quantity": {
      handler: function(valueAfter, valuebBefore) {
        if (valueAfter) {
          const result = this.unitList.filter(
            (item) => item.name == this.quantitations?.product?.unit
          );
          if (result && result.length > 0) {
            const { is_real } = result[0];
            if (is_real) {
              this.quantitations.quantity = parseFloat(valueAfter);
            } else {
              this.quantitations.quantity = parseInt(valueAfter);
            }
          } else {
            this.quantitations.quantity = parseInt(valueAfter);
          }
        }
      },
      deep: true,
    },
    "promotion_according_to_quantity.max": {
      handler: function(valueAfter, valuebBefore) {
        if (valueAfter) {
          const result = this.unitList.filter(
            (item) => item.name == this.productUpdateInfo?.unit
          );
          if (result && result.length > 0) {
            const { is_real } = result[0];
            if (is_real) {
              this.$nextTick(
                () =>
                  (this.promotion_according_to_quantity.max = parseFloat(
                    valueAfter
                  ))
              );
            } else {
              this.$nextTick(
                () =>
                  (this.promotion_according_to_quantity.max = parseInt(
                    valueAfter
                  ))
              );
            }
          } else {
            this.$nextTick(
              () =>
                (this.promotion_according_to_quantity.max = parseInt(
                  valueAfter
                ))
            );
          }
        }
      },
      deep: true,
    },
    "productUpdateInfo.min_inventory": {
      handler: function(valueAfter, valuebBefore) {
        if (valueAfter) {
          const result = this.unitList.filter(
            (item) => item.name == this.productUpdateInfo?.unit
          );
          if (result && result.length > 0) {
            const { is_real } = result[0];
            if (is_real) {
              this.$nextTick(
                () =>
                  (this.productUpdateInfo.min_inventory = parseFloat(
                    valueAfter
                  ))
              );
            } else {
              this.$nextTick(
                () =>
                  (this.productUpdateInfo.min_inventory = parseInt(valueAfter))
              );
            }
          } else {
            this.$nextTick(
              () =>
                (this.productUpdateInfo.min_inventory = parseInt(valueAfter))
            );
          }
        }
      },
      deep: true,
    },
    "productUpdateInfo.price": {
      handler: function(value) {
        if (typeof value == "string") {
          const result = addCommaPrice(value);
          this.$nextTick(() => (this.productUpdateInfo.price = result));
        }
      },
      deep: true,
    },
    "promotion_according_to_quantity.price": {
      handler: function(value) {
        if (typeof value == "string") {
          const result = addCommaPrice(value);
          this.$nextTick(
            () => (this.promotion_according_to_quantity.price = result)
          );
        }
      },
      deep: true,
    },
    "productUpdateInfo.sale_price": {
      handler: function(value) {
        if (typeof value == "string") {
          const result = addCommaPrice(value);
          this.$nextTick(() => (this.productUpdateInfo.sale_price = result));
        }
      },
      deep: true,
    },
    itemsPromotion() {
      const lengthItem = this.itemsPromotion.length;
      if (lengthItem === 0) {
        this.promotion_according_to_quantity.min = 0;
        this.promotion_according_to_quantity.price = this.productUpdateInfo.price;
      } else {
        const lastPromotion = this.itemsPromotion[lengthItem - 1];
        const { max, price } = lastPromotion;
        this.promotion_according_to_quantity.min = max;
        this.promotion_according_to_quantity.price = addCommaPrice(price);
        this.priceCompareTemp = addCommaPrice(price);
      }
    },
  },
  computed: {
    ...mapState("category", ["categoryList"]),
    ...mapState("brand", ["brandList"]),
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", ["isLoading", "isListProduct"]),
    ...mapState("unit", ["unitList"]),
    productsActive() {
      const idProductInTable = this.productUpdateInfo?.quantitations?.map(
        (item) => {
          return item.product.id;
        }
      );
      return this.isListProduct?.filter((item) => {
        return !idProductInTable?.includes(item.id);
      });
    },
    itemsImport() {
      return this.productUpdateInfo?.quantitations?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    itemsPromotion() {
      return this.productUpdateInfo?.promotion_according_to_quantity?.map(
        (item, index) => {
          return {
            sno: index + 1,
            ...item,
          };
        }
      );
    },
  },
  methods: {
    ...mapActions("category", ["getCategoryList"]),
    ...mapActions("product", [
      "productAdd",
      "onUpdateProduct",
      "onDetailProduct",
      "onListProduct",
    ]),
    ...mapActions("brand", ["getBrandList"]),
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions(["setMessage"]),
    onChangeAvatar() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      reader.onloadend = (file) => {
        this.productUpdateInfo.image = reader.result;
      };
      reader.readAsDataURL(file);
      this.isChangeImage = true;
    },
    searchingSelect({ name }) {
      return name;
    },
    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      var reader = new FileReader();
      reader.onload = (e) => {
        this.productUpdateInfo.list_images.push({
          image: e.target.result,
        });
      };
      reader.readAsDataURL(file);
    },
    removeImage: function(index) {
      this.productUpdateInfo.list_images.splice(index, 1);
    },
    onSubmit() {
      if (!this.isChangeImage) {
        // handle image change
        var {
          image,
          price,
          sale_price,
          category,
          brand,
          suppliers,
          list_images,
          quantitations,
          promotion_according_to_quantity,
          ...data
        } = this.productUpdateInfo;
      } else {
        var {
          price,
          sale_price,
          category,
          brand,
          suppliers,
          list_images,
          quantitations,
          promotion_according_to_quantity,
          ...data
        } = this.productUpdateInfo;
      }
      const supplierListId = suppliers?.map((item) => {
        return item.id;
      });

      const productsId = quantitations?.map((item) => {
        const { quantity } = item;
        return {
          product: item.product.id,
          quantity: quantity,
        };
      });

      const promotionList = promotion_according_to_quantity?.map((item) => {
        return {
          min: Number(item.min),
          max: Number(item.max),
          price: Number.isInteger(item.price)
            ? item.price
            : Number(item.price.replace(/,/g, "")),
        };
      });

      // handle update and create
      if (this.productUpdateInfo.id) {
        const idImages = this.productUpdateInfo.list_images.map((image) => {
          return image.id ? { id: image.id } : { image: image.image };
        });
        const dataSubmit = {
          price: Number(price.replace(/,/g, "")),
          sale_price: Number(sale_price.replace(/,/g, "")) || 0,
          category: category?.id,
          brand: brand?.id,
          suppliers: supplierListId,
          list_images: idImages,
          quantitations: productsId,
          promotion_according_to_quantity: promotionList,
          ...data,
        };
        const params = { page: this.pagenumber, page_size: this.pageSize };
        this.onUpdateProduct({ data: dataSubmit, params });
      } else {
        const idImages = this.productUpdateInfo.list_images.map((image) => {
          return { image: image.image };
        });
        this.productAdd({
          ...data,
          price: Number(price.replace(/,/g, "")),
          sale_price: Number(sale_price.replace(/,/g, "")) || 0,
          category: category?.id,
          brand: brand?.id,
          suppliers: supplierListId,
          quantitations: productsId,
          list_images: idImages,
          promotion_according_to_quantity: promotionList,
        });
      }
      this.isChangeImage = false;
    },
    cancelUpdate() {
      this.productUpdateInfo = {
        ...initialData,
      };
      this.quantitations = {
        ...initalDataImport,
      };
      this.productUpdateInfo.quantitations = [];
    },
    handleBackList() {
      // this.$router.push({ name: "product-list" });
      this.$router.go(-1);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
    search: _.debounce(function(query) {
      this.onListProduct({ name: query });
    }, 250),
    removeItemInTable(index) {
      this.productUpdateInfo?.quantitations.splice(index - 1, 1);
    },
    removeItemInTablePromotion(index) {
      this.productUpdateInfo.promotion_according_to_quantity.splice(
        index - 1,
        1
      );
    },
    addProduct() {
      const { product, quantity } = this.quantitations;
      if (product && quantity > 0) {
        this.productUpdateInfo?.quantitations?.push(this.quantitations);
        this.quantitations = {
          product: "",
          quantity: "",
        };
      } else {
        this.checkQuantityIngredient = true;
      }
    },
    addPromotion() {
      const { min, max, price } = this.promotion_according_to_quantity;
      if (Number(max) <= min) {
        this.setMessage({
          type: "error",
          content: "Số lượng sau phải lớn hơn",
          isShow: true,
        });
      } else if (!price || price?.includes("-")) {
        this.setMessage({
          type: "error",
          content: "Giá không để trống và phải là số dương",
          isShow: true,
        });
      } else if (!this.productUpdateInfo?.price) {
        this.setMessage({
          type: "error",
          content: "Vui lòng nhập giá bán trước",
          isShow: true,
        });
      } else if (
        this.priceCompareTemp &&
        Number(this.priceCompareTemp?.replace(/,/g, "")) <
          Number(price?.replace(/,/g, ""))
      ) {
        this.setMessage({
          type: "error",
          content:
            "Giá phải nhỏ hơn hoặc bằng giá trong danh sách giảm hoặc giá bán",
          isShow: true,
        });
      } else if (
        !this.priceCompareTemp &&
        Number(this.productUpdateInfo?.price?.replace(/,/g, "")) <
          Number(price?.replace(/,/g, ""))
      ) {
        this.setMessage({
          type: "error",
          content: "Giá phải nhỏ hơn hoặc bằng giá bán",
          isShow: true,
        });
      } else {
        this.productUpdateInfo?.promotion_according_to_quantity.push(
          this.promotion_according_to_quantity
        );
        this.promotion_according_to_quantity = {
          ...initialDataPromotion,
        };
      }
    },
    onChangeUnit() {
      this.productUpdateInfo.min_inventory = 0;
    },
    onChangePrice() {
      this.productUpdateInfo.promotion_according_to_quantity = [];
      this.priceCompareTemp = "";
    },
    clearDateExpiry() {
      this.productUpdateInfo.expiry = null;
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
.rbt-custom-input .multiselect__tags {
  border: 1px solid #ced4da;
}
.rbt-custom-multiselect .multiselect__tags {
  border: 1px solid #ced4da;
}
</style>
<style scoped>
.rbt-custom-input {
  white-space: nowrap;
  max-height: 38px !important;
}
input {
  height: 43px;
}
</style>

<style scoped>
.add-product-form .form-group:last-child {
  align-items: center;
}
</style>
