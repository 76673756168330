<template>
  <div>
    <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4" v-if="dataSubmit">
        Bạn có muốn hủy đơn hàng <b>{{ dataSubmit.name }}</b>
      </p>
    </b-modal>
    <b-modal size="xl" id="modal-import-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          ĐƠN HÀNG NHÀ CUNG CẤP
        </h3>
      </template>
      <div>
        <div class="row">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <span class="mr-3"
              ><span class="validate-star">* </span>Tên đơn hàng</span
            >
            <input
              v-model="dataSubmit.name"
              :disabled="!!dataSubmit.id"
              class="form-control rbt-custom-input"
              placeholder="Nhập tên đơn hàng"
              type="text"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <span class="mr-3"
              ><span class="validate-star">* </span>Nhà cung cấp</span
            >
            <multiselect
              v-if="!dataSubmit.id"
              class="rbt-custom-input"
              placeholder="Chọn nhà cung cấp"
              :options="supplierList"
              v-model="dataSubmit.supplier"
              :show-labels="false"
              :custom-label="searchingSelect"
            >
              <span slot="noResult">Không tìm thấy kết quả!!!</span>
              <template slot="singleLabel" slot-scope="props"
                ><span class="option__desc"
                  ><span class="option__title">{{
                    props.option.name
                  }}</span></span
                ></template
              >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <p class="option__title">
                    {{ props.option.name }}
                  </p>
                </div>
              </template>
            </multiselect>
            <input
              readonly
              v-else
              v-model="dataSubmit.supplier.name"
              class="form-control rbt-custom-input"
              type="text"
            />
          </div>
          <div
            class="col-12 col-md-6 d-flex align-items-center justify-content-end"
          >
            <span class="mr-3">Ngày dự kiến nhận hàng</span>
            <b-form-datepicker
              label-today
              class="form-control rbt-custom-date"
              label-help
              id="datepicker-placeholder"
              placeholder="Chọn thời gian"
              style="font-size: 14px"
              locale="vi"
              v-model="dataSubmit.recieve_time"
            ></b-form-datepicker>
          </div>
        </div>
        <hr />
        <div class="row mt-3">
          <div class="col-12 col-md-6 d-flex align-items-center">
            <span class="mr-3"
              ><span class="validate-star">* </span>Lấy từ báo giá</span
            >
            <multiselect
              v-if="!dataSubmit.id"
              class="rbt-custom-input"
              placeholder="Chọn báo giá"
              :show-labels="false"
              v-model="dataSubmit.quotation"
              @input="changeQuotation"
              :options="quotationsList"
              :custom-label="searchingSelect"
            >
              <span slot="noResult">Không tìm thấy kết quả!!!</span>
              <template slot="singleLabel" slot-scope="props"
                ><span class="option__desc"
                  ><span class="option__title">{{
                    props.option.name
                  }}</span></span
                ></template
              >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <p class="option__title">
                    {{ props.option.name }}
                  </p>
                </div>
              </template>
            </multiselect>
            <input
              readonly
              v-else
              v-model="dataSubmit.quotation.name"
              class="form-control rbt-custom-input"
              type="text"
            />
            <div class="d-flex align-items-center">
              <b-spinner
                v-if="isLoadingQuotation"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex align-items-center justify-content-end"
          >
            <span class="mr-3">Chiết khấu</span>
            <input
              v-model="dataSubmit.discount"
              :disabled="dataSubmit.status >= 2"
              class="form-control rbt-custom-input"
              @change="changeDiscount"
              placeholder="0"
              type="text"
            />
          </div>
        </div>
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="productInTable"
            :fields="dataSubmit.id ? tablefieldUpdate : tablefields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.name }} </span>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
                <span v-if="item.import_unit">/{{ item.import_unit }}</span>
              </span>
            </template>
            <template v-slot:cell(price_recommend)="{ item }">
              <span>
                {{ item.price_recommend | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{ totalPriceTable(item.sno) | formatCurrency }}
              </span>
            </template>
            <template v-slot:cell(quoted_quantity)="{ item }">
              <IncreaseAndDecrease
                v-if="!dataSubmit.id"
                :isDiscount="true"
                :isNumber="Number(item.quoted_quantity)"
                :product="item"
                @onChangeData="onIncreAndDecreQuotedQuantity"
              />
              <span v-else>
                {{ item.quoted_quantity }}
              </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <IncreaseAndDecrease
                :isDiscount="true"
                v-if="dataSubmit.id && dataSubmit.status < 2"
                :isNumber="Number(item.quantity)"
                :product="item"
                @onChangeData="onIncreAndDecreQuantity"
              />
              <span v-else>
                {{ item.quantity }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <div style="cursor: pointer;">
                <b-form-checkbox
                  :disabled="!!dataSubmit.id"
                  v-model="item.check"
                  value="true"
                  unchecked-value="false"
                >
                </b-form-checkbox>
              </div>
            </template>
          </b-table>
          <div>
            <b-pagination
              v-if="productInTable.length > 0"
              class="mt-2 d-flex justify-content-end"
              v-model="currentPage"
              :total-rows="productInTable.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
          <div>
            <textarea
              :disabled="dataSubmit.status >= 2"
              v-model="dataSubmit.note"
              rows="2"
              @change="changeNote"
              placeholder="Ghi chú đơn hàng"
              class="form-control mt-2"
            ></textarea>
          </div>
          <div class="mt-3 d-flex">
            <span>Tổng tiền: </span>
            <span class="ml-2" style="color: red">
              {{ allPrice | formatCurrency }}
            </span>
          </div>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <b-button
              v-if="dataSubmit.status === 1 || dataSubmit.status === 0"
              type="button"
              class="m-0 btn btn-danger"
              @click="openModelCancel()"
            >
              <span>
                Hủy đơn hàng
              </span>
            </b-button>
            <h2>
              <span v-if="dataSubmit.status === 3" class="badge badge-danger"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 2" class="badge badge-primary"
                >Đã duyệt</span
              >
            </h2>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              :disabled="!isComplete"
              class="btn-popup m-0"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              <span v-if="!dataSubmit.id"> Tạo đơn hàng</span>
            </b-button>
            <b-button
              v-if="dataSubmit.status === 1 || dataSubmit.status === 0"
              :disabled="!isComplete"
              class="btn-popup mr-3 my-0"
              @click="update()"
            >
              Cập nhật</b-button
            >
            <b-button
              v-if="dataSubmit.status === 1 || dataSubmit.status === 0"
              :disabled="!isComplete"
              class="btn-popup mr-3 my-0"
              @click="showModalPrint()"
            >
              In phiếu</b-button
            >
            <b-button
              v-if="dataSubmit.status === 1"
              class="btn-popup m-0"
              :disabled="checkDisable"
              @click="approved()"
            >
              Duyệt</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
    <OrderSupplierModal :orderInfo="orderInfo" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { statusCheckWarehouse } from "../../config/status-default";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { addCommaPrice } from "../../config/common";
import OrderSupplierModal from "../../components/modals/order/OrderSupplierModal.vue";

const initialDataSubmit = {
  product: [],
  name: "",
  note: null,
  discount: "",
  recieve_time: new Date().toISOString().slice(0, 10),
  total: 0,
  pre_payment: 0,
  remain_payment: 0,
  status: null,
  quotation: null,
  supplier: null,
  payment_status: null,
};

export default {
  components: {
    IncreaseAndDecrease,
    OrderSupplierModal,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  name: "WarehouseImport",
  data() {
    return {
      currentPage: 1,
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "actions", label: "Chọn" },
        { key: "name", label: "Sản phẩm" },
        { key: "import_unit", label: "Đơn vị nhập" },
        { key: "price", label: "Giá nhập" },
        { key: "price_recommend", label: "Giá khuyến nghị" },
        { key: "quoted_quantity", label: "Số lượng báo giá" },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      tablefieldUpdate: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "import_unit", label: "Đơn vị nhập" },
        { key: "price", label: "Giá nhập" },
        { key: "price_recommend", label: "Giá khuyến nghị" },
        { key: "quoted_quantity", label: "Số lượng báo giá" },
        { key: "quantity", label: "Số lượng thực tế" },
        { key: "totalPrice", label: "Thành tiền" },
      ],
      dataSubmit: { ...initialDataSubmit },
      productInTable: [],
      checkDisable: false,
    };
  },
  created() {},
  methods: {
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("supplier", ["onQuotationsList", "getQuotationDetail"]),
    ...mapActions(["setMessage"]),
    submit() {
      const { name, supplier, quotation } = this.dataSubmit;
      if ((supplier, quotation, name, this.productActive.length > 0)) {
        this.onEmitSubmit({
          type: "add",
        });
        this.$bvModal.hide("modal-import-warehouse");
      } else if (!supplier) {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn nhà cung cấp",
          isShow: true,
        });
        return;
      } else if (!quotation) {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn báo giá",
          isShow: true,
        });
        return;
      } else if (this.productActive.length == 0) {
        this.setMessage({
          type: "error",
          content: "Báo giá không có sản phẩm, hãy chọn báo giá khác",
          isShow: true,
        });
        return;
      }
    },
    onEmitSubmit({ type }) {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        discount,
        payment_status,
        created_by,
        ...data
      } = this.dataSubmit;
      if (type === "add") {
        const productActive = this.productActive.map((item) => {
          return {
            price: item?.price,
            quantity: item?.quoted_quantity,
            product: item?.id,
            quoted_quantity: item?.quoted_quantity,
            price_recommend: item?.price_recommend,
          };
        });
        const dataCreate = {
          supplier: supplier?.id,
          quotation: quotation?.id,
          recieve_time: new Date(recieve_time).toISOString().slice(0, 10),
          product: productActive,
          status: statusCheckWarehouse.CREATE,
          payment_status: 8, // default payment_status is PAID
          total: this.allPrice,
          discount: Number(discount.replace(/,/g, "")),
          created_by: created_by?.id,
          ...data,
        };
        this.$emit("onCreate", dataCreate);
        this.checkDisable = false;
      } else if (type === "update") {
        const productActive = this.productActive.map((item) => {
          return {
            price: item?.price,
            quantity: item?.quantity,
            product: item?.id,
            quoted_quantity: item?.quoted_quantity,
          };
        });
        const dataUpdate = {
          supplier: supplier?.id,
          quotation: quotation?.id,
          recieve_time: new Date(recieve_time).toISOString().slice(0, 10),
          product: productActive,
          status: statusCheckWarehouse.PENDING,
          payment_status: 8, // default payment_status is PAID
          total: this.allPrice,
          created_by: created_by?.id,
          discount: Number(discount.replace(/,/g, "")),
          ...data,
        };
        this.$emit("onUpdate", dataUpdate);
        this.checkDisable = false;
      }
    },
    totalPriceTable(index) {
      const { price, quantity, quoted_quantity } =
        this.productInTable?.[index - 1] || {};
      const priceTemp = price || 0;
      const quantityTemp = this.dataSubmit.id
        ? quantity || 0
        : quoted_quantity || 0;
      return quantityTemp === 0 ? "0" : Math.trunc(quantityTemp * priceTemp);
    },
    onIncreAndDecreQuotedQuantity({ quantity, product }) {
      this.productInTable?.forEach((item) => {
        if (item.id === product.id) {
          item.quoted_quantity = quantity;
        }
      });
      this.checkDisable = false;
    },
    onIncreAndDecreQuantity({ quantity, product }) {
      this.productInTable.forEach((item) => {
        if (item.id === product.id) {
          item.quantity = quantity;
        }
      });
      this.checkDisable = true;
    },
    openModelCancel() {
      this.$bvModal.show("model-cancel-import");
    },
    showModalPrint() {
      this.$bvModal.show("modal-print-order-supplier");
    },
    cancelImport() {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        payment_status,
        created_by,
        discount,
        ...data
      } = this.dataSubmit;
      const productActive = this.productActive.map((item) => {
        return {
          price: item?.price,
          quantity: item?.quantity,
          product: item?.id,
          quoted_quantity: item?.quoted_quantity,
        };
      });

      const dataUpdate = {
        supplier: supplier?.id,
        quotation: quotation?.id,
        recieve_time: new Date(recieve_time).toISOString().slice(0, 10),
        product: productActive,
        status: statusCheckWarehouse.CANCEL,
        payment_status: 8, // default payment_status is PAID
        total: this.allPrice,
        created_by: created_by?.id,
        discount: Number(discount.replace(/,/g, "")),
        ...data,
      };
      this.$emit("onCancel", dataUpdate);
      this.$bvModal.hide("modal-import-warehouse");
    },
    searchingSelect({ name }) {
      return name;
    },
    update() {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        payment_status,
        ...data
      } = this.dataSubmit;
      if (
        (supplier,
        quotation,
        this.dataSubmit.name,
        this.productActive.length > 0)
      ) {
        this.onEmitSubmit({
          type: "update",
        });
        this.$bvModal.hide("modal-import-warehouse");
      } else {
        this.setMessage({
          type: "error",
          content: "Nhập đủ các trường cần thiết",
          isShow: true,
        });
        return;
      }
    },
    approved() {
      const {
        supplier,
        quotation,
        product,
        recieve_time,
        status,
        total,
        payment_status,
        created_by,
        discount,
        ...data
      } = this.dataSubmit;
      const productActive = this.productActive.map((item) => {
        return {
          price: item?.price,
          quantity: item?.quantity,
          product: item?.id,
          quoted_quantity: item?.quoted_quantity,
        };
      });

      const dataUpdate = {
        supplier: supplier?.id,
        quotation: quotation?.id,
        recieve_time: new Date(recieve_time).toISOString().slice(0, 10),
        product: productActive,
        status: statusCheckWarehouse.APPROVED,
        payment_status: 8, // default payment_status is PAID
        total: this.allPrice,
        created_by: created_by?.id,
        discount: Number(discount.replace(/,/g, "")),
        ...data,
      };
      this.$emit("onApproved", dataUpdate);
      this.$bvModal.hide("modal-import-warehouse");
    },
    changeNote() {
      this.checkDisable = true;
    },
    changeDiscount() {
      this.checkDisable = true;
    },
    async changeQuotation(quotation) {
      if (quotation && !this.dataSubmit.id) {
        const quotationSelect = await this.getQuotationDetail(quotation?.id);
        this.productInTable = quotationSelect?.product_quotation.map(
          (item, index) => {
            const { price, ...productData } = item.product;
            return {
              sno: index + 1,
              check: "true",
              price: item?.unit_price,
              quantity: item?.quantity,
              quoted_quantity: item?.quantity,
              product: item?.product.id,
              price_recommend: item?.price_recommended,
              ...productData,
            };
          }
        );
      }
    },
  },
  computed: {
    ...mapState("product", ["isListProduct"]),
    ...mapState("warehouse", ["warehouseList"]),
    ...mapState("supplier", [
      "supplierList",
      "quotationsList",
      "totalRecordQuotation",
      "isLoadingQuotation",
    ]),
    allPrice() {
      let price = 0;
      this.productActive.forEach((item) => {
        price = price + this.totalPriceTable(item.sno);
      });
      const discountString = this.dataSubmit.discount;
      if (Number(discountString.replace(/,/g, "")) > price) {
        this.setMessage({
          type: "error",
          content: "Chiết khấu đang lớn hơn tổng đơn hàng",
          isShow: true,
        });
        this.dataSubmit.discount = "";
        return;
      } else {
        return price - Number(discountString.replace(/,/g, ""));
      }
    },
    productActive() {
      const results = this.productInTable.filter((item) => {
        return item.check === "true";
      });
      return results;
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    "dataSubmit.supplier"() {
      if (this.dataSubmit?.supplier?.id && !this.dataSubmit.id) {
        this.dataSubmit.quotation = null;
        this.productInTable = [];
        this.onQuotationsList({ supplier: this.dataSubmit?.supplier?.id });
      } else if (!this.dataSubmit?.supplier?.id && !this.dataSubmit.id) {
        this.dataSubmit.quotation = null;
      }
    },
    orderInfo() {
      this.dataSubmit = { ...this.orderInfo };
      this.checkDisable = false;
      if (this.dataSubmit.id) {
        // set product in table
        this.productInTable = this.dataSubmit?.product.map((item, index) => {
          const { price, ...productData } = item.product;
          return {
            sno: index + 1,
            check: "true",
            price: item?.price,
            quantity: item?.quantity,
            quoted_quantity: item?.quoted_quantity,
            product: item?.product.id,
            price_recommend: item?.price_recommend,
            ...productData,
          };
        });
        this.dataSubmit.discount = addCommaPrice(this.dataSubmit.discount);
      } else {
        this.dataSubmit = { ...initialDataSubmit };
        this.productInTable = [];
      }
    },
    "dataSubmit.discount"() {
      const discountConvert = this.dataSubmit.discount;
      if (typeof discountConvert == "string") {
        const result = addCommaPrice(discountConvert);
        this.$nextTick(() => (this.dataSubmit.discount = result));
      }
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}
.rbt-custom-date {
  max-width: 192px;
  white-space: nowrap;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>
