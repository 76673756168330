class LocalStorageService {
  getLocalAccessToken = () =>
    (process.browser && localStorage.getItem("accessToken")) || "";

  getLocalUserRole = () =>
    (process.browser && localStorage.getItem("userRole")) || "";

  getLocalRefreshToken = () =>
    (process.browser && localStorage.getItem("refreshToken")) || "";

  saveLocalAccessToken = (token) =>
    process.browser && localStorage.setItem("accessToken", token);

  saveLocalUserRole = (token) =>
    process.browser && localStorage.setItem("userRole", token);

  saveLocalRefreshToken = (token) =>
    process.browser && localStorage.setItem("refreshToken", token);

  removeLocalRefreshToken = () =>
    process.browser && localStorage.removeItem("refreshToken");

  removeLocalAccessToken = () =>
    process.browser && localStorage.removeItem("accessToken");

  getLocalAccessTokenDevice = () =>
    (process.browser && localStorage.getItem("accessTokenDevice")) || "";

  saveLocalAccessTokenDevice = (token) =>
    process.browser && localStorage.setItem("accessTokenDevice", token);

  removeLocalAccessTokenDevice = () =>
    process.browser && localStorage.removeItem("accessTokenDevice");

  saveLocalStatusOrder = (id) =>
    process.browser && localStorage.setItem("idConfirmOrder", id);

  getLocalStatusOrder = () =>
    process.browser && localStorage.getItem("idConfirmOrder");
}

export default new LocalStorageService();
