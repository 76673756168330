<template>
  <div>
    <loader :is-visible="isLoadingQuotation"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Báo giá nhà cung cấp" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecordQuotation }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row mb-4">
                <div
                  class="col-sm-9 d-flex justify-content-center align-items-center"
                >
                  <date-picker
                    v-model="date"
                    value-type="format"
                    range
                    format="DD-MM-YYYY"
                    placeholder="Chọn ngày để xem"
                    @change="onSearch"
                  ></date-picker>
                  <b-form-select
                    v-model="supplier"
                    @change="onSearch()"
                    class="col-sm-3 mr-2 ml-3"
                  >
                    <option value="">Nhà cung cấp</option>
                    <option
                      v-for="supplier in supplierList"
                      :key="supplier.index"
                      :value="supplier.id"
                      >{{ supplier.name }}</option
                    >
                  </b-form-select>
                  <div class="col-sm-5 d-flex">
                    <b-form-group
                      class="datatable-select"
                      style="margin-bottom: 0 !important"
                    >
                      <b-form-input
                        type="text"
                        v-model="query"
                        placeholder="Tìm kiếm"
                        @change="onSearch()"
                      ></b-form-input>
                    </b-form-group>
                    <div class="ml-3">
                      <b-button
                        class="btn-popup mb-0"
                        variant="primary"
                        @click="onSearch()"
                        >Tìm</b-button
                      >
                    </div>
                  </div>
                </div>
                <div class="col-sm-3 d-flex justify-content-end">
                  <b-button class="btn-popup m-0" @click="openModalImport()">
                    <span style="font-size: 24px; font-weight: 100">
                      +
                    </span>
                    Tạo báo giá</b-button
                  >
                </div>
              </div>
              <!-- {{ quotationsList }} -->
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xóa báo giá <b>{{ isCode }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoadingQuotation && quotationsList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="warehouseImportActive"
                  :fields="tablefields"
                >
                  <template v-slot:cell(supplier)="{ item }">
                    <span v-if="item.supplier">
                      {{ item.supplier.name }}
                    </span>
                  </template>
                  <template v-slot:cell(user)="{ item }">
                    <span v-if="item.user">
                      {{ item.user.name }}
                    </span>
                  </template>
                  <template v-slot:cell(created_at)="{ item }">
                    <span v-if="item.created_at">
                      {{ item.created_at | showDate }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  v-if="!isLoadingQuotation && quotationsList.length === 0"
                  class="text-center"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecordQuotation > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecordQuotation"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
              <QuotationsModal
                :quotationsInfo="quotationsInfo"
                @onUpdateQuotations="onUpdateQuotations"
                @onCreateQuotations="onCreateQuotations"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";
import QuotationsModal from "../../components/modals/QuotationsModal.vue";
import { convertDate } from "../../config/common";

export default {
  components: {
    QuotationsModal,
  },
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã báo giá" },
        { key: "name", label: "Tên Báo giá" },
        { key: "user", label: "Người tạo" },
        { key: "supplier", label: "Nhà cung cấp" },
        { key: "created_at", label: "Thời gian báo giá" },
        { key: "note", label: "Ghi chú" },
        { key: "actions", label: "Hành động" },
      ],
      query: "",
      date: "",
      supplier: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isCode: "",
      quotationsInfo: {},
    };
  },
  components: {
    Loader,
  },
  async created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    await this.onQuotationsList(params);
  },
  computed: {
    ...mapState("supplier", [
      "supplierList",
      "quotationsList",
      "isLoadingQuotation",
      "totalRecordQuotation",
    ]),
    colorStatus() {
      return colorBadgeStatus;
    },
    statusCheckWarehouse() {
      return statusCheckWarehouseText;
    },
    warehouseImportActive() {
      return this.quotationsList?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
  },
  watch: {},
  methods: {
    ...mapActions("supplier", [
      "onQuotationsList",
      "getQuotationDetail",
      "onQuotationsUpdate",
      "onQuotations",
      "onDeleteQuotation",
    ]),
    ...mapActions("product", ["onListProduct"]),
    onCreateQuotations(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onQuotations({ data: payload, params });
    },
    onUpdateQuotations(payload) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onQuotationsUpdate({ data: payload, params });
    },
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.onDeleteQuotation({ data: id, params });
    },
    openModalImport() {
      this.quotationsInfo = {};
      this.$bvModal.show("modal-import-warehouse");
    },
    setItem(item) {
      this.isId = item.id;
      this.isCode = item.code;
    },
    async onEdit(item) {
      const { id } = item;
      this.quotationsInfo = await this.getQuotationDetail(id);
      this.$bvModal.show("modal-import-warehouse");
    },
    onSearch() {
      let params = {
        page: this.pageNumber,
        page_size: this.pageSize,
      };
      if (this.date[0] && this.date[1]) {
        params = {
          created_at_from: convertDate(this.date[0]),
          created_at_to: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      this.onQuotationsList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/supplier/submit-quotes",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      const params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.date[0] && this.date[1]) {
        params = {
          created_at_from: convertDate(this.date[0]),
          created_at_to: convertDate(this.date[1]),
          ...params,
        };
      }
      if (this.query) {
        params = { search: this.query, ...params };
      }
      if (this.supplier) {
        params = {
          supplier: this.supplier,
          ...params,
        };
      }
      this.onQuotationsList(params);
      this.$router.push({
        path: "/supplier/submit-quotes",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>
