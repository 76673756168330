import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class ProfileService {
  async getProfile() {
    return axiosInstance.get(apiRoutesEnum.userProfile);
  }

  async changeProfile(payload) {
    return axiosInstance.put(apiRoutesEnum.changeProfile, payload);
  }

  async changePassword(payload) {
    return axiosInstance.post(apiRoutesEnum.changePassword, payload);
  }
}

export default new ProfileService();
