import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class BrandServices {
  async getAll(params) {
    return axiosInstance.get(apiRoutesEnum.brand, { params: params });
  }

  async getItem(id) {
    return axiosInstance.get(`${apiRoutesEnum.brand}/${id}`);
  }

  async create(payload) {
    return axiosInstance.post(apiRoutesEnum.brand, payload);
  }

  async update(payload) {
    const id = payload.id;
    const data = payload.data;
    return axiosInstance.put(`${apiRoutesEnum.brand}/${id}`, data);
  }

  async delete(id) {
    return axiosInstance.delete(`${apiRoutesEnum.brand}/${id}`);
  }
}

export default new BrandServices();
