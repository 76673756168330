<template>
  <div>
    <b-modal size="md" id="modal-inventory-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Thêm khách hàng
        </h3>
      </template>
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form class="needs-validation" @submit.stop.prevent="submit()">
          <div class="form">
            <div class="form-group">
              <label for="validationCustom01" class="mb-1"
                ><span>*</span>Tên khách hàng</label
              >
              <input
                class="form-control"
                id="validationCustom01"
                type="text"
                placeholder="Nhập tên khách hàng"
                v-model="dataSubmit.name"
              />
            </div>
            <div class="form-group">
              <label for="validationCustom01" class="mb-1"
                ><span>*</span>Số điện thoại</label
              >
              <validation-provider
                name="Số điện thoại"
                :rules="{
                  regexPhone: /^[0-9]+$/,
                  min: 10,
                  max: 11,
                }"
                v-slot="validationContext"
              >
                <b-form-group>
                  <b-form-input
                    id="example-input-6"
                    name="example-input-6"
                    placeholder="Nhập số điện thoại"
                    v-model="dataSubmit.phone_number"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-6-live-feedback"
                  ></b-form-input>

                  <b-form-invalid-feedback id="input-6-live-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <div class="form-group">
              <label for="validationCustom01" class="mb-1">Địa chỉ</label>
              <input
                class="form-control"
                placeholder="Nhập địa chỉ"
                id="validationCustom01"
                type="text"
                v-model="dataSubmit.address"
              />
            </div>
          </div>
          <b-button
            type="submit"
            class="btn btn-primary pull-right"
            :disabled="invalid || !isComplete"
          >
            <span>
              Thêm khách hàng
            </span>
          </b-button>
        </form>
      </validation-observer>

      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-end">
          <div class="d-flex">
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from "vuex";

const initialData = {
  name: "",
  phone_number: "",
  address: "",
};

export default {
  props: {
    dataCustomer: {
      type: Object,
      default() {
        return {};
      },
    },
    checkOpenModal: {
      type: Boolean,
    },
  },

  name: "AddCustomerModal",
  data() {
    const dataSubmit = { ...initialData };
    return {
      dataSubmit,
    };
  },
  created() {},
  methods: {
    ...mapActions("customer", ["createCustomer", "getCustomerList"]),
    cancel() {},
    async submit() {
      const { name, address, phone_number } = this.dataSubmit;
      const dataCustomer = {
        name: name,
        phone_number: phone_number,
        address: address,
        date_joined: new Date().toISOString().slice(0, 10),
        email: `${phone_number}@gmail.com`,
        username: phone_number,
        password: `abc${phone_number}`,
      };
      await this.createCustomer(dataCustomer);
      this.$bvModal.hide("modal-inventory-warehouse");
      this.dataSubmit = { ...initialData };
      await this.getCustomerList({ query: phone_number });
      this.$emit("checkOpenModal", true);
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated
        ? (valid = valid === true ? null : valid)
        : null;
    },
  },
  computed: {
    isComplete() {
      return this.dataSubmit.name && this.dataSubmit.phone_number;
    },
  },
  watch: {
    dataCustomer() {
      this.dataSubmit = { ...initialData };
    },
  },
};
</script>
