<template>
  <div class="col-xl-6 xl-100">
    <px-card title="Thông tin nhân viên">
      <div class="card-body">
        <div class="user-status table-responsive products-list">
          <b-table
            show-empty
            :items="employee"
            :fields="tablefields"
            :per-page="perPage"
          >
            <template #cell(avatar_url)="field">
              <div class="align-middle image-sm-size">
                <img :src="getImgUrl(field.item.avatar_url)" />
              </div>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span v-if="item.name">
                {{ item.name }}
              </span>
            </template>
            <template v-slot:cell(groups)="{ item }">
              {{ item.groups[0].name }}
            </template>
            <template v-slot:cell(is_online)="{ item }">
              <h4 v-if="item.is_online">
                <b-badge pill variant="success">{{
                  item.is_online | statusActive
                }}</b-badge>
              </h4>
              <h4 v-else>
                <b-badge pill variant="light">{{
                  item.is_online | statusActive
                }}</b-badge>
              </h4>
            </template>
          </b-table>
        </div>
      </div>
    </px-card>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: ["employee"],
  data() {
    return {
      tablefields: [
        { key: "avatar_url", label: "Ảnh", sortable: false },
        { key: "name", label: "Nhân viên", sortable: false },
        { key: "groups", label: "Vai trò", sortable: false },
        { key: "is_online", label: "Trạng thái", sortable: false },
      ],
      perPage: process.env.VUE_APP_PAGE_SIZE,
    };
  },
  created() {
    this.getRoleList();
  },
  computed: {
    ...mapState("role", ["roleList"]),
  },
  mounted() {},
  methods: {
    ...mapActions("role", ["getRoleList"]),
    getImgUrl(path) {
      if (path) {
        return `${process.env.VUE_APP_API_URL}${path}`;
      } else return require("../../assets/images/dashboard/avatar.png");
    },
  },
};
</script>

<style></style>
