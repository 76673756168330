<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="page-wrapper">
      <div>
        <b-navbar fixed="top" type="dark" style="background-color: #42b983">
          <b-nav-text class="header-nav-shipper"
            ><b>HỒ SƠ CÁ NHÂN</b></b-nav-text
          >
        </b-navbar>
      </div>
      <div class="card">
        <div class="card-body" v-if="userProfile">
          <div class="profile-details text-center" style="margin-top: 60px">
            <img
              v-if="userProfile.avatar_url"
              :src="userProfile.avatar_url"
              class="img-fluid img-90 rounded-circle blur-up lazyloaded"
              fluid
              rounded
            />
            <img
              v-else
              class="img-fluid img-90 rounded-circle blur-up lazyloaded"
              src="../../assets/images/dashboard/avatar.png"
              fluid
              rounded
            />
            <h5 class="f-w-600 mb-0">{{ userProfile.name }}</h5>
            <span>{{ userProfile.email }}</span>
            <div class="social">
              <b-button
                type="button"
                size="sm"
                class="btn btn-info pull-left ml-2"
                @click="editProfile()"
              >
                Sửa thông tin
              </b-button>
              <b-button
                type="button"
                size="sm"
                class="btn btn-warning pull-right mr-2"
                @click="changePassword()"
              >
                Đổi mật khẩu
              </b-button>
            </div>
          </div>
          <div class="table-responsive profile-table mb-0">
            <table class="table table-responsive mb-0">
              <tbody>
                <tr>
                  <td>Tên đăng nhập:</td>
                  <td>{{ userProfile.username }}</td>
                </tr>
                <tr>
                  <td>Email:</td>
                  <td>{{ userProfile.email }}</td>
                </tr>
                <tr>
                  <td>Số điện thoại:</td>
                  <td>{{ userProfile.phone_number }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-primary mb-2" @click="logout()">
            Đăng xuất
          </button>
        </div>
      </div>
    </div>
    <VueBottomNavigation
      :options="options"
      v-model="selected"
      foregroundColor="#42b983"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import VueBottomNavigation from "bottom-navigation-vue";
import Loader from "../../components/loading/loader.vue";

export default {
  components: { VueBottomNavigation, Loader },
  created() {
    this.getUserProfile();
  },
  computed: {
    ...mapState("profile", ["userProfile", "isLoading"]),
    ...mapState("menu", ["toggleSidebar"]),
  },
  methods: {
    ...mapActions("profile", ["getUserProfile"]),
    ...mapMutations("menu", ["openSidebar"]),
    logout: function() {
      this.$router.replace("/auth/login").catch((err) => err);
    },
    editProfile() {
      if (this.toggleSidebar) {
        this.openSidebar(true);
      }
      this.$router.push({
        name: "update-profile",
      });
    },
    changePassword() {
      if (this.toggleSidebar) {
        this.openSidebar(true);
      }
      this.$router.push({
        name: "change-password-profile",
      });
    },
  },
  data() {
    return {
      selected: 5,
      options: [
        {
          id: 4,
          icon: "fas fa-bell",
          title: "Thông báo",
          path: { name: "notify-shipper" },
        },
        {
          id: 1,
          icon: "fas fa-home",
          title: "Đơn hàng",
          path: { name: "shipper-order" },
        },
        {
          id: 5,
          icon: "fas fa-user",
          title: "Tài khoản",
          path: { name: "shipper-info" },
        },
        {
          id: 6,
          icon: "fas fa-chart-bar",
          title: "Thống kê",
          path: { name: "shipper-statistic" },
        },
      ],
    };
  },
};
</script>
<style>
.header-nav-shipper {
  font-size: 17px;
}
</style>
