<template>
  <div
    v-show="productList.length > 0"
    class="rbt-box-product-bottom mx-3"
    id="productList"
  >
    <div
      class="p-2 d-flex align-items-center justify-content-center"
      v-for="product in productList"
      :key="product.index"
    >
      <b-card
        v-if="product.image"
        :img-src="product.image"
        @error="replaceByDefault"
        style="max-width: 120px; max-height: 150px; object-fit: content"
        class="rbt-card-product-bottom mb-0"
        @click="addProductInTab(product)"
      >
        <div>
          <div class="mb-0 rbt-name-buttom-product">
            {{ product.name }}
          </div>
        </div>
        <span style="font-size: 12px; color: #999;">
          {{ product.price | formatCurrency }}
        </span>
      </b-card>
      <b-card
        v-else
        img-src="https://picsum.photos/600/300/?image=25"
        style="max-width: 120px; max-height: 150px"
        class="rbt-card-product-bottom mb-0"
        @click="addProductInTab(product)"
      >
        <div>
          <div class="mb-0 rbt-name-buttom-product">
            {{ product.name }}
          </div>
        </div>
        <span style="font-size: 12px; color: #999;">
          {{ product.price | formatCurrency }}
        </span>
      </b-card>
    </div>
  </div>
</template>

<script>
import defaultImage from "../../assets/images/image_default.png";
export default {
  props: {
    productList: {
      type: Array,
    },
  },
  methods: {
    replaceByDefault(e) {
      e.target.src = defaultImage;
    },
    addProductInTab(product) {
      this.$emit("onAddProductInTab", product);
    },
  },
  mounted() {
    const container2 = document.querySelector("#productList");
    container2.addEventListener("mousewheel", (e) => {
      // e = window.event || e;
      let delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      container2.scrollLeft -= delta * 90;
      e.preventDefault();
    });
  },
};
</script>

<style scoped>
.rbt-name-buttom-product {
  font-size: 12px;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbt-card-product-bottom {
  max-width: 150px;
  max-height: 160px;
  cursor: pointer;
}
.rbt-card-product-bottom img {
  height: 60px;
  width: 120px;
  object-fit: cover;
}
.rbt-box-product-bottom {
  height: 167px;
  overflow: auto;
  white-space: nowrap;
  display: -webkit-box;
  /* padding: 4px; */
  background-image: linear-gradient(0deg, #9be15d 0, #00e3ae);
}
.rbt-box-product-bottom p,
.rbt-box-product-bottom span {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.rbt-box-product-bottom .card-body {
  padding: 10px;
}
</style>
