import { apiRoutesEnum } from "../enums/routes";
import axiosInstance from "./axios";

class ReportService {
  async getListDailyReport(params) {
    return axiosInstance.get(apiRoutesEnum.dailyReport, { params: params });
  }

  async getListProductReport(params) {
    return axiosInstance.get(apiRoutesEnum.productReport, { params: params });
  }

  async getListSaleReport(params) {
    return axiosInstance.get(apiRoutesEnum.saleReport, { params: params });
  }

  async getListCustomerReport(params) {
    return axiosInstance.get(apiRoutesEnum.customerReport, { params: params });
  }

  async getListSupplierReport(params) {
    return axiosInstance.get(apiRoutesEnum.supplierReport, { params: params });
  }

  async getOrderProfitReport(params) {
    return axiosInstance.get(apiRoutesEnum.orderProfitReport, {
      params: params,
    });
  }

  async getListStatusOrder() {
    return axiosInstance.get(apiRoutesEnum.statusOrder);
  }

  async getListStatusPayment() {
    return axiosInstance.get(apiRoutesEnum.statusPayment);
  }
}

export default new ReportService();
