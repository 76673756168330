var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loader',{attrs:{"is-visible":_vm.isLoading}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header"},[_c('Breadcrumbs',{attrs:{"title":"Quản lý Slider"}})],1)]),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('router-link',{attrs:{"to":"/settings/create-image"}},[_c('b-button',{staticClass:"btn-popup pull-right"},[_vm._v("Thêm mới")])],1)],1)]),_c('div',{staticClass:"table-responsive datatable-vue product-list"},[_c('b-modal',{attrs:{"id":"modal-2","title":"Xác nhận"},on:{"ok":function($event){return _vm.onDelete(_vm.isId)}}},[_c('p',{staticClass:"my-4"},[_vm._v(" Xóa "),_c('b',[_vm._v(_vm._s(_vm.isName))])])]),(!_vm.isLoading && _vm.homepageImageList.length > 0)?_c('b-table',{staticClass:"text-center",attrs:{"striped":"","hover":"","head-variant":"light","bordered":"","stacked":"md","items":_vm.homepageImageList,"fields":_vm.tableFields},scopedSlots:_vm._u([{key:"cell(image)",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('img',{attrs:{"height":"80px","src":item.image,"width":"80px"}}):_vm._e(),(!item.image)?_c('img',{attrs:{"height":"80px","src":require("../../assets/images/image_default.png"),"width":"80px"}}):_vm._e()]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(" "+_vm._s(_vm.getLimitContent(item.name, 120))+" ")]):_vm._e()]}},{key:"cell(disable)",fn:function(ref){
var item = ref.item;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.disable),expression:"item.disable"}],attrs:{"type":"checkbox","id":"flexCheckChecked2"},domProps:{"checked":Array.isArray(item.disable)?_vm._i(item.disable,null)>-1:(item.disable)},on:{"change":[function($event){var $$a=item.disable,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "disable", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "disable", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "disable", $$c)}},function($event){return _vm.onChangeStatus($event, item)}]}}),_c('span',[_vm._v(" Ẩn trên trang chủ")]),_c('br')]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-size":"20px","color":"blue"}},[_c('feather',{staticStyle:{"cursor":"pointer"},attrs:{"type":"edit-2","stroke":"#3758FD","stroke-width":"1","size":"18px","fill":"#3758FD","stroke-linejoin":"round"},on:{"click":function($event){return _vm.onEdit(item)}}})],1),_c('span',{staticStyle:{"font-size":"20px","color":"#FA399F"}},[_c('feather',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-2",modifiers:{"modal-2":true}}],staticStyle:{"cursor":"pointer"},attrs:{"type":"trash","stroke":"#F72E9F","size":"18px","fill":"#F72E9F"},on:{"click":function($event){return _vm.setItem(item)}}})],1)]}}],null,false,3447774106)}):_vm._e(),(!_vm.isLoading && _vm.homepageImageList.length === 0)?_c('div',{staticClass:"text-center"},[_vm._v(" Không có dữ liệu ")]):_vm._e()],1)])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }