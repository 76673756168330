<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Thông tin cá nhân" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="profile-details text-center">
                <img
                  v-if="userProfile.avatar_url"
                  :src="userProfile.avatar_url"
                  class="img-fluid img-90 rounded-circle blur-up lazyloaded"
                  fluid
                  rounded
                />
                <img
                  v-else
                  class="img-fluid img-90 rounded-circle blur-up lazyloaded"
                  src="../../assets/images/dashboard/avatar.png"
                  fluid
                  rounded
                />
                <h5 class="f-w-600 mb-0">{{ userProfile.name }}</h5>
                <span>{{ userProfile.email }}</span>
                <div class="social">
                  <div class="form-group btn-showcase">
                    <button class="btn social-btn btn-fb d-inline-block">
                      <i class="fab fa-facebook-f"></i>
                    </button>
                    <button class="btn social-btn btn-twitter d-inline-block">
                      <i class="fab fa-google"></i>
                    </button>
                    <button
                      class="btn social-btn btn-google d-inline-block mr-0"
                    >
                      <i class="fab fa-twitter"></i>
                    </button>
                  </div>
                </div>
              </div>
              <h5 class="f-w-600">Thông tin</h5>
              <div class="table-responsive profile-table mb-0">
                <table class="table table-responsive mb-0">
                  <tbody>
                    <tr>
                      <td>Tên đăng nhập:</td>
                      <td>{{ userProfile.username }}</td>
                    </tr>
                    <tr>
                      <td>Email:</td>
                      <td>{{ userProfile.email }}</td>
                    </tr>
                    <tr>
                      <td>Số điện thoại:</td>
                      <td>{{ userProfile.phone_number }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="text-center">
              <button
                type="button"
                class="btn btn-primary mb-2"
                @click="editProfile(userProfile)"
              >
                Sửa thông tin
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {};
  },
  created() {
    this.getUserProfile();
  },
  computed: {
    ...mapState("profile", ["userProfile", "isLoading"]),
  },
  components: {
    Loader,
  },
  methods: {
    ...mapActions("profile", ["getUserProfile"]),
    editProfile(userInfo) {
      this.$router.push({
        name: "update-profile",
        params: { profileInfo: userInfo },
      });
    },
  },
};
</script>
<style scoped></style>
