<template>
  <div>
    <loader :is-visible="isLoading"></loader>
    <div class="container-fluid">
      <div class="page-header">
        <Breadcrumbs title="Danh sách nhân viên" />
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <h5>{{ totalRecord }} kết quả</h5>
            </div>
            <div class="card-body">
              <div class="row">
                <b-form-group class="datatable-select col-md-4">
                  <b-form-input
                    type="text"
                    v-model="search"
                    placeholder="Tìm kiếm"
                    @change="onSearch()"
                  ></b-form-input>
                </b-form-group>
                <b-form-group class="col-md-3">
                  <b-form-select v-model="role" @change="onSearch()">
                    <option value="">Vai trò</option>
                    <option
                      v-for="role in roleActive"
                      :key="role.index"
                      :value="role.id"
                      >{{ role.name }}</option
                    >
                  </b-form-select>
                </b-form-group>
                <div class="col-md-5">
                  <b-button class="btn-popup" variant="primary" @click="onSearch()"
                    >Tìm</b-button
                  >
                  <router-link to="/employee/create-employee">
                    <b-button class="btn-popup pull-right"
                      >Thêm mới</b-button
                    ></router-link
                  >
                </div>
              </div>
              <div class="table-responsive datatable-vue product-list">
                <b-modal id="modal-2" title="Xác nhận" @ok="onDelete(isId)">
                  <p class="my-4">
                    Xoá <b>{{ isName }}</b>
                  </p>
                </b-modal>
                <b-table
                  v-if="!isLoading && employeeList.length > 0"
                  class="text-center"
                  striped
                  hover
                  head-variant="light"
                  bordered
                  stacked="md"
                  :items="employeeList"
                  :fields="tablefields"
                >
                  <template v-slot:cell(name)="{ item }">
                    <span v-if="item.name">
                      {{ item.name }}
                    </span>
                  </template>
                  <template v-slot:cell(phone_number)="{ item }">
                    <span v-if="item.phone_number">
                      {{ item.phone_number }}
                    </span>
                  </template>
                  <template v-slot:cell(address)="{ item }">
                    <span>
                      {{ item.address }}
                      <span v-if="item.district">
                        - {{ item.district.name }}</span
                      >
                      <span v-if="item.city"> - {{ item.city.name }}</span>
                    </span>
                  </template>
                  <template v-slot:cell(username)="{ item }">
                    <span v-if="item.username">
                      {{ item.username }}
                    </span>
                  </template>
                  <template v-slot:cell(date_joined)="{ item }">
                    <span v-if="item.date_joined">
                      {{ item.date_joined | dateFormat }}
                    </span>
                  </template>
                  <template v-slot:cell(groups)="{ item }">
                    <span v-if="item.groups[0]">
                      {{ item.groups[0].name }}
                    </span>
                  </template>
                  <template v-slot:cell(actions)="{ item }">
                    <span style="font-size: 20px; color: blue;">
                      <feather
                        style="cursor: pointer"
                        type="edit-2"
                        stroke="#3758FD"
                        stroke-width="1"
                        size="18px"
                        fill="#3758FD"
                        stroke-linejoin="round"
                        @click="onEdit(item)"
                      ></feather>
                    </span>
                    <span style="font-size: 20px; color: #FA399F;">
                      <feather
                        v-b-modal.modal-2
                        style="cursor: pointer"
                        type="trash"
                        stroke="#F72E9F"
                        size="18px"
                        fill="#F72E9F"
                        @click="setItem(item)"
                      ></feather>
                    </span>
                  </template>
                </b-table>
                <div
                  class="text-center"
                  v-if="!isLoading && employeeList.length === 0"
                >
                  Không có dữ liệu
                </div>
              </div>
              <b-col md="12" class="my-1 p-0 pagination-justify">
                <b-pagination
                  v-if="totalRecord > 0"
                  v-model="pageNumber"
                  :total-rows="totalRecord"
                  :per-page="pageSize"
                  aria-controls="my-table"
                  class="mt-4"
                  @change="handlePageChange"
                ></b-pagination>
              </b-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Loader from "../../components/loading/loader.vue";

export default {
  data() {
    return {
      tablefields: [
        { key: "code", label: "Mã nhân viên" },
        { key: "name", label: "Tên nhân viên" },
        { key: "username", label: "Tên đăng nhập" },
        { key: "groups", label: "Vai trò" },
        { key: "phone_number", label: "Số điện thoại" },
        { key: "indentity_card_number", label: "CMND" },
        { key: "date_joined", label: "Ngày bắt đầu làm việc" },
        { key: "address", label: "Địa chỉ" },
        { key: "actions", label: "Hành động" },
      ],
      search: "",
      role: "",
      pageNumber: 1,
      pageSize: process.env.VUE_APP_PAGE_SIZE,
      isId: "",
      isName: "",
    };
  },
  components: {
    Loader,
  },
  created() {
    const pageNumber = this.$route.query.page || 1;
    this.pageNumber = pageNumber;
    const params = { page: pageNumber, page_size: this.pageSize };
    this.getEmployeeList(params);
    this.getRoleList();
  },
  watch: {},
  computed: {
    ...mapState("employee", ["employeeList", "totalRecord", "isLoading"]),
    ...mapState("role", ["roleList"]),
    roleActive() {
      return this.roleList?.filter((item) => {
        return item.is_employee;
      });
    },
  },
  methods: {
    ...mapActions("employee", ["getEmployeeList", "deleteEmployee"]),
    ...mapActions("role", ["getRoleList"]),
    onDelete(id) {
      const params = { page: this.pageNumber, page_size: this.pageSize };
      this.deleteEmployee({ id: id, params });
      this.search = "";
    },
    setItem(item) {
      this.isId = item.id;
      this.isName = item.username;
    },
    onEdit(item) {
      this.$router.push({
        name: "create-employee",
        query: { id: item.id },
      });
    },
    onSearch() {
      let params = {
        page: 1,
        page_size: this.pageSize,
      };
      if (this.search) {
        params = { query: this.search.trim(), ...params };
      }
      if (this.role) {
        params = {
          role: this.role,
          ...params,
        };
      }
      this.getEmployeeList(params);
      if (this.pageNumber != 1) {
        this.pageNumber = 1;
        this.$router.push({
          path: "/employee/employee-list",
          query: { page: this.pageNumber },
        });
      }
    },
    handlePageChange(value) {
      this.pageNumber = value;
      let params = { page: this.pageNumber, page_size: this.pageSize };
      if (this.search) {
        params = {
          query: this.search.trim(),
          ...params,
        };
      }
      if (this.role) {
        params = {
          role: this.role,
          ...params,
        };
      }
      this.getEmployeeList(params);
      this.$router.push({
        path: "/employee/employee-list",
        query: { page: this.pageNumber },
      });
    },
  },
};
</script>
