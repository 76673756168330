<template>
  <div>
    <b-modal id="model-cancel-import" title="Xác nhận" @ok="cancelImport()">
      <p class="my-4" v-if="dataSubmit">
        Bạn có muốn hủy đơn trả hàng <b>{{ dataSubmit.code }}</b>
      </p>
    </b-modal>
    <b-modal size="lg" id="modal-import-warehouse" centered>
      <template #modal-header>
        <h3 class="mx-auto" style="color: #ff8084">
          Trả hàng nhà cung cấp
        </h3>
      </template>
      <validation-provider>
        <b-form-group
          label-cols-md="3"
          label-cols-lg="3"
          content-cols-sm
          content-cols-md="9"
        >
          <template v-slot:label>
            Tên phiếu trả hàng<span class="validate-star"> *</span></template
          >
          <b-form-input
            :disabled="dataSubmit.status >= 1"
            v-model="dataSubmit.name"
            placeholder="Nhập tên phiếu trả hàng"
          ></b-form-input>
        </b-form-group>
      </validation-provider>
      <validation-provider>
        <b-form-group
          label-cols-md="3"
          label-cols-lg="3"
          content-cols-sm
          content-cols-md="9"
        >
          <template v-slot:label>
            Nhà cung cấp<span class="validate-star"> *</span></template
          >
          <multiselect
            :disabled="!!dataSubmit.id"
            class="rbt-custom-input"
            style="max-width: 100% !important"
            v-model="dataSubmit.supplier"
            placeholder="Nhà cung cấp"
            @input="onChangeSupplier"
            :show-labels="false"
            :custom-label="searchingSelect"
            :options="supplierList"
          >
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
        </b-form-group>
      </validation-provider>
      <div
        v-if="
          !dataSubmit.order_supplier &&
            (dataSubmit.status < 2 || !dataSubmit.id)
        "
        class="mt-3 form-row"
      >
        <div class="col-md-2 mt-2">
          Sản phẩm
        </div>
        <div class="mr-3 col-md-3">
          <multiselect
            class="rbt-custom-input"
            v-model="dataImport.product"
            placeholder="Chọn sản phẩm"
            :show-labels="false"
            :custom-label="searchingSelect"
            :options="productsActive"
          >
            <span slot="noOptions">
              Không có sản phẩm
            </span>
            <span slot="noResult">Không tìm thấy kết quả!!!</span>
            <template slot="singleLabel" slot-scope="props"
              ><span class="option__desc"
                ><span class="option__title">{{
                  props.option.name
                }}</span></span
              ></template
            >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <p class="option__title">
                  {{ props.option.name }}
                </p>
              </div>
            </template>
          </multiselect>
          <div class="d-flex align-items-center">
            <b-spinner
              v-if="isLoading"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </div>

        <div class="col-md-3 d-flex">
          <input
            v-model="dataImport.quantity"
            placeholder="Số lượng"
            class="form-control rbt-custom-input"
            id="validationCustom1"
            type="number"
          />
        </div>
        <div class="col-md-3">
          <input
            v-model="dataImport.price"
            placeholder="Giá"
            class="form-control rbt-custom-input"
            id="validationCustom1"
          />
        </div>
        <div class="d-flex align-items-center mb-3 ml-3 mt-2">
          <div
            class="d-flex"
            style="border-radius: 50%; background-color: #28a745"
            @click="addProduct()"
          >
            <feather
              class="rbt-icon-plus"
              style="cursor: pointer"
              type="plus"
              stroke="#f5fbf6"
              size="28px"
              fill="#f5fbf6"
            ></feather>
          </div>
        </div>
      </div>
      <div>
        <div class="table-responsive datatable-vue text-center mt-4">
          <b-modal
            id="model-remove"
            title="Xác nhận"
            @ok="removeProductInTab()"
          >
            <p class="my-4">Are you sure!</p>
          </b-modal>
          <b-table
            show-empty
            striped
            hover
            head-variant="light"
            bordered
            stacked="md"
            :items="itemsImport"
            :fields="computedFields"
            :per-page="perPage"
          >
            <template #empty>
              <span class="text-danger">
                Chưa có sản phẩm nào
              </span>
            </template>
            <template v-slot:cell(name)="{ item }">
              <span> {{ item.product.name }} </span>
            </template>
            <template v-slot:cell(quantity)="{ item }">
              <div
                class="d-flex justify-content-center align-items-center"
                v-if="dataSubmit.status != 2 && dataSubmit.status != 3"
              >
                <IncreaseAndDecrease
                  style="max-width: 150px"
                  :isNumber="Number(item.quantity) || 0"
                  @onChangeData="onIncreAndDecre"
                  :isGreaterZero="true"
                  :product="item.product"
                  :isDiscount="true"
                />
              </div>
              <div v-else>
                <span>
                  {{ item.quantity || 0 }}
                </span>
              </div>
            </template>
            <template v-slot:cell(price)="{ item }">
              <span>
                {{ item.price | formatCurrency }}
                <span v-if="item.product.unit">/{{ item.product.unit }}</span>
              </span>
            </template>
            <template v-slot:cell(totalPrice)="{ item }">
              <span>
                {{
                  Math.trunc(
                    item.quantity * Number(item.price.replace(/,/g, ""))
                  ) | formatCurrency
                }}
              </span>
            </template>
            <template v-slot:cell(actions)="{ item }">
              <span
                style="font-size: 20px; color: #FA399F;"
                @click="removeItemInTable(item.sno)"
              >
                <feather
                  style="cursor: pointer"
                  type="trash"
                  stroke="#F72E9F"
                  size="18px"
                  fill="#F72E9F"
                ></feather>
              </span>
            </template>
          </b-table>
        </div>
        <div>
          <textarea
            v-model="dataSubmit.reason"
            :disabled="dataSubmit.status >= 2"
            rows="2"
            placeholder="Lý do trả hàng"
            @change="changeNote"
            class="form-control"
          ></textarea>
        </div>
        <div class="mt-3">
          <p>
            Tổng tiền:
            <b v-if="totalPrice">
              {{ totalPrice | formatCurrency }}
            </b>
            <b v-else>
              0 đ
            </b>
          </p>
        </div>
      </div>
      <template #modal-footer="{cancel}">
        <div style="width: 100%" class="d-flex justify-content-between">
          <div>
            <b-button
              v-if="dataSubmit.status === 1 || dataSubmit.status === 0"
              type="button"
              class="m-0 btn btn-danger"
              @click="openModelCancel()"
            >
              <span>
                Hủy đơn trả hàng
              </span>
            </b-button>
            <h2>
              <span v-if="dataSubmit.status === 3" class="badge badge-danger"
                >Đã huỷ</span
              >
            </h2>
            <h2>
              <span v-if="dataSubmit.status === 2" class="badge badge-primary"
                >Đã duyệt</span
              >
            </h2>
          </div>
          <div>
            <b-button class="mr-3" size="sm" variant="danger" @click="cancel()">
              Đóng
            </b-button>
            <b-button
              v-if="!dataSubmit.id"
              class="btn-popup m-0"
              :disabled="!isComplete"
              @click="submit()"
            >
              <span style="font-size: 24px; font-weight: 100">
                +
              </span>
              <span v-if="!dataSubmit.id"> Tạo đơn trả hàng</span>
            </b-button>
            <b-button
              v-if="dataSubmit.status === 1 || dataSubmit.status === 0"
              class="btn-popup mr-3 my-0"
              :disabled="!isComplete"
              @click="update()"
            >
              Cập nhật</b-button
            >
            <b-button
              v-if="dataSubmit.status === 1"
              class="btn-popup m-0"
              :disabled="checkDisable"
              @click="approved()"
            >
              Duyệt</b-button
            >
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import IncreaseAndDecrease from "../../components/UI/increaseAndDecrease.vue";
import { statusCheckWarehouse } from "../../config/status-default";
import { addCommaPrice } from "../../config/common";

const initalDataImport = {
  price: "",
  quantity: null,
  product: null,
  unit: "",
};

const initalDataSubmit = {
  product: [],
  name: "Trả hàng nhà cung cấp",
  total: null,
  reason: "",
  supplier: null,
  status: null,
  payment_status: 8, //default payment status is PAID
};

export default {
  props: {
    refundInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    IncreaseAndDecrease,
  },
  name: "WarehouseImport",
  data() {
    return {
      perPage: process.env.VUE_APP_PAGE_SIZE,
      tablefields: [
        { key: "sno", label: "STT" },
        { key: "name", label: "Sản phẩm" },
        { key: "quantity", label: "Số lượng" },
        { key: "price", label: "Đơn giá" },
        { key: "totalPrice", label: "Thành tiền" },
        { key: "actions", label: "Xóa", require: true },
      ],
      productRefund: [],
      dataImport: { ...initalDataImport },
      dataSubmit: { ...initalDataSubmit },
      checkDisable: false,
    };
  },
  created() {
    this.getUnitList();
    this.getSupplierList();
  },
  methods: {
    ...mapActions("supplier", ["getSupplierList"]),
    ...mapActions("product", ["onListProduct"]),
    ...mapActions("unit", ["getUnitList"]),
    ...mapActions(["setMessage"]),
    removeItemInTable(index) {
      this.dataSubmit?.product.splice(index - 1, 1);
      this.checkDisable = true;
    },
    onEmitSubmit({ type }) {
      const {
        supplier,
        product,
        total,
        status,
        payment_status,
        ...dataSubmit
      } = this.dataSubmit;
      const convertProductToSubmit = product?.map((item) => {
        const { product, price, quantity, unit } = item;
        return {
          product: product.id,
          total: Number(price.replace(/,/g, "")) * Number(quantity),
          price: Number(price.replace(/,/g, "")),
          quantity: Number(quantity),
          unit: unit || item.product.unit,
        };
      });
      if (type === "add") {
        this.$emit("onCreate", {
          product: convertProductToSubmit,
          supplier: supplier.id,
          total: this.totalPrice,
          payment_status: 8,
          status: statusCheckWarehouse.PENDING,
          ...dataSubmit,
        });
        this.checkDisable = false;
      } else if (type === "update") {
        this.$emit("onUpdate", {
          product: convertProductToSubmit,
          supplier: supplier.id,
          total: this.totalPrice,
          payment_status: 8,
          status: statusCheckWarehouse.PENDING,
          ...dataSubmit,
        });
        this.checkDisable = false;
      }
    },
    submit() {
      if (this.dataSubmit?.product?.length > 0 && this.dataSubmit.supplier) {
        this.onEmitSubmit({
          type: "add",
        });
        this.$bvModal.hide("modal-import-warehouse");
      } else {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn sản phẩm và nhà cung cấp",
          isShow: true,
        });
        return;
      }
    },
    update() {
      if (this.dataSubmit?.product?.length > 0 && this.dataSubmit.supplier) {
        this.onEmitSubmit({
          type: "update",
        });
        this.$bvModal.hide("modal-import-warehouse");
      } else {
        this.setMessage({
          type: "error",
          content: "Vui lòng chọn sản phẩm và nhà cung cấp",
          isShow: true,
        });
        return;
      }
    },
    approved() {
      this.$emit("onApproved", {
        id: this.dataSubmit.id,
        status: statusCheckWarehouse.APPROVED,
      });
      this.$bvModal.hide("modal-import-warehouse");
    },
    addProduct() {
      const { product, quantity, price } = this.dataImport;
      if (
        product &&
        quantity > 0 &&
        price &&
        Number(price.replace(/,/g, "")) > 0
      ) {
        this.dataSubmit?.product?.push({
          product: product,
          unit: product?.unit,
          quantity,
          price,
        });
        this.dataImport = { ...initalDataImport };
        this.checkDisable = true;
      } else {
        this.setMessage({
          type: "error",
          content: "Sản phẩm, đơn giá và số lượng không được để trống",
          isShow: true,
        });
        return;
      }
    },
    searchingSelect({ name }) {
      return name;
    },
    onIncreAndDecre({ quantity, product }) {
      this.dataSubmit?.product?.forEach((item) => {
        if (item.product.id === product.id) {
          item.quantity = quantity;
        }
      });
      this.checkDisable = true;
    },
    onChangeSupplier(supplier) {
      this.dataImport.product = "";
      this.dataSubmit.product = [];
      if (supplier) {
        this.onListProduct({ supplier_id: supplier?.id });
      }
    },
    openModelCancel() {
      this.$bvModal.show("model-cancel-import");
    },
    cancelImport() {
      this.$emit("onCancel", {
        id: this.dataSubmit.id,
        status: statusCheckWarehouse.CANCEL,
      });
      this.$bvModal.hide("modal-import-warehouse");
    },
    changeNote() {
      this.checkDisable = true;
    },
  },
  computed: {
    ...mapState("supplier", ["supplierList"]),
    ...mapState("product", ["isListProduct", "isLoading"]),
    ...mapState("unit", ["unitList"]),
    totalPrice() {
      let total = 0;
      if (this.dataSubmit?.product?.length > 0) {
        this.dataSubmit?.product?.forEach((item) => {
          total += item.quantity * Number(item.price.replace(/,/g, ""));
        });
      }
      return total;
    },
    productsActive() {
      const idProductInTable = this.dataSubmit?.product?.map((item) => {
        return item.product.id;
      });
      return this.isListProduct?.filter((item) => {
        return !idProductInTable?.includes(item.id);
      });
    },
    itemsImport() {
      return this.dataSubmit.product?.map((item, index) => {
        return {
          sno: index + 1,
          ...item,
        };
      });
    },
    computedFields() {
      if (this.dataSubmit.status != 2 && this.dataSubmit.status != 3)
        return this.tablefields;
      else return this.tablefields.filter((field) => !field.require);
    },
    isComplete() {
      return this.dataSubmit.name;
    },
  },
  watch: {
    refundInfo() {
      this.checkDisable = false;
      this.dataImport = { ...initalDataImport };
      if (this.refundInfo.id) {
        const { product, ...data } = this.refundInfo;
        const productConvertPrice = product?.map((item) => {
          const { price, ...data } = item;
          return {
            price: addCommaPrice(price),
            ...data,
          };
        });
        this.dataSubmit = { product: productConvertPrice, ...data };
      } else {
        this.dataSubmit = { ...initalDataSubmit };
        this.dataSubmit.product = [];
      }
    },
    "dataImport.price"() {
      const priceImport = this.dataImport.price;
      if (typeof priceImport == "string") {
        const result = addCommaPrice(priceImport);
        this.$nextTick(() => (this.dataImport.price = result));
      }
    },
    "dataImport.quantity": {
      handler: function(valueAfter, valuebBefore) {
        if (valueAfter) {
          const result = this.unitList.filter(
            (item) => item.name == this.dataImport?.product?.unit
          );
          if (result && result.length > 0) {
            const { is_real } = result[0];
            if (is_real) {
              this.dataImport.quantity = parseFloat(valueAfter);
            } else {
              this.dataImport.quantity = parseInt(valueAfter);
            }
          } else {
            this.dataImport.quantity = parseInt(valueAfter);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style>
.rbt-custom-input .multiselect__tags .multiselect__single {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}
</style>

<style scoped>
.rbt-custom-input {
  max-width: 192px;
  white-space: nowrap;
  height: 43px !important;
}

input::placeholder,
textarea::placeholder {
  font-size: 14px;
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
input {
  height: 100% !important;
}
</style>
