<template>
  <!-- <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 footer-copyright">
          <p class="mb-0">Copyright 2021 © RBT All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer> -->
</template>

<script>
export default {};
</script>

<style>

</style>